<template>
  <div>
    <div class="row bar-table">
      <div class="col">
        <b-pagination
          v-if="hasPageTwo"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="rows"
          aria-controls="my-table"
          align="right" />
      </div>
    </div>
    <b-table :id="tableId"
             :busy="busy"
             :current-page="currentPage"
             :items="families" 
             :fields="tableFields"
             :filter="filter"
             :filter-included-fields="filterOn"
             :per-page="perPage"
             @filtered="onFilterChange"
             @row-clicked="onSelection"
             empty-filtered-text="No families match the filter"
             responsive 
             show-empty
             thead-class="d-none"
             :tbody-tr-class="classTr">
      <template #cell(familyName)="row">
        {{ row.item.familyName }} 
        <FamilyDescription :family-description="row.item.familyDescription"
                           :author="row.item.author"
                           :source-url="row.item.sourceUrl"
                           :id="`fdi-${row.item.familyId}`"
                           placement="bottom" />
      </template>
      <template #table-busy>
        <TableBusy />
      </template>
    </b-table>
  </div>
</template>

<script>
import * as urlUtils from '@/logic/general/urlUtils'
import TableBusy from "@/components/TableBusy"
import FamilyDescription from "@/components/family/FamilyDescription"

  export default {
    components: {
      FamilyDescription,
      TableBusy
    },
    name: 'FamilySelectionTable',
    props: {
      busy: {type: Boolean},
      families: {type: Array},
      filter: {type: String},
      perPage: {type: Number},
      tableId: {type: String}
    },
    data() {
      return {
        currentPage: 1,
        filterOn: ['familyName','familyDescription'],
        hasPageTwo: false,
        rows: this.families.length,
        tableFields: [
          {key: "familyName", label: "Name"}
        ]
      }
    },
    computed: {
    },
    methods: {
      classTr: function () {
        const showButton = this.rows > 0
        return {
          "hover-pointer" : showButton,
          "bar-table-button" :showButton
        }
      },
      determinePageTwo: function () {
        this.hasPageTwo = this.rows > this.perPage
      },
      onFilterChange: function (filteredItems) {
        this.rows = filteredItems.length
        this.currentPage = 1
        this.determinePageTwo()
      },
      onSelection: function (item, index, event) {
        const payload = { vm: this, familyId: item.familyId }
        this.$store.dispatch('family/loadFamily', payload)
          .then(() => {
            const pathNew = urlUtils.getFamilyURL(item.familyId, item.familyName)
            this.$router.push(pathNew)
          })
          .catch(() => {
            // Ignore the error.  The network error should show in the banner.
          })
      }
    },
    watch: {
      families: function (newVal){
        this.rows = newVal.length
        this.determinePageTwo()
      }
    }
  }
</script>