// Referennce: http://homepages.rootsweb.com/~pmcbride/gedcom/55gcch2.htm
//             http://homepages.rootsweb.com/~pmcbride/gedcom/55gcappa.htm
//
// basePropertyReference:  Valid only with 'isObject'.  This is the name of the property to house id pointer on this row.  
//                         In this example, it would be used to store @I1@ in the ASSO object.
//                           Example: 1 ASSO @I1@
//                                    2 RELA great grandson
//
// basePropertyValue:      Valid only with 'isObject'.  This is the name of the property to house the value on this row.
//                         In this example, it would be used to store
//                           Example: 1 DESC 198cm tall
//                                    2 AGE 23
//
// filterLevel:          Filter. Used to select which handling type to use if more than one exists within the array. Selected if the row filterLevel matches the handlingType filterLevel
// 
// filterLevel0Code:     Filter. Used to select which handling type to use if more than one exists within the array. Selected if the code of the most recent level 0 matches this.
//
// filterPreviousCode:          Filter. Used to select which handling type to use if more than one exists within the array. Selected if the previous row has this code type
//
// group:                 An array property with this name will be created within the parent object.  Multiple values of this type 
//                        can be added to this array property
//                          Example: 1 OCCU Janitor
//                                   2 PLAC Hudson's Bay Dept Store
//                                   1 OCCU Director, Flight Operations
//                                   2 PLAC NASA
//
// isObject:             An object will be created for this in order to contain all the child rows.
//
// includeCode:          Valid only with 'isObject'.  Determines if the gedcom code will be included as a property within the object.
//
// label:                Nonfunctional. Left in for readability
//                       A label propert will be added to this object with the value specified.  Not really language neutral but easy to interpret the JSON.         
//  
// recordProperty:       The root object will be given a object property of this name.  This object will be stored inside that object property as an object with the id as its property name
//                         Example:        0 @I6000000016923561346@ INDI
//                                         1 NAME Lewis /Seago/
//
//                         results in ...  {
//                                            individuals: {
//                                              I6000000016923561346: {
//                                                id: "I6000000016923561346",
//                                                name: {
//                                                  fullName: "Lewis Seago"
//                                                }
//                                            }
//                                          }
//
// type:                 The name of the property that will be created in the parent object.  Used for simple values

export const handlingTypes = {
  ABBR: {type: 'abbreviation'},
  ADDR: {type: 'address', isObject: true, basePropertyValue: 'address'},
  ADR1: {type: 'address1'},
  ADR2: {type: 'address2'},
  ADR3: {type: 'address3'},
  ADOP: [
    {filterPreviousCode: 'FAMC', type: 'adoptiveParents'},
    {group: 'events', label: 'Adoption', isObject: true, basePropertyValue: 'value', includeCode: true}
  ],
  AFN: {type: 'ancestralFileNumber'},
  AGE: {type: 'age'},
  AGNC: {type: 'agency'},
  ALIA: {group: 'aliases', isObject: true, basePropertyReference: 'pointer', basePropertyValue: 'value', cleanSlashes: true},
  ANCE: {type: 'generationsOfAncestors'},
  ANCI: {group: 'ancestorInterest'},
  ANUL: {group: 'events', label: 'Annulment', isObject: true, basePropertyValue: 'value', includeCode: true},
  ASSO: {group: 'associations', isObject: true, basePropertyReference: 'pointer'},
  AUTH: {type: 'author'},
  BAPL: {group: 'ldsIndividualOrdinance', label: 'Baptism', isObject: true},
  BAPM: {group: 'events', label: 'Baptism', isObject: true, basePropertyValue: 'value', includeCode: true},
  BARM: {group: 'events', label: 'Bar Mitzvah', isObject: true, basePropertyValue: 'value', includeCode: true},
  BASM: {group: 'events', label: 'Bas Mitzvah', isObject: true, basePropertyValue: 'value', includeCode: true},
  BIRT: {group: 'events', label: 'Birth', isObject: true, basePropertyValue: 'value', includeCode: true},
  BLES: {group: 'events', label: 'Blessing', isObject: true, basePropertyValue: 'value', includeCode: true},
  BLOB: {ignore: true}, //Support discontinued in gedcom 5.5.1,
  BURI: {group: 'events', label: 'Burial', isObject: true, basePropertyValue: 'value', includeCode: true},
  CALN: {group: 'sourceCallNumbers', isObject: true, basePropertyValue: 'value'},
  CAST: {group: 'attributes', label: 'Caste', isObject: true, basePropertyValue: 'value', includeCode: true},
  CAUS: {type: 'cause'},
  CENS: {group: 'events', label: 'Census', isObject: true, basePropertyValue: 'value', includeCode: true},
  CHAN: {type: 'lastChange', isObject: true},
  CHAR: {ignore: true},
  CHIL: {group: 'children', isObject: true, basePropertyReference: 'pointer'},
  CHR: {group: 'events', label: 'Christening', isObject: true, basePropertyValue: 'value', includeCode: true},
  CHRA: {group: 'events', label: 'Adult Christening', isObject: true, basePropertyValue: 'value', includeCode: true},
  CITY: {type: 'city'},
  CLNDR: {type: 'calendarType'},
  CONC: {ignore: true},  // Handled by special code
  CONF: {group: 'events', label: 'Confirmation', isObject: true, basePropertyValue: 'value', includeCode: true},
  CONL: {group: 'ldsIndividualOrdinance', label: 'Confirmation', isObject: true},
  CONT: {ignore: true},  // Handled by special code
  COPR: {type: 'copyright'},
  CORP: {type: 'corporate', isObject: true},
  CREM: {group: 'events', label: 'Cremation', isObject: true, basePropertyValue: 'value', includeCode: true},
  CTRY: {type: 'country'},
  DATA: {type: 'data', isObject: true},
  DATE: {type: 'date', isObject: true, basePropertyValue: 'date'},
  DEAT: {group: 'events', label: 'Death', isObject: true, basePropertyValue: 'value', includeCode: true},
  DESC: {type: 'generationsOfDescendants'},
  DESI: {type: 'descendentInterest'},
  DEST: {ignore: true}, //{type: 'destination'},
  DIV: {group: 'events', label: 'Divorce', isObject: true, basePropertyValue: 'value', includeCode: true},
  DIVF: {group: 'events', label: 'Divorce Filed', isObject: true, basePropertyValue: 'value', includeCode: true},
  DSCR: {group: 'attributes', label: 'Physical Description', isObject: true, basePropertyValue: 'value', includeCode: true},
  EDUC: {group: 'attributes', label: 'Education', isObject: true, basePropertyValue: 'value', includeCode: true},
  EMAI: {group: 'emails'},
  EMAIL: {group: 'emails'},
  EMIG: {group: 'events', label: 'Emmigration', isObject: true, basePropertyValue: 'value', includeCode: true},
  ENDL: {group: 'ldsIndividualOrdinance', label: 'Endowment', isObject: true},
  ENGA: {group: 'events', label: 'Engagement', isObject: true, basePropertyValue: 'value', includeCode: true},
  EVEN: {group: 'events', label: 'Event', isObject: true, basePropertyValue: 'value', includeCode: true},
  FACT: {group: 'attributes', label: 'Fact', isObject: true, basePropertyValue: 'value', includeCode: true},
  FAM: {type: 'family', isObject: true, recordProperty: 'families'},
  FAMC: {group: 'familyParents', isObject: true, basePropertyReference: 'pointer'},
  FAMF: {type: 'familyFile'},
  FAMS: {group: 'familyUnions', isObject: true, basePropertyReference: 'pointer'},
  FAX: {group: 'faxes'},
  FCOM: {group: 'events', label: 'First Communion', isObject: true, basePropertyValue: 'value', includeCode: true},
  FILE: {group: 'files', isObject: true, basePropertyValue: 'url'},
  FONE: {type: 'Phonetic', isObject: true},
  FORM: {type: 'format'},
  GEDC: {type: 'gedcom', isObject: true},
  GIVN: {type: 'given'},
  GRAD: {group: 'events', label: 'Graduation', isObject: true, basePropertyValue: 'value', includeCode: true},
  HEAD: {type: 'header', isObject: true},
  HUSB: [
    {filterLevel: 2, type: 'husband', isObject: true},
    {group: 'spouses'}
  ],
  IDNO: {group: 'attributes', label: 'National Id Number', isObject: true, basePropertyValue: 'value', includeCode: true},
  IMMI: {group: 'events', label: 'Immigration', isObject: true, basePropertyValue: 'value', includeCode: true},
  INDI: {isObject: true, recordProperty: 'individuals'},
  LANG: {type: 'language'},
  LATI: {type: 'latitude'},
  LEGA: {ignore: true}, //Support discontinued in gedcom 5.6,
  LONG: {type: 'longitude'},
  MAP: {type: 'map', isObject: true},
  MARR: {group: 'events', label: 'Marriage', isObject: true, basePropertyValue: 'value', includeCode: true},
  MARB: {group: 'events', label: 'Marriage Public Notice', isObject: true, basePropertyValue: 'value', includeCode: true},
  MARC: {group: 'events', label: 'Marriage Contract', isObject: true, basePropertyValue: 'value', includeCode: true},
  MARL: {group: 'events', label: 'Marriage License', isObject: true, basePropertyValue: 'value', includeCode: true},
  MARS: {group: 'events', label: 'Marriage Settlement', isObject: true, basePropertyValue: 'value', includeCode: true},
  MEDI: {type: 'media'},
  NAME: {group: 'names', isObject: true, basePropertyValue: 'fullName', cleanSlashes: true, removeUnderscores: true},
  NATI: {group: 'attributes', label: 'National or Tribal Origin', isObject: true, basePropertyValue: 'value', includeCode: true},
  NATU: {group: 'events', label: 'Naturalization', isObject: true, basePropertyValue: 'value', includeCode: true},
  NCHI: {group: 'attributes', label: 'Count of Children', isObject: true, basePropertyValue: 'value', includeCode: true},
  NICK: {type: 'nickname'},
  NMR: {group: 'attributes', label: 'Count of Marriages', isObject: true, basePropertyValue: 'value', includeCode: true},
  NOTE: [
    {filterLevel: 0, isObject: true, recordProperty: 'notes', basePropertyValue: 'value'},
    {group: 'notes', isObject: true, basePropertyValue: 'value', basePropertyReference: 'pointer'}
  ],
  NPFX: {type: 'namePrefix'},
  NSFX: {type: 'nameSuffix'},
  OBJE: [
    {filterLevel: 0, isObject: true, recordProperty: 'multimedia'},
    {group: 'multimedia', isObject: true, basePropertyReference: 'pointer'}
  ],
  OCCU: {group: 'attributes', label: 'Occupation', isObject: true, basePropertyValue: 'value', includeCode: true},
  ORDI: {type: 'ordinance'},
  ORDN: {group: 'events', label: 'Ordination', isObject: true, basePropertyValue: 'value', includeCode: true},
  PAGE: {type: 'page'},
  PEDI: {type: 'pedigree'},
  PHON: {group: 'phones'},
  PLAC: {type: 'place', isObject: true, basePropertyValue: 'value'},
  POST: {type: 'postalCode'},
  PROB: {group: 'events', label: 'Probate', isObject: true, basePropertyValue: 'value', includeCode: true},
  PROP: {group: 'attributes', label: 'Possessions', isObject: true, basePropertyValue: 'value', includeCode: true},
  PUBL: {type: 'publication'},
  QUAY: {type: 'certaintyAssessment'},
  REFN: {type: 'reference', isObject: true, basePropertyValue: 'value'},
  RELA: {type: 'relation'},
  RELI: {group: 'attributes', label: 'Religious Affiliation', isObject: true, basePropertyValue: 'value', includeCode: true},
  REPO: [
    {filterLevel: 0, isObject: true, recordProperty: 'repositories'},
    {type: 'repository', isObject: true, basePropertyReference: 'pointer'}
  ],
  RESI: {group: 'attributes', label: 'Residence', isObject: true, basePropertyValue: 'value', includeCode: true},
  RESN: {type: 'restrictionNotice'},
  RETI: {group: 'events', label: 'Retirement', isObject: true, basePropertyValue: 'value', includeCode: true},
  RFN:  {type: 'recordFileNumber'},
  RIN:  {type: 'recordIdNumber'},
  ROLE: {type: 'role'},
  ROMN: {type: 'Romanized', isObject: true},
  SEX: {type: 'sex'},
  SLGC: {group: 'ldsIndividualOrdinance', label: 'Sealing', isObject: true},
  SLGS: {group: 'ldsSpouseSealing', isObject: true},
  SOUR: [
    {filterLevel: 0, isObject: true, recordProperty: 'sources'},
    {group: 'sources', isObject: true, basePropertyValue: 'value', basePropertyReference: 'pointer'}
  ],
  SPFX: {type: 'surnamePrefix'},
  SSN: {group: 'attributes', label: 'Social Security Number', isObject: true, basePropertyValue: 'value', includeCode: true},
  STAE: {type: 'subdivision'},
  STAT: {type: 'status'},
  SUBM: [
    {filterLevel: 0, isObject: true, recordProperty: 'submittors'},
    {group: 'submittors'}
  ],
  SUBN: [
    {filterLevel: 0, isObject: true, recordProperty: 'submissions'},
    {type: 'submission'}
  ],
  SURN: {type: 'surname'},
  TEMP: {type: 'ldsTemple'},
  TEXT: {group: 'text'},
  TIME: {type: 'time'},
  TITL: [
    {filterLevel: 1, filterLevel0Code: 'INDI', group: 'attributes', label: 'Title', isObject: true, basePropertyValue: 'value', includeCode: true},
    {type: 'title'}
  ],
  TRLR: {ignore: true},
  TYPE: {type: 'type'},
  URL: {group: 'urls'},
  VERS: {type: 'version'},
  WAC: {group: 'ldsIndividualOrdinance', label: 'WashingAndClothing', isObject: true},
  WIFE: [
    {filterLevel: 2, type: 'wife', isObject: true},
    {group: 'spouses'}
  ],
  WILL: {group: 'events', label: 'Probate', isObject: true, basePropertyValue: 'value', includeCode: true},
  WWW: {group: 'urls'},
  _ASSO: {group: 'associations', isObject: true, basePropertyReference: 'pointer'},
  _DESCRIPTION_AWARE:  {ignore: true},
  _DISABLED:  {type: 'LastUpdate'},
  _FREL: {type: 'fatherRelationship'},
  _PHOTO_RIN: {type: 'photoRecordIdNumber'},
  _PRIM: {type: 'primary', valueFormatter: formatYN},
  _MARNM: {type: 'marriedName'},
  _MREL: {type: 'motherRelationship'},
  _PLAC: [
    {filterLevel: 0, isObject: true, recordProperty: 'places'},
    {type: 'placeReference', isObject: true, basePropertyValue: 'value', basePropertyReference: 'pointer'}
  ],
  _PUBLISH:  {ignore: true},
  _RINS:  {ignore: true},
  _RTLSAVE:  {ignore: true},
  _SDATE: {type: 'sortDate'},
  _SHAR: {group: 'shares', isObject: true, basePropertyReference: 'pointer'},
  _TIMEZONE:  {ignore: true},
  _UID:  {ignore: true},
  _UPD:  {type: 'LastUpdate'},
  _USERNAME:  {type: 'LastUpdate'},
  _WITN: {group: 'witnesses', isObject: true, basePropertyReference: 'pointer'},
}

function formatYN(value) {
  const map = {Y: true, N: false}
  return map[value]
}