import builder from 'xmlbuilder'
import moment from 'moment'
import {getFamilyURL, getIndividualURL} from '@/logic/general/urlUtils'
import {getFormalName} from '@/logic/family/individualUtils'

export function generateSitemapFile(individuals, familyId, familyName) {
  const today = moment().utc().format("YYYY-MM-DD")
  const origin = location.origin

  const urlset = builder.create('urlset').dec('1.0', 'UTF-8', true);
  urlset.att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9')

  const collectionUrl = getFamilyURL(familyId, familyName)
  const collectionPath = `${origin}${collectionUrl.path}`
  generateSitemapUrl(urlset, collectionPath, today)

  for (const key of Object.getOwnPropertyNames(individuals || {})) {
    const individual = individuals[key]
    const name = getFormalName(individual)
    const parts = name.split(' ').filter(item => /[\w\d]/.test(item))
    const isSufficientlyComplex = parts.length > 2 || (parts.length === 2 && !hasGenericName(parts))

    if (isSufficientlyComplex) {
      const individualUrl = getIndividualURL(familyId, familyName, individual.id, name)
      const individualPath = `${origin}${individualUrl.path}`
      generateSitemapUrl(urlset, individualPath, today)
    }
  }

  return urlset.end({ pretty: true})
}

export function generateSitemapUrl(urlset, loc, lastmod) {
  const urlNode = urlset.ele('url')
  const locNode = urlNode.ele('loc')
  const lastmodNode = urlNode.ele('lastmod')
  locNode.txt(loc)
  lastmodNode.txt(lastmod)
}

export function hasGenericName(parts) {
  return parts.some(part => /\b(son|dau(ghter)?|boy|girl)\b/.test(part))
}