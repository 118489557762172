<template>
  <b-modal id="saveTreeModal" size="lg" hide-header @show="onShow">
    <h5>Save File</h5>
    <div class="option-row">
      <div>
        File Type
      </div>
      <div class="option-cell">
        <b-form-radio-group v-model="fileType" :options="fileTypes" />
      </div>
    </div>
    <div v-if="isPdf" class="option-row">
      <div>
        Paper Type
      </div>
      <div class="option-cell">
        <b-form-select v-model="paperType" :options="paperTypes" />
      </div>
    </div>
    <div v-if="isPdf" class="option-row">
      <div>
        Layout
      </div>
      <div class="option-cell">
        <b-form-radio-group v-model="layout" :options="layouts" />
      </div>
    </div>
    <div v-if="isPdf" class="option-row">
      <div>Include Cut Guides?</div>
      <div>
        <b-form-checkbox v-model="printCutGuides" />
      </div>
    </div>
    <div class="option-row">
      <div>
        Scaling
      </div>
      <div class="option-cell-slider">
        <vue-slider v-model="scalingFactor" 
                    :dot-style="dotStyle"
                    :marks="marksSize" 
                    :max="1000"
                    :min="0" />
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <b-button type="button"
                variant="primary"
                :disabled="postState.inProgress"
                @click="onGenerate">
        Generate
      </b-button>
    </div>
    <ApiPostFeedback :post-state="postState" />     
    
    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-button size="sm" class="float-right" @click="close ()">
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script> 
  import { mapState } from 'vuex'
  import { paperSizesSorted } from '@/logic/file/pageSizes'
  import { generateFile } from '@/logic/file/treeSave'
  import * as apiUtils from '@/logic/general/apiUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"

  export default {
    name: 'SaveTreeModal',
    components: {
      ApiPostFeedback
    },
    data() {
      return {
        dotStyle: function () {
          return {
            "background-color":"#3498db"  
          }
        },
        layout: 'l', 
        layouts: [
          { text: 'Portrait', value: 'p' },
          { text: 'Landscape', value: 'l' },
        ],
        marksSize: {
          '0': {label: 'Small'},
          '1000': {label: 'Large'}
        },
        paperType: 'letter', 
        scalingFactor: 250, 
        fileType: 'pdf', 
        fileTypes: [
          { text: 'Image', value: 'image' },
          { text: 'PDF', value: 'pdf' },
        ],
        postState: {},
        printCutGuides: true
      }
    },
    computed: {
      ...mapState('viewState', [
        'language',
        'measurement'
      ]),
      isPdf: function () {
        return this.fileType === 'pdf'
      },
      paperTypes: function () {
        return paperSizesSorted(this.language).map((item) => {
          const system = this.measurement === 'imperial' ? item.imperial : item.metric
          return {
            html: `<b>${item.name}:</b> (${system})`,
            value: item.value
          }
        })
      }
    },
    methods: {
      onGenerate: function () {
        this.postState = apiUtils.resetPostState()
        const individualName = this.$route.params.individualName
        const scaling = .50 + (this.scalingFactor / 500) 
        generateFile('tree-view-container', individualName, this.fileType, this.layout, this.paperType, scaling, this.printCutGuides)
          .then(() => {
            this.postState.inProgress = false
            this.$bvModal.hide('saveTreeModal')
          })
          .catch(error => {
            this.postState = apiUtils.handleGeneralFailure(error)
          })
      },
      onShow: function () {
        console.log(`Modal: saveTreeModal`)
        this.postState = {}
      }
    }
  }
</script>