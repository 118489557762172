import Vue from 'vue'
import Router from 'vue-router'
import AccountSettings from './views/AccountSettings'
import ContactUs from './views/ContactUs'
import EmailManageSettings from './views/EmailManageSettings'
import EmailVerify from './views/EmailVerify'
import ErrorAuthorization from './views/ErrorAuthorization'
import ErrorRoute from './views/ErrorRoute'
import ErrorStackOverflow from './views/ErrorStackOverflow'
import ErrorUnexpected from './views/ErrorUnexpected'
import FamilyDetails from './views/FamilyDetails'
import FamilyIndividualList from './views/FamilyIndividualList'
import FamilyInvitations from './views/FamilyInvitations'
import FamilyManagement from './views/FamilyManagement'
import FamilySelection from './views/FamilySelection'
import Home from './views/Home'
import PasswordReset from './views/PasswordReset'
import Privacy from './views/Privacy'
import SiteAdministrationReimportAll from './views/SiteAdministrationReimportAll'
import Terms from './views/Terms'
import Tree from './views/Tree'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/bob',
      beforeEnter: (to, from, next) => {
        const link = document.createElement('a');
        link.download = `Family Tree - bob.png`;
        link.href = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAOCAYAAAA8E3wEAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QkVFTIru6sp5wAAAAd0RVh0QXV0aG9yAKmuzEgAAAAMdEVYdERlc2NyaXB0aW9uABMJISMAAAAKdEVYdENvcHlyaWdodACsD8w6AAAADnRFWHRDcmVhdGlvbiB0aW1lADX3DwkAAAAJdEVYdFNvZnR3YXJlAF1w/zoAAAALdEVYdERpc2NsYWltZXIAt8C0jwAAAAh0RVh0V2FybmluZwDAG+aHAAAAB3RFWHRTb3VyY2UA9f+D6wAAAAh0RVh0Q29tbWVudAD2zJa/AAAABnRFWHRUaXRsZQCo7tInAAABZElEQVQ4jeWUsUrDUBSGv4qP0IDgIqF0yCR26CDULA7OLYV2DBn6BIJip0IrnR0cMohDC9LMFR2M2Tq0OGUINbgIgfgOcchNa5MSOmXxLPfe/5x7/v/cc7iFMAxDcrS9aPmgX1aQywqa+ZMHYX72TwkDs4MseiqXhywy/Qry8GPt9E00gffn8Wx0ePIFYTIZ0xuqV/Yf4IGGbhKI02KoJPyA0d4kjeFWGyNZ4fN8E7BsmYnr4LkOs0EtAu1X3vxI/Z3IoI+jGM/uoQIYL6mXoNZj5jp47j3NgwjaT8aoA40TsZdOz1GxsbBZfgN8Yq3UKwn1Hkuf1V0A9eIMKVlhkjDLgi8vwxuLWlvpqJiKSlVoTd8J6nUkYPHYFRXVKB2ChAxE/dPHDteVLbx+tuh0hXaXqpiyRvxmeifqQUXjVrTVaCkbk7rrD5UiVAejVdKIbIR3eSwORZqGw0TfKfdWK+T9ef8CccV5s6eiyYMAAAAASUVORK5CYII="
        link.dataset.downloadurl = ["image/png", link.download, link.href].join(':');

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        next(false)
      }
    },
    {
      path: '/error/authorization',
      name: 'Authorization Error',
      component: ErrorAuthorization,
      meta: {
        title: 'Error - Authorization'
      }
    },
    {
      path: '/error/stackOverflow',
      name: 'Stack Overflow Error',
      component: ErrorStackOverflow,
      meta: {
        title: 'Error - Overflow'
      }
    },
    {
      path: '/error/unexpected',
      name: 'Unexpected Error',
      component: ErrorUnexpected,
      meta: {
        title: 'Error - Unexpexted'
      }
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: 'Home',
        metaTags: [
          {
            name: 'description',
            content: 'This site allows you to display large and complex family trees quickly and in a manner suitable for printing.  A must tool to use before your next family reunion.'
          }
        ]
      }
    },
    {
      path: '/admin/accountSettings',
      name: 'Account Settings',
      component: AccountSettings,
      meta: {
        title: 'Account Settings',
        metaTags: [
          {
            name: 'description',
            content: 'View and change your account.'
          }
        ]
      }
    },
    {
      path: '/admin/family/invitations',
      name: 'Family Invitations',
      component: FamilyInvitations,
      meta: {
        title: 'Family Invitations',
        metaTags: [
          {
            name: 'description',
            content: 'View outstanding invitations to view private families belonging to other people.'
          }
        ]
      }
    },
    {
      path: '/admin/family/management',
      name: 'Family Management',
      component: FamilyManagement,
      meta: {
        title: 'Family Management',
        metaTags: [
          {
            name: 'description',
            content: 'Manage private families you are authorized to. New private families can be created here.'
          }
        ]
      }
    },
    {
      path: '/siteAdmin/reimportAll',
      name: 'Reimport All',
      component: SiteAdministrationReimportAll,
      meta: {
        title: 'Reimport All',
        metaTags: [
          {
            name: 'description',
            content: 'Reimport all families.'
          }
        ]
      }
    },
    {
      path: '/admin/family/:familyId',
      name: 'adminFamilyDetails',
      component: FamilyDetails,
      meta: {
        title: 'Family Details',
        metaTags: [
          {
            name: 'description',
            content: 'View details for this private family.  Make changes appropriate to your authorization level.'
          }
        ]
      }
    },
    {
      path: '/family/selection',
      name: 'Family Selection',
      component: FamilySelection,
      meta: {
        title: 'Family Selection',
        metaTags: [
          {
            name: 'description',
            content: 'Select a new family from a list of public and private families available.'
          }
        ]
      }
    },
    {
      path: '/info/contactUs',
      name: 'Contact Us',
      component: ContactUs,
      meta: {
        title: 'Contact Us',
        metaTags: [
          {
            name: 'description',
            content: 'This site allows you to display large and complex family trees quickly and in a manner suitable for printing.  A must tool to use before your next family reunion.'
          }
        ]
      }
    },
    {
      path: '/info/terms',
      name: 'terms',
      component: Terms,
      meta: {
        title: 'Terms',
        metaTags: [
          {
            name: 'description',
            content: 'Our terms of service for the Relative To Me website.'
          }
        ]
      }
    },
    {
      path: '/info/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        title: 'Privacy',
        metaTags: [
          {
            name: 'description',
            content: 'Our privacy policy.'
          }
        ]
      }
    },
    {
      path: '/emailManageSettings/:manageEmailToken',
      name: 'Manage Email Settings',
      component: EmailManageSettings,
      meta: {
        title: 'Manage Email Settings',
        metaTags: [
          {
            name: 'description',
            content: 'Manage your email settings for this website.'
          }
        ]
      }
    },
    {
      path: '/security/passwordReset/',
      name: 'Password Reset',
      component: PasswordReset,
      meta: {
        title: 'Password Reset'
      }
    },
    {
      path: '/security/emailVerify/',
      name: 'Verify Email',
      component: EmailVerify,
      meta: {
        title: 'Verify Email'
      }
    },
    {
      path: '/tree/:familyId/:familySEO/:individualId/:individualSEO',
      name: 'Individual Tree',
      component: Tree,
      skipMetaLoadInBeforeEach: true,
      meta: {
        title: 'INDIVIDUAL_TITLE - Relative To Me',
        metaTags: [
          {
            name: 'description',
            content: 'INDIVIDUAL_DESCRIPTION'
          }
        ]
      }
    },
    {
      path: '/tree/:familyId/:familySEO',
      name: 'Family',
      component: FamilyIndividualList,
      skipMetaLoadInBeforeEach: true,
      meta: {
        title: 'FAMILY_TITLE - Relative To Me',
        metaTags: [
          {
            name: 'description',
            content: 'FAMILY_DESCRIPTION'
          }
        ]
      }
    },
    {
      path: '*',
      name: 'Routing Error',
      component: ErrorRoute,
      meta: {
        title: 'Error - Routing'
      }
    }
  ]
})

export default router