<template>
  <div v-if="!isLoading"
       v-dragscroll.pass="allowPanning"
       id="tree-view-container"
       class="grab-bing"
       ref="familyContainer">
    <AncestorGrid :individual-id="individualId" :key="treeKey" v-if="showAncestors" />
    <Child :individual-id="individualId" :key="treeKey" v-if="!showAncestors" />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import AncestorGrid from "@/components/tree/AncestorGrid"
  import Child from "@/components/tree/Child"
  import { allowDragScroll } from '@/logic/general/dragScrollUtils'
  import { buildTreeEvent } from '@/logic/seo/analyticsUtils'
  import { buildIndividualStructuredData } from '@/logic/seo/structuredDataUtils'
  import { setHeaderTags } from '@/logic/seo/headerUtils'

  export default {
    components: {
      AncestorGrid,
      Child
    },
    data() {
      return {
      }
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.seo(to)
      })
    },
    beforeRouteUpdate(to, from, next) {
      this.seo(to)
      next()
    },
    computed: {
      ...mapGetters('authentication', [
        'isLoggedIn'
      ]),
      ...mapState('family', [
        'familyData',
        'familyId',
        'familyName',
        'isPublic',
        'isLoading'
      ]),
      ...mapState('viewState', [
        'maxAncestorGenerations',
        'maxDescendentGenerations',
        'showAncestors',
        'showDecendents',
        'treeRenderOptionVersion'
      ]),
      allowPanning: function () {
        const allow = allowDragScroll()
        // console.log(`Allow panning: ${allow}`)
        return allow
      },
      individualId: function () {
        return this.$route.params.individualId
      },
      treeKey: function () {
        return `${this.individualId}θ${this.treeRenderOptionVersion}`
      }
    },
    methods: {
      seo: function (to) {
        if (!this.isLoading) {
          buildTreeEvent(this, to.params)
          setHeaderTags(to, this.$store.state)
          buildIndividualStructuredData(this.$store.state, to.params)
        }
      }
    },
    created() {
      this.$store.dispatch('viewState/resetRenderedEntities')
    },
    mounted() {
      const vm = this

      // Wait until the familyContainer is finished loading before focusing to the individual
      let timer = setTimeout(function f() {
        if('familyContainer' in vm.$refs) {
          vm.$scrollTo('.focus-individual')
        } else {
          clearTimeout(timer)
          timer = setTimeout(f, 10)
        }
      }, 10)
    },
    watch: {
      isLoggedIn: function (newValue) {
        if (newValue === false && this.isPublic === false) {
          this.$router.push({ path: `/` })
        }
      },
      isLoading: function () {
        this.seo(this.$route)
      }
    }
  }

</script>