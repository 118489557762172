export default {
    familyStartLoad (state) {
      state.isLoading = true
    },
    familyStopLoad (state) {
      state.isLoading = false
    },
    familyEndLoad (state, payload) {
      state.familyData = payload.familyData || {}
      state.familyAuthorityId = payload.familyAuthorityId || 1
      state.familyDescription = payload.familyDescription || null
      state.familyId = payload.familyId
      state.familyName = payload.familyName || ''
      state.individualsArray = payload.individualsArray || []
      state.individualSearchMap = payload.individualSearchMap || null
      state.isPublic = payload.isPublic || false
      state.isLoading = false
      state.author = payload.author || null
      state.sourceUrl = payload.sourceUrl || null
      state.suppressGender = payload.suppressGender || false
    }
}