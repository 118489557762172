export function buildTreeEvent(vm, toParams) {
  const individualId = toParams.individualId
  const individualSEO = toParams.individualSEO
  const familyId = toParams.familyId
  const familySEO = toParams.familySEO

  if (individualId){
    vm.$gtag.event('tree', {
      familyId,
      familySEO,
      individualId,
      individualSEO
    })
  }
}

export function buildFamilyIndividualListEvent(vm, toParams) {
  const familyId = toParams.familyId
  const familySEO = toParams.familySEO

  vm.$gtag.event('family_individual_list', {
    familyId,
    familySEO
  })
}