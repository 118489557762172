// Reset the vee-validate form validation
export function resetFormDelayed (component) {
  component.$nextTick(() => {
    component.$refs.observer.reset();
  });
}

export function resetFormImmediate (component) {
  component.$refs.observer.reset();
}

export function getValidationState({ dirty, validated, valid = null }) {
  return dirty || validated ? valid : null;
}