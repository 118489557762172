<template>
  <div v-if="isLoggedInUnverified" class="banner-unverified">
    <span class="pr-5"> Your email address has not been verified. <b-icon icon="info-square" id="unverifiedEmailInfo" class="hover-pointer" /></span>
    <b-badge variant="light" @click="EmailSendVerificationRequest" class="action-icon">Click here to resend the account verification email.</b-badge>

    <b-popover target="unverifiedEmailInfo"
               custom-class="popover-wide"
               placement="bottom"
               title="Your email address has not been verified"
               triggers="click blur">
      <p>Until you have verified your email, you will have limited access.</p>
      <p>When you registered or changed your email address a message was sent to your inbox. Find that message and follow the instructions there.</p>
      <p>That email might take a few minutes to arrive in your inbox.</p>
    </b-popover>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as apiUtils from '@/logic/general/apiUtils'

  export default {
    name: 'BannerUnverifiedEmail',
    computed: {
      ...mapGetters('authentication', [
        'isLoggedInUnverified'
      ]),
    },
    methods: {
      EmailSendVerificationRequest: function () {
        this.$bvModal.msgBoxConfirm('Confirm resend?', {size: 'sm'})
        .then(value => {
          if (value) {
            this.axios.post(apiUtils.buildAPIUrl('account/emailSendVerificationRequest'))
          }
        })
      }
    }
  }
</script>

<style scoped>
  .banner-unverified {
    color: white;
    background: mediumpurple;
    padding-left: 16px;
    width: 100%;
  }

  .resendLinkx {
    color: white;
    padding-bottom: 8px;
    padding-top: 4px;
  }
</style>