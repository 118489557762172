import moment from 'moment'

export const standardDateFormat = new Intl.DateTimeFormat(undefined,{
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
})

export function convertGEDCOMDate(value) {
  // Determin what parts have been provided in the date
  // Has day been supplied?
  const red = /\b[0-9]{1,2}\b/g
  const hasDay = value.match(red)

  // Has month been supplied?
  const rem = /[Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec]{3}/g
  const hasMonth = value.match(rem)

  // Has year been supplied?
  const rey = /[0-9]{4}/g
  const hasYear = value.match(rey)

  if (hasDay){
    // If we have a day, let's assume that we have month and year as well
    return moment(value, ['DMMMYYYY','DDMMMYYYY']).format('YYYY-MM-DD')
  } else if (hasMonth) {
    // if we have a month, let's assume that we have the year as well.
    return moment(value, 'MMM YYYY', true).format('YYYY-MM-DD')
  } else if (hasYear) {
    return moment(value, 'YYYY', true).format('YYYY-MM-DD')
  } else {
    return ''
  }
}