export default class Generation {
  endRow = () => { 
    if (this.households.length === 0) {
      return Number.MIN_SAFE_INTEGER
    } else {
      return Math.max(...this.households.map(x => x.endRow()))
    }
  }
  households = []
  nextAvailableRow = () => {
    if (this.households.length === 0) {
      return Number.MIN_SAFE_INTEGER
    } else {
      // We can't allow overlaps with another household group above
      const lastHousehold = this.households[this.households.length - 1]
      const lastHouseholdEndRow = lastHousehold.endRow()
      const childHouseholdEndRow = lastHousehold.childHousehold ? lastHousehold.childHousehold.endRow() : Number.MIN_SAFE_INTEGER

      return Math.max(lastHouseholdEndRow, childHouseholdEndRow) + 1;
    }
  }
}
