import _ from 'lodash'

import * as cultureUtils from '@/logic/culture/cultureUtils'
import * as familyFactUtils from '@/logic/family/familyFactUtils'
import male from '@/assets/male.png'
import female from '@/assets/female.png'

const referenceErrorIndividual = {
  names: [
    {
      fullName: "(GEDCOM Reference Error: Individual Missing)"
    }
  ]
}

// [Deprecated]
export function getAvatarUrl(individual) {
  const images = _.get(individual, 'files', []).filter(x => x.primary)
  const url = _.get(images[0], 'file')

  if (url)
    return url.includes('://') ? url : url.replace(':','://')
  else {
    const gender = getGender(individual)
    return getGenderAvatarUrl(gender)
  }
}

export function getGenderAvatarUrl(gender) {
  switch (gender) 
  {
    case 'F':
      return female  
    case 'M':
      return male 
    default:
      return male    // TODO gender neutral
  }
}

export function getIndividual(familyData, individualId, showReferenceError = true) {
  let individual = familyData.individuals[individualId]

  if (!individual && showReferenceError) {
    individual = {...referenceErrorIndividual, id: individualId}
  }

  return individual
}

export function getName(individual) {
  const name = familyFactUtils.getGroupMemberByPrimaryOrFirst(individual, 'names')
  const fullname = _.get(name, 'fullName', ' ')
  const nameSuffix = _.get(name, 'nameSuffix')
  const nickname = _.get(name, 'nickname')

  // Alter the fullname
  const names = fullname.split(' ').filter(word => nameSuffix ? nameSuffix != word : true)  // Strip out the nameSuffix

  // Nickname handling
  if (nickname) {
    const i = names.findIndex(word => word.toLowerCase() === nickname.toLowerCase())

    // If the nickname exists as a one of the names
    if (i === -1) {  
      // The nickname was not found.  Insert it as the second name
      names.splice(1, 0, `(${nickname})`)
    } else {
      // Wrap the name that matches the nickname in single quotes
      names[i] = `'${nickname}'`
    } 
  }

  return names.join(' ').trim()
}

export function getFormalName(individual) {
  const name = familyFactUtils.getGroupMemberByPrimaryOrFirst(individual, 'names')
  const fullname = _.get(name, 'fullName', ' ')
  const titleAttribute = familyFactUtils.getGroupMemberByCodeLast(individual, 'attributes','TITL')
  const names = fullname.split(' ')

  if (titleAttribute) {
    const title = _.get(titleAttribute, 'value')
    names.splice(0,0,title)
  }

  return names.join(' ').trim()
}

export function getGender(individual) {
  return _.get(individual, 'sex')
}

export function getGenderIcon(gender) {
  switch (gender)
  {
    case 'F':
      return 'venus'
    case 'M':
      return 'mars'
    default:
      return 'genderless'
  }
}

export function getBirthDeathSummary(individual, language) {
  const birthEvent = familyFactUtils.getGroupMemberByCodePrimaryOrFirst(individual, 'events','BIRT')
  const deathEvent = familyFactUtils.getGroupMemberByCodePrimaryOrFirst(individual, 'events','DEAT')
  const birth = cultureUtils.formatDate(_.get(birthEvent, 'date.date'), language)
  const death = cultureUtils.formatDate(_.get(deathEvent, 'date.date'), language)

  if (birth && death)
    return `${birth} - ${death}`
  else if (birth)
    return `b. ${birth}`
  else if (death)
    return `d. ${death}`
  else 
    return ''
}
