import _ from 'lodash'
import * as cultureUtils from '@/logic/culture/cultureUtils'
import * as familyFactDefinitions from '@/logic/family/familyFactDefinitions'
import { getGroupMemberByCodes, pushValue, pushValueByPath } from '@/logic/family/familyFactUtils'

const displayableFormats = ['bmp','gif','jpg']

class Fact {
  citationNumber = null
  displayableFileNumber = null
  factDisplayType = 3
  factFormatType = 0
  label = null  
  pointerType = null
  private = false
  subfacts = []
  value = null
}

class FactDefinition {
  alternatePathList = null          // If a value is not returned from the pathList, the alternatePathList is used.  The alternatePathList is only used if value and pathList have no result.
  citationPath = null               // The path to the potential citations for this fact
  factDisplayType = 3               // How this fact should be rendered:  1=1 column, 2=2 column 3=3 Column
  factFormatType = 0                // How the fact should be formatted as a subfact: 0=Normal, 1=Note, 2=Text
  forceCreation = false             // Create this fact even if there is no value specified?
  isSensitive = false               // Is sensitive information and view can be restricted to select individuals
  labelKey = null                   // The label key for the translation tables for this fact.  If one exists, a label will be shown
  map = null                        // A map of values each of which can provide a different language specific value.  Example:  M=Male,hombre,etc    F=Female,mujer,etc
  multivaluePath = null             // If each element of an array is a separate fact, this is the path to the array itself
  pathList = null                   // Either a single string or an array of strings.  All will be used by the lodash _.get function to find the values.  If the pathList is an '*', return the whole entity.  Useful when paired with multivaluePath.
  pointerType = null                // If this fact points to another entity such as an individual or submittor
  private = false                   // TODO Only someone with the appropriate authority can see this fact.
  replacer = null                   // A list of items within the value that will be replaced by language specific words.  Example:   JAN=Jan,enero,etc    FEB=Feb,feb
  separator = ''                    // If multiple paths were provided then the resulting values will be concatenated together with this separator
  specialHandlingType = null        // If special logic is required for the fact value
  value = null                      // Value can be provided directly
}

class FactResult {
  primaryFact = new Fact()
  attributeFacts = []
  citations = []
  displayableImages = []
  eventFacts = []
  generalFacts = []
  ldsEventFacts = []
  noteFacts = []
  otherFacts = []
}

export default class FamilyFactFactory {
  // A note regarding the private variables.   Vue appears to modify the class instance making the fields read-only.  To prevent that, the fields need to be private
  // however, they no longer show up on the Chrome dev tools so I am only changing these two.  Alternately, I could put them inside a sub-object.

  familyData = null
  language = null
  canViewSensitive = false
  #citations = []
  #displayableImages = []
  #lastCitationNumber = 0
  #lastCitationPointer = null
  #lastDisplayableFileNumber = 0
  #usedCitationPointers = {}

  #TitleExternalFile = cultureUtils.getLabel('externalFile',this.language)
  #TitleFileNotDisplayable = cultureUtils.getLabel('fileNotDisplayable',this.language)
  
  constructor(familyData, language, canViewSensitive){
    this.familyData = familyData
    this.language = language
    this.canViewSensitive = canViewSensitive
  }
  
  addCodeFacts(entity, sourceProperty, eventCodes, factArray, factMap) {
    const items = getGroupMemberByCodes(entity, sourceProperty, eventCodes)
  
    items.forEach(item => {
      const fact = this.pushFactByPath(item, factArray, {forceCreation: true, citationPath: 'sources', labelKey: item.code, pathList: 'date.date', specialHandlingType: 'date'})
      this.addFactsFromMap(item, factMap, fact.subfacts)
    })
  }
  
  addFactsFromMap(entity, factMap, factArray) {
    factMap.forEach(factDefinition => {
      switch (factDefinition.specialHandlingType) {
        case 'multimedia':
          this.addMultimediaFacts(entity, factArray, factDefinition.pathList)
          break;
        case 'note':
          this.addNoteFacts(entity, factArray, factDefinition.pathList)
          break;
        case 'placeReference':
          this.addPlaceReferenceFacts(entity, factArray, factDefinition.pathList)
          break;
        case 'submission':
          this.addSubmissionFacts(entity, factArray, factDefinition.pathList)
          break;
        case 'submittor':
          this.addSubmittorFacts(entity, factArray, factDefinition.pathList)
          break;
        default: 
          this.pushFactByPath(entity, factArray, factDefinition)
      }     
    })
  }

  // Notes can have citations and citations can have notes.  It could, in theory, go on forever so we don't support citations on notes within citations.
  addNoteFacts(entity, factArray, notesPath, { supportsCitations } = { supportsCitations: true }) {
    const notes = _.get(entity,notesPath,[])

    notes.forEach(note => {
      const pointer = note.pointer
      let fact = this.pushFactByPath(note, factArray, {pathList: 'value', specialHandlingType: 'note', factDisplayType: 1, factFormatType: 1 })

      if (supportsCitations) {
        this.buildCitation(note, fact, {citationPath: 'sources'})
      }

      if (pointer) {
        const record = this.familyData.notes[pointer]
        fact = this.pushFactByPath(record, factArray, {pathList: 'value', specialHandlingType: 'note', factDisplayType: 1, factFormatType: 1 })

        if (record.pointer) {
          this.pushFactByPath(record, factArray, {labelKey: 'reference', pathList: ['reference.type', 'reference.value'], separator: ': '})
          this.pushFactByPath(record, factArray, {labelKey: 'recordIdNumber', pathList: 'abbreviation'})
        } 
      }
    })
  }

  addMultimediaFacts(entity, factArray, objectPath, { supportsCitations } = { supportsCitations: true }) {
    const multimediaLinks = _.get(entity,objectPath,[])

    multimediaLinks.forEach(multiMediaLink => {
      let record = multiMediaLink
      const pointer = record.pointer

      if (pointer) {
        record = this.familyData.multimedia[pointer]
      }

      const fact = this.pushFact(multiMediaLink, factArray, {labelKey: 'multimedia', factDisplayType: 2, forceCreation: true})

      if (supportsCitations) {
        this.buildCitation(record, fact, { citationPath: 'sources' })
      }

      const baseForm = _.get(record,'format','')
      const baseTitle = _.get(record,'title')
      const files = _.get(record,'files',[])

      files.forEach(file => {
        // Format
        const formparts = _.get(file,'format',baseForm).toLowerCase().split(':')
        const form = formparts[formparts.length-1]

        // Title & media
        const title = _.get(file,'title',baseTitle)
        this.pushFact(file, fact.subfacts, {value: title})
        this.pushFactByPath(file, fact.subfacts, {labelKey: 'media', pathList: 'media'})

        // Url
        const url = _.get(file,'url')
        const isDisplayable = url && url.toLowerCase().startsWith('http') && displayableFormats.includes(form)

        if (isDisplayable) {
          const location = new URL(url)
          const hostname = location.hostname
          const linkTitle = `${this.#TitleExternalFile}: ${hostname}`
          const link = `<a href="${url}" target="_blank">${linkTitle}</a>`
          const urlFact = this.pushFact(file, fact.subfacts, {labelKey: 'url', value: link})

          urlFact.displayableFileNumber = ++this.#lastDisplayableFileNumber
          this.#displayableImages.push({
            displayableFileNumber: urlFact.displayableFileNumber,
            title: title,
            url: url
          })
        } else {
          this.pushFact(file, fact.subfacts, {value: `${url} *(${this.#TitleFileNotDisplayable })*`})
        }
      })

      this.addFactsFromMap(record, familyFactDefinitions.mediaFactMap, fact.subfacts)
    })
  }

  addSubmissionFacts(entity, factArray, submissionPath) {
    const submission = _.get(entity,submissionPath)
    this.pushFactByPath(submission, factArray, {pathList: 'submittors', specialHandlingType: 'submittor'})
    this.pushFactByPath(submission, factArray, {labelKey: 'temple', pathList: 'ldsTemple'})
    this.pushFactByPath(submission, factArray, {labelKey: 'ordinance', pathList: 'ordinance'})
    this.pushFactByPath(submission, factArray, {labelKey: 'recordIdNumber', pathList: 'recordIdNumber'})

  }

  addSubmittorFacts(entity, factArray, submittorPath) {
    const submittors = _.get(entity,submittorPath,[])

    submittors.forEach(submittor => {
      const record = this.familyData.submittors[submittor]
      this.pushFactByPath(record, factArray, {labelKey: "submitter", factDisplayType: 2, subfacts: [
        {multivaluePath: 'names', pathList: 'fullName', separator: ', '}, 
        {pathList: ['address.address1','address.address2','address.city','address.subdivision','address.postalCode','address.country'], labelKey: 'address', separator: ', '},
        {pathList: 'address.phones', alternatePathList: 'phones', labelKey: 'phones', separator: ', ', isSensitive: true},
        {pathList: 'address.emails', alternatePathList: 'emails', labelKey: 'emails', separator: ', ', isSensitive: true},
        {pathList: 'address.faxes', alternatePathList: 'faxes', labelKey: 'faxes', separator: ', ', isSensitive: true},
        {pathList: 'address.urls', alternatePathList: 'urls', labelKey: 'urls', separator: ', '},
        {pathList: 'multimedia', specialHandlingType: 'multimedia'},
        {pathList: 'familyFile', labelKey: 'file'},
        {pathList: 'recordIdNumber', labelKey: 'recordIdNumber'},
        {pathList: 'recordFileNumber', labelKey: 'recordFileNumber'},
        {pathList: 'date.date', labelKey: 'lastChange'}
      ]})
    })
  }

  addPlaceReferenceFacts(entity, factArray, placePath) {
    const placeReference = _.get(entity,placePath,{})
    const pointer = placeReference.pointer

    if (pointer) {
      const record = this.familyData.places[pointer]
      this.pushFactByPath(record, factArray, {labelKey: 'map', pathList: ['map.latitude','map.longitude'], separator: ','})
      this.addNoteFacts(record, factArray, 'notes')
      this.addMultimediaFacts(record, factArray, 'multimedia')
    }
  }

  addRepositoryFacts(entity, factArray, placePath) {
    const repositoryReference = _.get(entity,placePath,{})
    const record = repositoryReference.pointer ? this.familyData.repositories[repositoryReference.pointer] : {}
    this.addFactsFromMap(record, familyFactDefinitions.repositoryFactMap1, factArray)
    this.pushFactByPath(repositoryReference, factArray, {labelKey: 'repository', pathList: 'value'})
    this.pushFactByPath(repositoryReference, factArray, {multivaluePath: 'sourceCallNumbers', pathList: ['media', 'value'], labelKey: 'sourceCallNumbers', separator: ': '})
    this.addNoteFacts(repositoryReference, factArray, 'notes')
    this.addFactsFromMap(record, familyFactDefinitions.repositoryFactMap2, factArray)
  }

  buildCitation(entity, fact, factDefinition) {
    const sources = _.get(entity, factDefinition.citationPath, [])

    if (sources.length > 0) {
      const citation = {
        citationNumber: ++this.#lastCitationNumber,
        footnote: null,
        subfacts: []
      }

      this.#citations.push(citation)
      fact.citationNumber = citation.citationNumber
      const footnotes = []

      sources.forEach(source => {
        const pointer = source.pointer
        const record = pointer ? this.familyData.sources[pointer] : {}
        const value = source.value
        const footnoteParts = []
        const nameParts = []
        let includeFactDetails = false

        if (value) {
          footnoteParts.push(value)
          this.pushFactByPath(source, citation.subfacts, {multivaluePath: 'text', pathList: '*', factDisplayType: 3, factFormatType: 2 })
        } 

        // We construct the name of the source using standard citation rules.
        const author = pushValueByPath(record, nameParts, 'author') 
        const title = pushValueByPath(record, nameParts,'title') 
        const publication = pushValueByPath(record, nameParts,'publication') 

        if (pointer === this.#lastCitationPointer) {
          footnoteParts.push('*ibid.*')
        } else if (this.#usedCitationPointers[pointer]) {
          if (nameParts.length > 0) footnoteParts.push(nameParts[0])
          footnoteParts.push('*op.cit.*')
        } else {
          includeFactDetails = true
          const p = []
          pushValue(p, title, '*')
          pushValue(p, publication, '(')
          const titlePublication = p.join(' ')

          pushValue(footnoteParts,author) 
          pushValue(footnoteParts,titlePublication) 
        }

        // Push other facts
        if (includeFactDetails) {
          this.addNoteFacts(source, citation.subfacts, 'notes', { supportsCitations: false })
          this.pushFactByPath(source, citation.subfacts, {multivaluePath: 'data.text', pathList: '*', factDisplayType: 3, factFormatType: 2 })  
          this.pushFactByPath(source, citation.subfacts, {multivaluePath: 'events', pathList: 'value', labelKey: 'event', map: 'generalMap'})      
          this.pushFactByPath(source, citation.subfacts, {multivaluePath: 'events', pathList: 'role', labelKey: 'role', map: 'roleMap'})   
          this.pushFactByPath(source, citation.subfacts, {pathList: 'data.date.date', labelKey: 'date', specialHandlingType: 'date'})                   
          this.addMultimediaFacts(source, citation.subfacts, 'multimedia')    

          this.pushFactByPath(record, citation.subfacts, {pathList: 'abbreviation', labelKey: 'abbreviation'}) 
          this.addNoteFacts(record, citation.subfacts, 'notes', { supportsCitations: false }) 
          this.pushFactByPath(record, citation.subfacts, {multivaluePath: 'text', pathList: '*', factDisplayType: 3, factFormatType: 2 }) 
          this.pushFactByPath(record, citation.subfacts, {multivaluePath: 'data.events', pathList: 'value', labelKey: 'event', map: 'generalMap', subfacts: [       
            {pathList: 'date.date', labelKey: 'date', specialHandlingType: 'date'},  
            {pathList: 'place.value', labelKey: 'place'},  
          ]})
          this.addMultimediaFacts(record, citation.subfacts, 'multimedia')   
          this.pushFactByPath(record, citation.subfacts, {pathList: 'agency', labelKey: 'agency' })  
          this.pushFactByPath(record, citation.subfacts, {pathList: ['reference.type', 'reference.value'], labelKey: 'reference', separator: ': '})  
          this.pushFactByPath(record, citation.subfacts, {pathList: 'recordIdNumber', labelKey: 'recordIdNumber'})

          this.pushFactByPath(source, citation.subfacts, {pathList: 'certaintyAssessment', labelKey: 'certaintyAssessment', map: 'pedigreeMap' })    
          this.pushFactByPath(record, citation.subfacts, {pathList: ['lastChange.date.date','lastChange.date.time'], labelKey: 'lastChange', separator: ' ', specialHandlingType: 'date', subfacts: [    
            {pathList: 'lastChange.notes', specialHandlingType: 'note', factDisplayType: 1, factFormatType: 1}   
          ]})

          this.pushFactByPath(source, citation.subfacts, {pathList: 'version', labelKey: 'version'})

          this.addRepositoryFacts(record, citation.subfacts, 'repository', { supportsCitations: false })
        }

        pushValueByPath(source, footnoteParts, 'page') 
        footnotes.push(footnoteParts.join(', ')) 
        this.#lastCitationPointer = pointer 
        this.#usedCitationPointers[pointer] = true   
      });

      citation.footnote = footnotes.join('; ')
    }
  }

  buildFacts(entity, generalFactMap, eventCodes, ldsEventCodes, attributeCodes, otherFactMap) {
    this.#lastCitationNumber = 0
    this.#lastCitationPointer = null
    this.#lastDisplayableFileNumber = 0
    this.#usedCitationPointers = {}
    this.#citations = []
    this.#displayableImages = []

    const result = new FactResult()
    result.citations = this.#citations
    result.displayableImages = this.#displayableImages

    // Look for a citation on the top level entity
    this.buildCitation(entity, result.primaryFact, { citationPath: 'sources' })

    this.addFactsFromMap(entity, generalFactMap, result.generalFacts)
    this.addCodeFacts(entity, 'events', eventCodes, result.eventFacts, familyFactDefinitions.eventSubfactMap)
    this.addCodeFacts(entity, 'ldsIndividualOrdinance', ldsEventCodes, result.ldsEventFacts, familyFactDefinitions.ldsEventSubfactMap)
    this.addCodeFacts(entity, 'attributes', attributeCodes, result.attributeFacts, familyFactDefinitions.eventSubfactMap)
    this.addNoteFacts(entity, result.noteFacts, 'notes')
    this.addFactsFromMap(entity, otherFactMap, result.otherFacts)

    return result
  }

  buildHeaderFacts(header) {
    return this.buildFacts(
      header, 
      familyFactDefinitions.headerGeneralFactMap,
      [],
      [],
      [],
      [])
  }

  buildIndividualFacts(individual) {
    return this.buildFacts(
      individual, 
      familyFactDefinitions.individualGeneralFactMap, 
      familyFactDefinitions.individualEventCodes, 
      familyFactDefinitions.individualLDSEventCodes,
      familyFactDefinitions.individualAttributeCodes, 
      familyFactDefinitions.individualOtherFactMap)
  }

  buildUnionFacts(union) {
    return this.buildFacts(
      union, 
      familyFactDefinitions.unionGeneralFactMap, 
      familyFactDefinitions.unionEventCodes, 
      familyFactDefinitions.unionLDSEventCodes,
      familyFactDefinitions.unionAttributeCodes, 
      familyFactDefinitions.unionOtherFactMap)
  }

  buildFamilyRelationshipFacts(familyRelationship) {
    const result = []
    this.addFactsFromMap(familyRelationship, familyFactDefinitions.childToFamilyMap, result)
    return result
  }

  // Some values need special formatting
  formatValue(factDefinition) {
    let result = factDefinition.value || ''

    if (Array.isArray(result)) {
      result = result.join(factDefinition.separator)
    }

    switch (factDefinition.specialHandlingType) 
    {
      case 'age':
        result = cultureUtils.formatAge(result, this.language)
        break;
      case 'date':
        result = cultureUtils.formatDate(result, this.language)
        break;
      case 'eventValue':
        result = cultureUtils.formatEventValue(result, this.language)
        break;
      case 'note':
        result = cultureUtils.formatNote(result, this.language)
        break;
    }

    if (factDefinition.map)
      result = cultureUtils.getLocalizedValue(result, factDefinition.map, this.language)

    if (factDefinition.replacer)
      result = cultureUtils.replaceTranslatableItems(result, factDefinition.replacer, this.language)

    return result.toString().replace(/\n/g,'<br />')
  }

  pushFact(entity, factArray, factDefinition = new FactDefinition()) {
    if (factDefinition.isSensitive && !this.canViewSensitive) return

    const fact = {...new Fact(), ..._.pick(factDefinition, ['factDisplayType','factFormatType','pointerType','private'])}
    fact.label = cultureUtils.getLabel(factDefinition.labelKey, this.language)
    fact.value = factDefinition.value ? this.formatValue(factDefinition) : null

    if (fact && factDefinition.citationPath) {
      this.buildCitation(entity, fact, factDefinition)
    }

    if (factDefinition.subfacts) {
      this.addFactsFromMap(entity, factDefinition.subfacts, fact.subfacts)
    }

    if (fact.value || factDefinition.forceCreation || fact.subfacts.length > 0) {
      factArray.push(fact)
      return fact
    } else {
      return null
    }
  }

  pushFactDetail(entity, factArray, factDefinition) {
    if (Array.isArray(factDefinition.pathList)) {
      const r = []
      factDefinition.pathList.forEach(path => {
        pushValueByPath(entity, r, path)
      })
      factDefinition.value = r.join(factDefinition.separator)
    } else {
      // If the pathlist is an * than return the whole entity
      factDefinition.value = factDefinition.pathList === '*' ? entity : _.get(entity, factDefinition.pathList)
    }

    if (!factDefinition.value && factDefinition.alternatePathList) {
      factDefinition.value = _.get(entity, factDefinition.alternatePathList)
    }

    const fact = this.pushFact(entity, factArray, factDefinition)
  
    return fact
  }

  pushFactByPath(entity, factArray, factDefinition = new FactDefinition()) {
    if (factDefinition.multivaluePath) {
      const values = _.get(entity, factDefinition.multivaluePath,[])
      values.forEach(item => {
        this.pushFactDetail(item, factArray, factDefinition)
      })
    } else {
      return this.pushFactDetail(entity, factArray, factDefinition)
    }
  }
}