<template>
  <div>
    <b-jumbotron header="Reset Password">
      <div v-if="status===1">
        <h3>Error</h3>
        <p>The password reset email you just used is for a different user than the one you are currently logged in to.  If you want to use that email to reset a password, please log off the current user first.</p>
      </div>
      <div v-if="status===2">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <h3>Please type in your new password</h3>
            <validation-provider vid="password" name="Password" :rules="{required: true, regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/gm }" v-slot="validationContext">
              <b-form-group
                label-cols-sm="3"
                label="Password:"
                label-align-sm="right"
                label-for="password-box">
                <b-form-input id="password-box"
                              type="password"
                              v-model="form.password"
                              :state="getValidationState(validationContext)"
                              autocomplete="new-password"
                              tabindex="4" />
                <b-form-invalid-feedback id="password-feedback">The password must be at least 10 characters long containing the following: upper case letters, lower case letters, and digits.</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider name="Confirm Password" :rules="{required: true, confirmed:'password' }" v-slot="validationContext">
              <b-form-group
                label-cols-sm="3"
                label="Confirm Password:"
                label-align-sm="right"
                label-for="password-box">
                <b-form-input id="confirmPassword-box"
                              type="password"
                              v-model="form.confirmPassword"
                              :state="getValidationState(validationContext)"
                              autocomplete="new-password"
                              tabindex="5" />
                <b-form-invalid-feedback id="confirmPassword-feedback">The passwords must match.</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="d-flex justify-content-end">
              <b-button :disabled="postState.inProgress"
                        type="submit"
                        variant="primary"
                        tabindex="5"
                        class="ml-2">
                Submit
              </b-button>
            </div>
            <ApiPostFeedback :post-state="postState" />
          </b-form>
        </validation-observer>
      </div>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as apiUtils from '@/logic/general/apiUtils'
import * as authenticationUtils from '@/logic/general/authenticationUtils'
import * as validationUtils from '@/logic/general/validationUtils'
import ApiPostFeedback from "@/components/ApiPostFeedback"

export default {
  components: {
    ApiPostFeedback
  },
  data() {
    return {
      form: {
        confirmPassword: "",
        token: "",
        userId: "",
        password: ""
      },
      postState: {},
      status: 0
    }
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn'
    ]),
    ...mapState('authentication', [
      'jwt'
    ])
  },
  methods: {
    getValidationState: function (validationContext) {
      return validationUtils.getValidationState(validationContext)
    },
    initialize: function () {
      // Reset the state
      this.form.token = this.$route.query.token
      this.form.userId = this.$route.query.userId
      this.setStatus()
    },
    onSubmit: function () {
      this.postState = apiUtils.resetPostState()
      this.axios.post(apiUtils.buildAPIUrl('account/passwordReset'), this.form)
        .then(response => {
          this.postState = response.postState
          this.$auth.storageType = authenticationUtils.determineStorageLocation(false)
          this.$auth.setToken(response.data)
          this.resetForm()
          this.$store.dispatch('authentication/signalNewAccessToken', this)
          this.$router.push({ path: `/` })
        })
    },
    resetForm: function () {
      this.postState = {}
      this.form = {
        confirmPassword: "",
        token: "",
        userId: "",
        password: ""
      }
      validationUtils.resetFormImmediate(this)
    },
    setStatus: function () {
      if (this.isLoggedIn && this.jwt.nameid !== this.form.userId) {
        this.status = 1
      } else {
        this.status = 2
      }
    }
  },
  watch: {
    $route: function(to, from) {
      // If the user comes back here with a new token ...
      this.initialize()
    }
  },
  mounted() {
    // The first time the user comes here ...
    this.initialize()
  }
}
</script>