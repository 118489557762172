<template>
  <div>
    <b-jumbotron header="Email verification">
      <div v-if="status===1">
        <h3>Login to verify your email.</h3>
        Select the <b-link v-b-modal.authenticationModal>Login</b-link> link in the header to login.
      </div>
      <div v-if="status===2">
        <h3>Your email was already verified.  You're good to go.</h3>
      </div>
      <div v-if="status===3">
        <h3>Processing email verification ...</h3>
      </div>
      <div v-if="status===4">
        <h3>Your email has been verified.  You're good to go.</h3>
      </div>
      <div v-if="status===5">
        <h3>Email verification failed.</h3>
        <p>Here are some common reasons why it would fail:</p>
        <ul>
          <li>The email is too old.  You only have 1 hour after the email is sent.  You can send another by clicking the link at the very top of the page.</li>
          <li>You are logged on to a different user</li>
        </ul>
      </div>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as apiUtils from '@/logic/general/apiUtils'
import * as authenticationUtils from '@/logic/general/authenticationUtils'

export default {
  data() {
    return {
      attemptFailed: false,
      attemptedVerify: false,
      status: 0,
      token: null,
    }
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn',
      'isLoggedInFully'
    ]),
    ...mapState('authentication', [
      'forceAuthenticationReactivity'
    ])
  },
  methods: {
    initialize: function () {
      // Reset the state
      this.attemptFailed = false
      this.attemptedVerify = false
      this.token = this.$route.query.token
      this.setStatus()

      if (!this.isLoggedIn) {
        // Show the login prompt but only attempt this once.  If the user cancels out of the login they must click it themselves manually
        this.$bvModal.show('authenticationModal')
      }
    },
    setStatus: function () {
      // Determine the status for display purposes 
      if (this.isLoggedIn) {
        if (this.isLoggedInFully) {
          // Logged in and the email was verified on login
          if (this.attemptedVerify) {
            this.status = 4  // Verification succeeded
          } else {
            this.status = 2  // Account already verified on login
          }
        } else {
          // Logged in but the email has not been verified
          if (this.attemptFailed) {
            this.status = 5   // An attempt was made to verify this token but it failed
          } else {
            this.status = 3   // Attempt verification
          }
        }
      } else {       
        this.status = 1  // Attempt login
      }

      // Now attempt to verify.  This will only be attempted once
      if (this.status === 3 && !this.attemptedVerify) {
        this.attemptedVerify = true
        this.axios.post(apiUtils.buildAPIUrl('account/emailVerify'), { token: this.token })
          .then(response => {
            this.$auth.storageType = authenticationUtils.determineStorageLocation(response.data.stayLoggedIn)
            this.$auth.setToken(response.data)
            this.$store.dispatch('authentication/signalNewAccessToken', this)
          })
          .catch(error => {
            this.attemptFailed = true
            this.status = 5 
          })
      }
    }
  },
  watch: {
    $route: function(to, from) {
      // If the user comes back here with a new token ...
      this.initialize()
    }, 
    forceAuthenticationReactivity: function (newValue, oldValue) {
      this.setStatus()
    }
  },
  mounted() {
    // The first time the user comes here ...
    this.initialize()
  }
}
</script>