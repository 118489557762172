<template>
  <fragment>
    <div class="d-flex flex-wrap justify-content-between">
      <div>
        <h4>Invitations Available</h4>
      </div>
      <div>
        <b-pagination
          v-if="hasPageTwo"
          v-model="tableCurrentPage"
          :per-page="tablePerPage"
          :total-rows="tableRows"
          aria-controls="my-table"
          align="right" />
      </div>
    </div>
    <b-table :busy="busy"
             :current-page="tableCurrentPage"
             :items="tableData"
             :fields="tableFields"
             :per-page="tablePerPage"
             empty-text="There are no more invitations to show"
             hover
             responsive
             show-empty
             stacked="md"
             striped>
      <template #cell(familyNameBase)="row">
        {{ row.item.familyNameBase }} 
      </template>
      <template #cell(actions)="row">
        <b-button v-if="!isProcessing(row)"
                  @click="onAcceptInvitation(row)"
                  class="action-icon"
                  variant="primary"
                  size="sm">
          <b-icon icon="clipboard-check" />Accept
        </b-button>
        <div v-if="hasRowError(row)" class="alert alert-danger alert-table" v-b-tooltip.hover :title="rowError(row)">Error</div>
      </template>
      <template #table-busy>
        <TableBusy />
      </template>
    </b-table>
  </fragment>
</template>

<script>
import shortUUID from 'short-uuid'
import * as apiUtils from '@/logic/general/apiUtils'
import * as urlUtils from '@/logic/general/urlUtils'
import TableBusy from '@/components/TableBusy'

export default {
  components: {
    TableBusy
  },
  props: {
    showViewed: {type: Boolean, required: true}
  },
  data() {
    return {
      busy: false,
      tableCurrentPage: 1,
      tableData: [],
      tableFields: [
        {key: "actions", tdClass: "info-table-action"},
        {key: "familyNameBase", label: "Family Name"},
        {key: "sponsorUserName", label: "Sponsor"}
      ],
      tablePerPage: 10,
      tableRowErrors: {},
      tableRows: 0,
      tableRowsProcessing: {},
      viewSession: shortUUID.generate()
    }
  },
  computed: {
    hasPageTwo: function () {
      return this.tableRows > this.tablePerPage
    }
  },
  methods: {
    hasRowError: function (row) {
      return this.tableRowErrors[row.item.familyInvitationId] ? true : false
    },
    isProcessing: function (row) {
      return this.tableRowsProcessing[row.item.familyInvitationId]
    },
    onAcceptInvitation: function (row) {
      this.$set(this.tableRowsProcessing, row.item.familyInvitationId, true)
      this.axios.post(apiUtils.buildAPIUrl(`familyInvitation/accept/${row.item.familyInvitationId}`))
          .then(response => {
            const familyId = row.item.familyId
            const pathNew = urlUtils.getFamilyURL(familyId, row.item.familyNameBase)
            const payload = { vm: this, familyId }

            this.$store.dispatch('family/loadFamily', payload)
              .then(() => {
                this.postState = response.postState

                if (this.tableRows === 1) {
                  // If this was the last invitation, jump to the family page.
                  this.$emit('no-more-invitations')
                  this.$router.push(pathNew)
                } else {
                  // refresh the list
                  this.reset()
                }
              })
              .catch(error => {
                // Ignore the error.  The network error should show in the banner.
              })
          })
          .catch(error => {
            this.$set(this.tableRowErrors, row.item.familyInvitationId, error.postState.errorMessages[0].message)
          })
    },
    reset: function () {
      this.busy = true
      this.axios.get(apiUtils.buildAPIUrl(`familyInvitation/list?showViewed=${this.showViewed}&viewSession=${this.viewSession}`))
          .then(response => {
            this.tableData = response.data   
            this.tableRows = this.tableData.length 
            this.busy = false  
            
            if (this.tableRows === 0) {
              this.$emit('no-more-invitations')
            }
          })
    },
    rowError: function (row) {
      return this.tableRowErrors[row.item.familyInvitationId]
    }
  },
  created() {
    this.reset()
  }
}
</script>