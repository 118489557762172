import _ from 'lodash'
import * as cultureUtils from '@/logic/culture/cultureUtils'
import * as familyFactUtils from '@/logic/family/familyFactUtils'
import * as individualUtils from '@/logic/family/individualUtils'
import * as urlUtils from '@/logic/general/urlUtils'

export function addStructuredDataScript(structuredData) {
  const tag = document.createElement('script')
  tag.setAttribute('type', 'application/ld+json')
  tag.setAttribute('data-vue-router-controlled', '')  // We use this to track which meta tags we create so we don't interfere with other ones.  See the routerExtensions beforeEachGlobal method
  tag.innerHTML = JSON.stringify(structuredData, null, 2)

  document.head.appendChild(tag)
}

export function buildFamilyStructuredData(familyState, toParams) {
  const { author, familyDescription, familyId, familyName, sourceUrl } = familyState
  const url = `${location.origin}${urlUtils.getFamilyURL(familyId, familyName).path}` 
  const dataset = {
    "@context": "https://schema.org",
    "@type": "Collection",
    "name": `${familyName} family tree`,
    "url": url
  }

  if (author) dataset.author = {
    "@type": "Person",
    "name": author
  }

  if (familyDescription) dataset.abstract = familyDescription
  if (sourceUrl) dataset.isBasedOn = sourceUrl

  addStructuredDataScript(dataset)
}

export function buildIndividualStructuredData(state, toParams) {
  const familyState = state.family
  const language = state.viewState.language
  const individualId = toParams.individualId
  const person = buildIndividual(language, familyState, individualId, true)
  const individual = familyState.familyData.individuals[individualId]
  const parentFamily = buildParentChildren(language, familyState, individual.familyParents, individualId)
  if (parentFamily.parents.length > 0) person.parent = parentFamily.parents
  if (parentFamily.children.length > 0) person.sibling = parentFamily.children

  const unionFamilies = buildParentChildren(language, familyState, individual.familyUnions, individualId)
  if (unionFamilies.parents.length > 0) person.spouse = unionFamilies.parents
  if (unionFamilies.children.length > 0) person.children = unionFamilies.children

  addStructuredDataScript(person)
}

export function buildIndividual(language, { familyData, familyId, familyName }, individualId, isTopLevel) {
  const person = {}

  if (isTopLevel) person["@context"] = "https://schema.org"

  const individual = familyData.individuals[individualId]
  const sex = _.get(individual, 'sex')
  const standardName = individualUtils.getName(individual)
  const formalName = individualUtils.getFormalName(individual)
  const url = `${location.origin}${urlUtils.getIndividualURL(familyId, familyName, individualId, standardName).path}` 
  const birthEvent = familyFactUtils.getGroupMemberByCodePrimaryOrFirst(individual, 'events','BIRT')
  const deathEvent = familyFactUtils.getGroupMemberByCodePrimaryOrFirst(individual, 'events','DEAT')
  const birthDate = cultureUtils.formatDate(_.get(birthEvent, 'date.date'), language)
  const deathDate = cultureUtils.formatDate(_.get(deathEvent, 'date.date'), language)
  const birthPlace = joinValues(birthEvent, ['place.value', 'address.address'])
  const deathPlace = joinValues(deathEvent, ['place.value', 'address.address'])
  const titleAttributes = familyFactUtils.getGroupMemberByCode(individual, 'attributes','TITL')
  const titles = titleAttributes.map(item => _.get(item, 'value')).join(', ')
  const occupationAttribute = familyFactUtils.getGroupMemberByCodePrimaryOrFirst(individual, 'attributes','OCCU')
  const occupation = _.get(occupationAttribute, 'value')

  person["@type"] = "Person"
  person.name = standardName
  person.url = url

  if (isTopLevel) {
    person.description = `Family tree of ${standardName}`
    const birthDeathSummary = individualUtils.getBirthDeathSummary(individual, language)

    if (birthDeathSummary) person.description += ` (${birthDeathSummary})`
    if (titles || occupation) person.description += `, ${titles || occupation}.`
  }

  // if (given && surname) {
  //   person.givenName = given
  //   person.familyName = surname
  // } 

  if (standardName != formalName) person.alternateName = formalName
  if (birthDate) person.birthDate = birthDate
  if (birthPlace) person.birthPlace = birthPlace
  if (deathPlace) person.deathPlace = deathPlace
  if (deathDate) person.deathDate = deathDate
  if (titles || occupation) person.jobTitle = titles || occupation

  switch (sex) 
  {
    case 'F':
      person.gender = 'Female'
      break
    case 'M':
      person.gender = 'Male'
      break
  }

  return person
}

export function joinValues(entity, pathList) {
  const r = []

  pathList.forEach(path => {
    const value = _.get(entity, path)
    if (value) r.push(value)
  })

  return r.join(', ')
}

export function buildParentChildren(language, familyState, familyIds = [], individualIdExcluded) {
  const { familyData } = familyState
  const parentChildren = {
    parents: [],
    children: []
  }

  familyIds.forEach(item => {
    const family = familyData.families[item.pointer]
    parentChildren.parents = [...parentChildren.parents, ...buildFamilyIndividuals(language, familyState, family, 'spouses', individualIdExcluded)] 
    parentChildren.children = [...parentChildren.children, ...buildFamilyIndividuals(language, familyState, family, 'children', individualIdExcluded)] 
  })

  return parentChildren
}

export function buildFamilyIndividuals(language, familyState, family, individualIdListProperty, individualIdExcluded) {
  return _.get(family, individualIdListProperty, [])
            .map(item => item.pointer || item)
            .filter(id => id != individualIdExcluded)
            .map(individualId => buildIndividual(language, familyState, individualId))
}