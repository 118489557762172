import xssFilters from "xss-filters"
import * as stringUtils from "@/logic/general/stringUtils"
import { ifExists } from "@/logic/general/objectUtils"
import { handlingTypes } from "@/logic/gedcom/handlingTypes"

const lineFormat =  new Intl.NumberFormat(navigator.language, {notation: "standard", minimumIntegerDigits: 7, useGrouping: false})

export function convert(rawData) {
  const gedcomRows = rawData.replace(/\r\n/g,'\n').split('\n')
  const recordProperties = []
  const scaffold = []
  const referencesMade = {}
  let inErrorInvalidCode = false
  let inErrorInvalidCodeLevel = -1
  let inErrorNotObject = false
  let level0Code = null
  let lastSuccessfulGedcomRow = null
  let lastSuccessfulHandling = null
  let lastSuccessfulLevel = -1
  let lastSuccessfulLineNumber = 0
  let previousCode = null

  scaffold.push(buildBaseObject(recordProperties))
  
  // Old for loop used so we can look ahead 
  for (let n = 0; n < gedcomRows.length; n++) {
    const gedcomRow = gedcomRows[n]
    const lineNumber = n + 1

    try {
      const parts = gedcomRow.split(/\s+/)

      // Ignore lines without at least two parts
      if (parts.length < 2)
        continue

      const level = parseInt(parts[0])
      let code = null
      let id = null
      let value = null
      let isReference = false

      // Special handling when UID's occupy the 2nd position
      if (level === 0 && parts.length > 2 && parts[1].startsWith('@')) {
        id = cleanId(parts[1])
        code = parts[2]
        isReference = true
        value = getTrailingText(gedcomRow, parts[2]) 

      } else {
        code = parts[1]

        if (parts.length > 2 && parts[2].startsWith('@')) {
          isReference = true
          value = cleanId(parts[2])
        } else {
          value = getTrailingText(gedcomRow, parts[1]) 
        }
      }

      // Record references made in order to show invalid references
      recordReferencesMade(referencesMade, value, isReference)

      // Clean the lower levels of the scaffold
      // Remove lower levels as they can sometimes cause hard to trace bugs
      scaffold.fill(null, (level || 0) + 1)

      // The current level 0 item.  Used to filter handling types.
      if (level === 0) {
        level0Code = code
      }

      // Lines that follow the invalid code
      if (inErrorInvalidCode && level > inErrorInvalidCodeLevel) {
        printLine(lineNumber,gedcomRow)
        continue 
      }

      // How should we handle this code?
      const handling = getHandlingType(code, level, level0Code, previousCode)

      // Validation Section
      // No handling setup for this code.  log and leave
      if (!handling) {
        inErrorInvalidCode = true
        inErrorInvalidCodeLevel = level
        console.warn(`GEDCOM invalid. GEDCOM code not recognized`)
        printLine(lineNumber,gedcomRow)
        continue 
      }

      // If we are ignoring this code, then leave as well if the handling allows it.
      if (handling.ignore) {
        inErrorInvalidCode = true
        inErrorInvalidCodeLevel = level
        continue
      }

      // If parent is not an object, we must reject any lines that follow at a higher level.
      if (level > lastSuccessfulLevel && lastSuccessfulHandling && !lastSuccessfulHandling.isObject) {
        if (!inErrorNotObject) {
          inErrorNotObject = true;
          console.warn(`GEDCOM invalid. Sublevels follow a non-object handling type.`)
          printLine(lastSuccessfulLineNumber,lastSuccessfulGedcomRow)
        }

        printLine(lineNumber,gedcomRow)
        continue
      }

      // Clean off the first and last slash in a name
      value = cleanSlashes(value, handling)

      // Remove underscores
      value = removeUnderscores(value, handling)

      // Look to see if the immediately subsequent gedcomRows are 'CONT' gedcomRows and concatenate them to the value.
      value = continueLookahead(value, gedcomRows, n) 

      // Add this to the parent object.  
      if (handling.isObject) {
        setPropertyObject(scaffold, level, code, id, value, handling, isReference)
      } else {
        if (handling.group)
          setPropertyArrayValue(scaffold[level], handling.group, value, handling)
        else 
          setPropertySimpleValue(scaffold[level], handling.type, value, handling)
      }

      inErrorInvalidCode = false
      inErrorInvalidCodeLevel = -1
      inErrorNotObject = false
      lastSuccessfulGedcomRow = gedcomRow
      lastSuccessfulHandling = handling
      lastSuccessfulLevel = level
      lastSuccessfulLineNumber = lineNumber
      previousCode = code

    } catch (error) {
      console.error(`GEDCOM invalid. Unexpected error: ${error.message}`)
      printLine(lineNumber,gedcomRow)
      throw error
    }
  } 

  // Return only the level 0 object.
  const familyData = scaffold[0]
  reportInvalidReferences(familyData, referencesMade, recordProperties)

  return familyData
}

// Build the base object.
// Add a property for each record type.
export function buildBaseObject(recordProperties) {
  const obj = {}

  for (const property in handlingTypes) {
    const handling = handlingTypes[property]

    if (Array.isArray(handling)) {
      handling.forEach(element => {
        if (element.recordProperty) {
          obj[element.recordProperty] = {}
          recordProperties.push(element.recordProperty)
        }
      })
    } else {
      if (handling.recordProperty) {
        obj[handling.recordProperty] = {}
        recordProperties.push(handling.recordProperty)
      }
    }
  }

  return obj
}

// Clean Slashes
// If this is a name and there are more than 1 '/'character, remove the first and the last. 
// A number of GEDCOM exports bracket the last name with slashes as thus 'John /Smith/' The problem here is that a person could enter a slash in a person's name like this: 'John /Smith/Jones/
export function cleanSlashes(value, handling) {
  if (handling.cleanSlashes) {
    const totalSlashes = (value.match(/\//g)||[]).length

    if (totalSlashes > 1) {
      let raw = value.replace(/\s*\//,' ')  // Remove the first slash
      raw = stringUtils.removeChar(raw, raw.lastIndexOf('/')) // Remove last slash
      return raw.trim()
    }

    return value
  } else {
    return value
  }
}

export function removeUnderscores(value, handling) {
  if (handling.removeUnderscores) {
    return value.replace('_', ' ')
  } else {
    return value
  }
}

// Clean Value
// Strip off '@' characters at the beginning or end for everything but 'FILE'
export function cleanId(id) {
  return id.replace(/^@|@$/g,'')
}

// Search subsequent gedcomRows for the "CONT" or "CONC" and concatenate the result to the value
export function continueLookahead(value, gedcomRows, currentPosition) {
  for (let n = currentPosition + 1; n < gedcomRows.length; n++) {
    const gedcomRow = gedcomRows[n]
    const parts = gedcomRow.split(/\s+/)

    // Ignore lines without at least two parts
    if (parts.length < 2 || (parts[1] !== 'CONT' && parts[1] !== 'CONC')) {
      break
    } else {
      if (parts[1] === 'CONC') {
        value += getTrailingText(gedcomRow, parts[1])
      }

      if (parts[1] === 'CONT') {
        value += '\n' + getTrailingText(gedcomRow, parts[1])
      }
    }
  }

  return value
}

export function getTrailingText(gedcomRow, lastKeyPart) {
  const controlStart = gedcomRow.indexOf(lastKeyPart)
  const textStart = controlStart + lastKeyPart.length + 1
  const text = gedcomRow.substring(textStart) || ''
  return text.replace(/@@/g,'@')
}

// Some values need special formatting
export function formatValue(value, handling) {
  if (typeof value === 'object')
    return value
  if (handling.valueFormatter) {
    return handling.valueFormatter(value, handling)
  } else {
    return xssFilters.inDoubleQuotedAttr(value)
  }
}

// Clean Value
// Strip off '@' characters at the beginning or end for everything but 'FILE'
// If this is a name and there are more than 1 '/'character, remove the first and the last. 
// A number of GEDCOM exports bracket the last name with slashes as thus 'John /Smith/' The problem here is that a person could enter a slash in a person's name like this: 'John /Smith/Jones/
export function getHandlingType(code, level, level0Code, previousCode) {
  const handling = handlingTypes[code]

  if (Array.isArray(handling)) {
    // Get the first item that matches. If the property exists, check for match, otherwise, assume match.
    const items = handling.filter(item => {
      const matchLevel = ifExists(item.filterLevel) ? item.filterLevel === level : true  
      const matchLevel0Code = ifExists(item.filterLevel0Code) ? item.filterLevel0Code === level0Code : true 
      const matchPreviousCode = ifExists(item.filterPreviousCode) ? item.filterPreviousCode === previousCode : true 
      return matchLevel && matchLevel0Code && matchPreviousCode
    })

    return items ? items[0] : null
  } else {
    return handling
  }
}

export function recordReferencesMade(referencesMade, value, isReference) {
  if (isReference && value) {
    referencesMade[value] = true
  }
}

export function reportInvalidReferences(familyData, referencesMade, recordProperties) {
  const invalidReferences = []

  Object.keys(referencesMade).forEach(reference => {
    const found = recordProperties.some(recordProperty => {
      return familyData[recordProperty][reference]
    })

    if (!found) invalidReferences.push(reference)
  });

  if (invalidReferences.length > 0) {
    const list = invalidReferences.sort().join(', ')
    console.log(`Invalid References: ${list}`)
  }
}

export function setPropertyObject(scaffold, level, code, id, value, handling, isReference){
  const obj = {}
  scaffold[level + 1] = obj

  if (handling.recordProperty && level === 0) {
    obj['id'] = id
    scaffold[0][handling.recordProperty][id] = obj
  } else if (handling.group) {
    setPropertyArray(scaffold[level], handling.group, obj)
    if (handling.includeCode) {
      obj.code = code
    }

  } else {
    setPropertySimpleValue(scaffold[level], handling.type, obj, handling)
  }

  // Some objects also have a value on the same line
  if (handling.basePropertyReference && isReference) {
    setPropertySimpleValue(obj, handling.basePropertyReference, value, handling)
  } else if (handling.basePropertyValue) {
    setPropertySimpleValue(obj, handling.basePropertyValue, value, handling)
  }
}

export function setPropertyArrayValue(obj, property, value, handling) {
  if (value) {
    setPropertyArray(obj, property, formatValue(value, handling))
  }
}

export function setPropertySimpleValue(obj, property, value, handling) {
  if (value)
    obj[property] = formatValue(value, handling)
}

export function setPropertyArray(obj, property, item) {
  if (!obj[property])
    obj[property] = []

  obj[property].push(item)
}

export function printLine(lineNumber, gedcomRow) {
  console.log(`   ${lineFormat.format(lineNumber)}  ${gedcomRow}`)
}
