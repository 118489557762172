// Creates a new object with properties who pass the predicate test
// Thanks https://stackoverflow.com/a/5072145/1864995
export function filter(obj, predicate) {
  const result = {}

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && predicate(obj[key])) {
            result[key] = obj[key]
        }
    }

    return result
}

export function ifExists(value) {
  if (value === undefined || value === null) 
    return false
  else
    return true
}