<template>
  <div :class="{ 'text-right': pushRight, 'pl-2': true }">
    <span v-if="postState.inProgress"><font-awesome-icon :icon="['fas', 'spinner']" class="" spin /> Processing ...</span>
    <b-form-invalid-feedback :state="state">
      <span v-if="hasSingleError">
        {{ singleError }} 
      </span>
      <ul class="text-left" v-if="hasMultipleErrors">
        <li v-for="(errorMessage,index) in postState.errorMessages" :key="index">{{ errorMessage.message }}</li>
      </ul>
    </b-form-invalid-feedback>
    <b-form-valid-feedback :state="isCompleteOk">
      {{ successMessage }}
    </b-form-valid-feedback>
  </div>
</template>

<script>
  import * as apiUtils from '@/logic/general/apiUtils'

  export default {
    name: 'ApiPostFeedback',
    props: {
      postState: {type: Object, required: true},
      pushRight: {type: Boolean, default: true},
      successMessage: {type: String }
    },
    computed: {
      hasMultipleErrors: function () {
        return this.postState.errorMessages && this.postState.errorMessages.length > 1 ? true : false
      },
      hasSingleError: function () {
        return this.postState.errorMessages && this.postState.errorMessages.length === 1 ? true : false
      },
      isCompleteOk: function () {
        return this.postState.errorType == apiUtils.APIErrorTypes.OK && this.postState.inProgress == false
      },
      singleError: function () {
        const errorMessages = this.postState.errorMessages
        return errorMessages && errorMessages.length > 0 ? errorMessages[0].message : ""
      },
      state: function () {
        const isPostError = apiUtils.isPostError(this.postState)
        return !isPostError
      }
    }
  }
</script>