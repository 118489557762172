<template>
  <div v-if="hasNetworkError" class="banner-error">
    Network Error <b-icon icon="info-square" id="networkErrorInfo" @click="$bvToast.show('toastNetworkError')" class="hover-pointer" />

    <b-popover target="networkErrorInfo"
               custom-class="popover-wide"
               placement="bottom"
               title="Network Error"
               triggers="click blur">
      Communication with the server has been interupted.  This could be a problem with your device, the server, or the network inbetween.  Try your request later.  This may resolve itself automatically.
    </b-popover>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'BannerNetworkError',
    computed: {
      ...mapState('viewState', [
        'hasNetworkError'
      ])
    }
  }
</script>

<style scoped>
  .banner-error {
    color: white;
    background: red;
    padding-left: 16px;
    width: 100%;
  }
</style>