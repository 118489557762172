<template>
  <div class="narrow-container-2 px-2 pt-2">
    <div class="d-flex flex-wrap justify-content-between">
      <div>
        <h4>Switch Family</h4>
      </div>
      <div>
        <b-form-group
          label-cols-sm="4"
          label="Name Filter:"
          label-align-sm="right"
          label-for="filter-box">
          <b-form-input id="filter-box" 
                        type="text" 
                        v-model="filter" />
        </b-form-group>
      </div>
    </div>
    <b-tabs card pills>
      <b-tab v-if="privateFamilies.length > 0" title="Available Private Families">
        <family-selection-table :busy="busy"
                                :families="privateFamilies"
                                :filter="filter"
                                :per-page="90"
                                table-id="AvailablePrivateFamilies" />
      </b-tab>
      <b-tab v-if="publicFamilies.length > 0" title="Available Public Families">
        <family-selection-table :busy="busy"
                                :families="publicFamilies"
                                :filter="filter"
                                :per-page="90"
                                table-id="AvailablePublicFamilies" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as apiUtils from '@/logic/general/apiUtils'
import FamilySelectionTable from '@/components/family/FamilySelectionTable.vue'

export default {
  components: {
    FamilySelectionTable
  },
  data() {
    return {
      busy: false,
      filter: '',
      privateFamilies: [],
      publicFamilies: [],
    }
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn'
    ])
  },
  methods: {
    reset: function () {
      this.busy = true
      this.axios.get(apiUtils.buildAPIUrl('family/list/available'))
          .then(response => {
            this.privateFamilies = response.data.privateFamilies || []
            this.publicFamilies = response.data.publicFamilies || []   
            this.busy = false  
          })
          .catch(error => {
            // Ignore the error.  The network banner will show
          })
    }
  },
  created() {
    this.reset()
  },
  watch: {
    isLoggedIn: function (newValue) {
      this.reset()
    }
  }
}
</script>
