<template>
  <fragment>
    <h5>Security Options</h5>

    <b-row>
      <b-col offset-md="1">
        <b-form-group>
          <b-form-checkbox :checked="value" @input="$emit('input', $event)">
            Stay Logged In? <b-icon icon="info-square"
                                    id="stayLoggedInInfo"
                                    class="hover-pointer"
                                    @click.prevent />
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-popover target="stayLoggedInInfo"
               custom-class="popover-wide"
               placement="rightbottom"
               title="Stay Logged In?"
               triggers="click blur">
      If you are using a public computer in places like a library or internet cafe you should leave this unselected.  This should only be selected if you are using your own secure computer or mobile device.
    </b-popover>
  </fragment>
</template>

<script>
  export default {
    name: 'LoginOptions',
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      }
    }
  }
</script>