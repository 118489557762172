<template>
  <div>
    <individual-name :individual-id="individualId"
                     :citation-number="citationNumber"
                     :hide-detail-button="hideDetailButton"
                     :show-buttons="true"
                     :union-id="unionId"
                     :use-formal-name="true" />
    <individual-summary :individual-id="individualId" />
  </div> 
</template>

<script>
import IndividualName from "@/components/tree/IndividualName"
import IndividualSummary from "@/components/tree/IndividualSummary"

  export default {
    name: 'InfoIndividual',
    components: {
      IndividualName,
      IndividualSummary
    },
    props: {
      citationNumber: {type: Number},
      hideDetailButton: {type: Boolean, default: false},
      individualId: {type: String, required: true},
      unionId:  {type: String},
    }
  }
</script>