<template>
  <div class="narrow-container-2 px-2 pt-2">
    <h4>Reimport All Families</h4>
    <p>All families will be reimported using the original gedcom data stored on the backend.  This operation should only be used when the translation logic that processes the gedcom data and turns it into JSON has been updated. This does not include the translation of the original gedcom file from various character sets such as ANSI, ANSEL, etc to UTF-8.</p>
    <div class="d-flex justify-content-end">
      <b-button v-if="postState.inProgress"
                :disabled="this.cancelPending"
                @click="onStop()"
                type="button"
                variant="outline-secondary"
                tabindex="12">
        {{ stopText }}
      </b-button>
      <b-button :disabled="postState.inProgress"
                @click="onSubmit()"
                type="submit"
                variant="primary"
                tabindex="11"
                class="ml-2">
        Submit
      </b-button>
    </div>
    <ApiPostFeedback :post-state="postState" />
    <b-progress v-if="postState.inProgress" :value="progressValue" :max="progressMax" animated />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiPostFeedback from "@/components/ApiPostFeedback"
import { checkRouteSiteAdministrator } from '@/logic/general/authenticationUtils'
import { APIErrorTypes, buildAPIUrl, resetPostState } from '@/logic/general/apiUtils'
import { processFamilyData } from '@/logic/family/familyAdminUtils'

export default {
  components: {
    ApiPostFeedback
  },
  data() {
    return {
      cancelPending: false,
      families: [],
      postState: {},
      progressMax: 100,
      progressValue: 0
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => checkRouteSiteAdministrator(vm, next))
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn',
      'isSiteAdministrator'
    ]),
    stopText: function () {
      return this.cancelPending ? 'Stopping ...' : 'Stop'
    }
  },
  methods: {
    onStop: function () {
      this.cancelPending = true
    },
    onSubmit: async function () {
      this.cancelPending = false
      this.progressValue = 0
      this.postState = resetPostState()
      
      await this.axios.get(buildAPIUrl('siteAdministration/listFamilyAll'))
          .then(async response => {
            this.families = response.data
            this.progressMax = this.families.length * 4  // Each family will get four bumps on the progress bar

            // Process every family
            family_loop: for await (const family of this.families) {
              this.progressValue++

              // Allow the long running operation to be cancelled
              if (this.cancelPending) {
                this.postState = {
                  errorType: APIErrorTypes.USER,
                  errorMessages: [{message: `Operation Cancelled.`}]
                }
                break
              }

              // Load the gedcom
              await this.axios.get(buildAPIUrl(`siteAdministration/familyGedcom/${family.familyId}`))
                .then(async response => {
                  this.progressValue++
                  const cleanData = response.data.gedcomData
                  const payload = await processFamilyData(cleanData, family.familyId, family.familyNameBase)
                  this.progressValue++

                  await this.axios.post(buildAPIUrl(`familyAdmin/upload/${family.familyId}`), payload)
                    .then(response => {
                      this.progressValue++
                    })
                    .catch(error => {
                      this.postState = error.postState
                    })
                }).catch(error => {
                  this.postState = {
                    errorType: APIErrorTypes.USER,
                    errorMessages: [{message: `Error while processing GED file on line ${error.row}: ${error.message}`}]
                  }
                })

              // If an error occurred while processing the last family, stop processing other families
              if (this.postState.errorType != "Ok") {
                break family_loop
              }
            }

            this.postState.inProgress = false
      }).catch(error => {
        this.postState = error.postState
      })
    }
  }
}
</script>