// Source and with thanks: https://github.com/infused/ansel/blob/master/lib/ansel/character_map.rb
// https://en.wikipedia.org/wiki/ANSEL
// https://www.compart.com/en/unicode/block/U+0300 

export const anselToUtf8Map = {
  'ERR':'\uFFFD',      // � - REPLACEMENT CHARACTER
  '88':'',               // NON-SORT BEGIN / START OF STRING
  '89':'',               // NON-SORT END / STRING TERMINATOR
  '8D':'',               // JOINER / ZERO WIDTH JOINER
  '8E':'',               // NON-JOINER / ZERO WIDTH NON-JOINER
  'A1':'\u0141',       // Ł - UPPERCASE POLISH L / LATIN CAPITAL LETTER L WITH STROKE
  'A2':'\u00D8',       // Ø - UPPERCASE SCANDINAVIAN O / LATIN CAPITAL LETTER O WITH STROKE
  'A3':'\u0110',       // Đ - UPPERCASE D WITH CROSSBAR / LATIN CAPITAL LETTER D WITH STROKE
  'A4':'\u00DE',       // Þ - UPPERCASE ICELANDIC THORN / LATIN CAPITAL LETTER THORN (Icelandic)
  'A5':'\u00C6',       // Æ - UPPERCASE DIGRAPH AE / LATIN CAPITAL LIGATURE AE
  'A6':'\u0152',       // Œ - UPPERCASE DIGRAPH OE / LATIN CAPITAL LIGATURE OE
  'A7':'\u02B9',       // ʹ - SOFT SIGN, PRIME / MODIFIER LETTER PRIME
  'A8':'\u00B7',       // · - MIDDLE DOT
  'A9':'\u266D',       // ♭ - MUSIC FLAT SIGN
  'AA':'\u00AE',       // ® - PATENT MARK / REGISTERED SIGN
  'AB':'\u00B1',       // ± - PLUS OR MINUS / PLUS-MINUS SIGN
  'AC':'\u01A0',       // Ơ - UPPERCASE O-HOOK / LATIN CAPITAL LETTER O WITH HORN
  'AD':'\u01AF',       // Ư - UPPERCASE U-HOOK / LATIN CAPITAL LETTER U WITH HORN
  'AE':'\u02BC',       // ʼ - ALIF / MODIFIER LETTER APOSTROPHE
  'B0':'\u02BB',       // ʻ - AYN / MODIFIER LETTER TURNED COMMA
  'B1':'\u0142',       // ł - LOWERCASE POLISH L / LATIN SMALL LETTER L WITH STROKE
  'B2':'\u00F8',       // ø - LOWERCASE SCANDINAVIAN O / LATIN SMALL LETTER O WITH STROKE
  'B3':'\u0111',       // đ - LOWERCASE D WITH CROSSBAR / LATIN SMALL LETTER D WITH STROKE
  'B4':'\u00FE',       // þ - LOWERCASE ICELANDIC THORN / LATIN SMALL LETTER THORN (Icelandic)
  'B5':'\u00E6',       // æ - LOWERCASE DIGRAPH AE / LATIN SMALL LIGATURE AE
  'B6':'\u0153',       // œ - LOWERCASE DIGRAPH OE / LATIN SMALL LIGATURE OE
  'B7':'\u02BA',       // ʺ - HARD SIGN, DOUBLE PRIME / MODIFIER LETTER DOUBLE PRIME
  'B8':'\u0131',       // ı - LOWERCASE TURKISH I / LATIN SMALL LETTER DOTLESS I
  'B9':'\u00A3',       // £ - BRITISH POUND / POUND SIGN
  'BA':'\u00F0',       // ð - LOWERCASE ETH / LATIN SMALL LETTER ETH (Icelandic)
  'BC':'\u01A1',       // ơ - LOWERCASE O-HOOK / LATIN SMALL LETTER O WITH HORN
  'BD':'\u01B0',       // ư - LOWERCASE U-HOOK / LATIN SMALL LETTER U WITH HORN
  'BE':'\u25A1',       // □ - EMPTY BOX
  'BF':'\u25A0',       // ■ - BLACK BOX
  'C0':'\u00B0',       // ° - DEGREE SIGN
  'C1':'\u2113',       // ℓ - SCRIPT SMALL L
  'C2':'\u2117',       // ℗ - SOUND RECORDING COPYRIGHT
  'C3':'\u00A9',       // © - COPYRIGHT SIGN
  'C4':'\u266F',       // ♯ - MUSIC SHARP SIGN
  'C5':'\u00BF',       // ¿ - INVERTED QUESTION MARK
  'C6':'\u00A1',       // ¡ - INVERTED EXCLAMATION MARK
  'C7':'\u00DF',       // ß - ESZETT SYMBOL
  'C8':'\u20AC',       // € - EURO SIGN
  'CD':'\u0065',       // e - MIDLINE E
  'CE':'\u006F',       // o - MIDLINE O
  'CF':'\u00DF',       // ß - EZ ZET

  'E0+41':'\u1EA2',    // Ả - LATIN CAPITAL LETTER A WITH HOOK ABOVE
  'E0+45':'\u1EBA',    // LATIN CAPITAL LETTER E WITH HOOK ABOVE
  'E0+49':'\u1EC8',    // LATIN CAPITAL LETTER I WITH HOOK ABOVE
  'E0+4F':'\u1ECE',    // LATIN CAPITAL LETTER O WITH HOOK ABOVE
  'E0+55':'\u1EE6',    // LATIN CAPITAL LETTER U WITH HOOK ABOVE
  'E0+59':'\u1EF6',    // LATIN CAPITAL LETTER Y WITH HOOK ABOVE
  'E0+61':'\u1EA3',    // LATIN SMALL LETTER A WITH HOOK ABOVE
  'E0+65':'\u1EBB',    // LATIN SMALL LETTER E WITH HOOK ABOVE
  'E0+69':'\u1EC9',    // LATIN SMALL LETTER I WITH HOOK ABOVE
  'E0+6F':'\u1ECF',    // LATIN SMALL LETTER O WITH HOOK ABOVE
  'E0+75':'\u1EE7',    // LATIN SMALL LETTER U WITH HOOK ABOVE
  'E0+79':'\u1EF7',    // LATIN SMALL LETTER Y WITH HOOK ABOVE
  'E0+E3+41':'\u1EA8', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND HOOK ABOVE
  'E0+E3+45':'\u1EC2', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND HOOK ABOVE
  'E0+E3+4F':'\u1ED4', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND HOOK ABOVE
  'E0+E3+61':'\u1EA9', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND HOOK ABOVE
  'E0+E3+65':'\u1EC3', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND HOOK ABOVE
  'E0+E3+6F':'\u1ED5', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND HOOK ABOVE
  'E0+E6+41':'\u1EB2', // LATIN CAPITAL LETTER A WITH BREVE AND HOOK ABOVE
  'E0+E6+61':'\u1EB3', // LATIN SMALL LETTER A WITH BREVE AND HOOK ABOVE
  'E0':'',             // COMBINING HOOK ABOVE       \u0309

  'E1+41':'\u00C0',    // LATIN CAPITAL LETTER A WITH GRAVE
  'E1+45':'\u00C8',    // LATIN CAPITAL LETTER E WITH GRAVE
  'E1+49':'\u00CC',    // LATIN CAPITAL LETTER I WITH GRAVE
  'E1+4F':'\u00D2',    // LATIN CAPITAL LETTER O WITH GRAVE
  'E1+55':'\u00D9',    // LATIN CAPITAL LETTER U WITH GRAVE
  'E1+57':'\u1E80',    // LATIN CAPITAL LETTER W WITH GRAVE
  'E1+59':'\u1EF2',    // LATIN CAPITAL LETTER Y WITH GRAVE
  'E1+61':'\u00E0',    // LATIN SMALL LETTER A WITH GRAVE
  'E1+65':'\u00E8',    // LATIN SMALL LETTER E WITH GRAVE
  'E1+69':'\u00EC',    // LATIN SMALL LETTER I WITH GRAVE
  'E1+6F':'\u00F2',    // LATIN SMALL LETTER O WITH GRAVE
  'E1+75':'\u00F9',    // LATIN SMALL LETTER U WITH GRAVE
  'E1+77':'\u1E81',    // LATIN SMALL LETTER W WITH GRAVE
  'E1+79':'\u1EF3',    // LATIN SMALL LETTER Y WITH GRAVE
  'E1+E3+41':'\u1EA6', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND GRAVE
  'E1+E3+45':'\u1EC0', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND GRAVE
  'E1+E3+4F':'\u1ED2', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND GRAVE
  'E1+E3+61':'\u1EA7', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND GRAVE
  'E1+E3+65':'\u1EC1', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND GRAVE
  'E1+E3+6F':'\u1ED3', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND GRAVE
  'E1+E5+45':'\u1E14', // LATIN CAPITAL LETTER E WITH MACRON AND GRAVE
  'E1+E5+4F':'\u1E50', // LATIN CAPITAL LETTER O WITH MACRON AND GRAVE
  'E1+E5+65':'\u1E15', // LATIN SMALL LETTER E WITH MACRON AND GRAVE
  'E1+E5+6F':'\u1E51', // LATIN SMALL LETTER O WITH MACRON AND GRAVE
  'E1+E6+41':'\u1EB0', // LATIN CAPITAL LETTER A WITH BREVE AND GRAVE
  'E1+E6+61':'\u1EB1', // LATIN SMALL LETTER A WITH BREVE AND GRAVE
  'E1+E8+55':'\u01DB', // LATIN CAPITAL LETTER U WITH DIAERESIS AND GRAVE
  'E1+E8+75':'\u01DC', // LATIN SMALL LETTER U WITH DIAERESIS AND GRAVE
  'E1':'',             // COMBINING GRAVE ACCENT      \u0300

  'E2+41':'\u00C1',    // LATIN CAPITAL LETTER A WITH ACUTE
  'E2+43':'\u0106',    // LATIN CAPITAL LETTER C WITH ACUTE
  'E2+45':'\u00C9',    // LATIN CAPITAL LETTER E WITH ACUTE
  'E2+47':'\u01F4',    // LATIN CAPITAL LETTER G WITH ACUTE
  'E2+49':'\u00CD',    // LATIN CAPITAL LETTER I WITH ACUTE
  'E2+4B':'\u1E30',    // LATIN CAPITAL LETTER K WITH ACUTE
  'E2+4C':'\u0139',    // LATIN CAPITAL LETTER L WITH ACUTE
  'E2+4D':'\u1E3E',    // LATIN CAPITAL LETTER M WITH ACUTE
  'E2+4E':'\u0143',    // LATIN CAPITAL LETTER N WITH ACUTE
  'E2+4F':'\u00D3',    // LATIN CAPITAL LETTER O WITH ACUTE
  'E2+50':'\u1E54',    // LATIN CAPITAL LETTER P WITH ACUTE
  'E2+52':'\u0154',    // LATIN CAPITAL LETTER R WITH ACUTE
  'E2+53':'\u015A',    // LATIN CAPITAL LETTER S WITH ACUTE
  'E2+55':'\u00DA',    // LATIN CAPITAL LETTER U WITH ACUTE
  'E2+57':'\u1E82',    // LATIN CAPITAL LETTER W WITH ACUTE
  'E2+59':'\u00DD',    // LATIN CAPITAL LETTER Y WITH ACUTE
  'E2+5A':'\u0179',    // LATIN CAPITAL LETTER Z WITH ACUTE
  'E2+61':'\u00E1',    // LATIN SMALL LETTER A WITH ACUTE
  'E2+63':'\u0107',    // LATIN SMALL LETTER C WITH ACUTE
  'E2+65':'\u00E9',    // LATIN SMALL LETTER E WITH ACUTE
  'E2+67':'\u01F5',    // LATIN SMALL LETTER G WITH ACUTE
  'E2+69':'\u00ED',    // LATIN SMALL LETTER I WITH ACUTE
  'E2+6B':'\u1E31',    // LATIN SMALL LETTER K WITH ACUTE
  'E2+6C':'\u013A',    // LATIN SMALL LETTER L WITH ACUTE
  'E2+6D':'\u1E3F',    // LATIN SMALL LETTER M WITH ACUTE
  'E2+6E':'\u0144',    // LATIN SMALL LETTER N WITH ACUTE
  'E2+6F':'\u00F3',    // LATIN SMALL LETTER O WITH ACUTE
  'E2+70':'\u1E55',    // LATIN SMALL LETTER P WITH ACUTE
  'E2+72':'\u0155',    // LATIN SMALL LETTER R WITH ACUTE
  'E2+73':'\u015B',    // LATIN SMALL LETTER S WITH ACUTE
  'E2+75':'\u00FA',    // LATIN SMALL LETTER U WITH ACUTE
  'E2+77':'\u1E83',    // LATIN SMALL LETTER W WITH ACUTE
  'E2+79':'\u00FD',    // LATIN SMALL LETTER Y WITH ACUTE
  'E2+7A':'\u017A',    // LATIN SMALL LETTER Z WITH ACUTE
  'E2+A5':'\u01FC',    // LATIN CAPITAL LETTER AE WITH ACUTE
  'E2+B5':'\u01FD',    // LATIN SMALL LETTER AE WITH ACUTE
  'E2+E3+41':'\u1EA4', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND ACUTE
  'E2+E3+45':'\u1EBE', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND ACUTE
  'E2+E3+4F':'\u1ED0', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND ACUTE
  'E2+E3+61':'\u1EA5', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND ACUTE
  'E2+E3+65':'\u1EBF', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND ACUTE
  'E2+E3+6F':'\u1ED1', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND ACUTE
  'E2+E4+4F':'\u1E4C', // LATIN CAPITAL LETTER O WITH TILDE AND ACUTE
  'E2+E4+55':'\u1E78', // LATIN CAPITAL LETTER U WITH TILDE AND ACUTE
  'E2+E4+6F':'\u1E4D', // LATIN SMALL LETTER O WITH TILDE AND ACUTE
  'E2+E4+75':'\u1E79', // LATIN SMALL LETTER U WITH TILDE AND ACUTE
  'E2+E5+45':'\u1E16', // LATIN CAPITAL LETTER E WITH MACRON AND ACUTE
  'E2+E5+4F':'\u1E52', // LATIN CAPITAL LETTER O WITH MACRON AND ACUTE
  'E2+E5+65':'\u1E17', // LATIN SMALL LETTER E WITH MACRON AND ACUTE
  'E2+E5+6F':'\u1E53', // LATIN SMALL LETTER O WITH MACRON AND ACUTE
  'E2+E6+41':'\u1EAE', // LATIN CAPITAL LETTER A WITH BREVE AND ACUTE
  'E2+E6+61':'\u1EAF', // LATIN SMALL LETTER A WITH BREVE AND ACUTE
  'E2+E7+53':'\u1E64', // LATIN CAPITAL LETTER S WITH ACUTE AND DOT ABOVE
  'E2+E7+73':'\u1E65', // LATIN SMALL LETTER S WITH ACUTE AND DOT ABOVE
  'E2+E8+49':'\u1E2E', // LATIN CAPITAL LETTER I WITH DIAERESIS AND ACUTE
  'E2+E8+55':'\u01D7', // LATIN CAPITAL LETTER U WITH DIAERESIS AND ACUTE
  'E2+E8+69':'\u1E2F', // LATIN SMALL LETTER I WITH DIAERESIS AND ACUTE
  'E2+E8+75':'\u01D8', // LATIN SMALL LETTER U WITH DIAERESIS AND ACUTE
  'E2+EA+41':'\u01FA', // LATIN CAPITAL LETTER A WITH RING ABOVE AND ACUTE
  'E2+EA+61':'\u01FB', // LATIN SMALL LETTER A WITH RING ABOVE AND ACUTE
  'E2+F0+43':'\u1E08', // LATIN CAPITAL LETTER C WITH CEDILLA AND ACUTE
  'E2+F0+63':'\u1E09', // LATIN SMALL LETTER C WITH CEDILLA AND ACUTE
  'E2':'',             // COMBINING ACUTE ACCENT    \u0301

  'E3+41':'\u00C2',    // LATIN CAPITAL LETTER A WITH CIRCUMFLEX
  'E3+43':'\u0108',    // LATIN CAPITAL LETTER C WITH CIRCUMFLEX
  'E3+45':'\u00CA',    // LATIN CAPITAL LETTER E WITH CIRCUMFLEX
  'E3+47':'\u011C',    // LATIN CAPITAL LETTER G WITH CIRCUMFLEX
  'E3+48':'\u0124',    // LATIN CAPITAL LETTER H WITH CIRCUMFLEX
  'E3+49':'\u00CE',    // LATIN CAPITAL LETTER I WITH CIRCUMFLEX
  'E3+4A':'\u0134',    // LATIN CAPITAL LETTER J WITH CIRCUMFLEX
  'E3+4F':'\u00D4',    // LATIN CAPITAL LETTER O WITH CIRCUMFLEX
  'E3+53':'\u015C',    // LATIN CAPITAL LETTER S WITH CIRCUMFLEX
  'E3+55':'\u00DB',    // LATIN CAPITAL LETTER U WITH CIRCUMFLEX
  'E3+57':'\u0174',    // LATIN CAPITAL LETTER W WITH CIRCUMFLEX
  'E3+59':'\u0176',    // LATIN CAPITAL LETTER Y WITH CIRCUMFLEX
  'E3+5A':'\u1E90',    // LATIN CAPITAL LETTER Z WITH CIRCUMFLEX
  'E3+61':'\u00E2',    // LATIN SMALL LETTER A WITH CIRCUMFLEX
  'E3+63':'\u0109',    // LATIN SMALL LETTER C WITH CIRCUMFLEX
  'E3+65':'\u00EA',    // LATIN SMALL LETTER E WITH CIRCUMFLEX
  'E3+67':'\u011D',    // LATIN SMALL LETTER G WITH CIRCUMFLEX
  'E3+68':'\u0125',    // LATIN SMALL LETTER H WITH CIRCUMFLEX
  'E3+69':'\u00EE',    // LATIN SMALL LETTER I WITH CIRCUMFLEX
  'E3+6A':'\u0135',    // LATIN SMALL LETTER J WITH CIRCUMFLEX
  'E3+6F':'\u00F4',    // LATIN SMALL LETTER O WITH CIRCUMFLEX
  'E3+73':'\u015D',    // LATIN SMALL LETTER S WITH CIRCUMFLEX
  'E3+75':'\u00FB',    // LATIN SMALL LETTER U WITH CIRCUMFLEX
  'E3+77':'\u0175',    // LATIN SMALL LETTER W WITH CIRCUMFLEX
  'E3+79':'\u0177',    // LATIN SMALL LETTER Y WITH CIRCUMFLEX
  'E3+7A':'\u1E91',    // LATIN SMALL LETTER Z WITH CIRCUMFLEX
  'E3+E0+41':'\u1EA8', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND HOOK ABOVE
  'E3+E0+45':'\u1EC2', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND HOOK ABOVE
  'E3+E0+4F':'\u1ED4', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND HOOK ABOVE
  'E3+E0+61':'\u1EA9', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND HOOK ABOVE
  'E3+E0+65':'\u1EC3', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND HOOK ABOVE
  'E3+E0+6F':'\u1ED5', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND HOOK ABOVE
  'E3+E1+41':'\u1EA6', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND GRAVE
  'E3+E1+45':'\u1EC0', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND GRAVE
  'E3+E1+4F':'\u1ED2', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND GRAVE
  'E3+E1+61':'\u1EA7', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND GRAVE
  'E3+E1+65':'\u1EC1', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND GRAVE
  'E3+E1+6F':'\u1ED3', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND GRAVE
  'E3+E2+41':'\u1EA4', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND ACUTE
  'E3+E2+45':'\u1EBE', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND ACUTE
  'E3+E2+4F':'\u1ED0', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND ACUTE
  'E3+E2+61':'\u1EA5', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND ACUTE
  'E3+E2+65':'\u1EBF', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND ACUTE
  'E3+E2+6F':'\u1ED1', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND ACUTE
  'E3+E4+41':'\u1EAA', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND TILDE
  'E3+E4+45':'\u1EC4', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND TILDE
  'E3+E4+4F':'\u1ED6', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND TILDE
  'E3+E4+61':'\u1EAB', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND TILDE
  'E3+E4+65':'\u1EC5', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND TILDE
  'E3+E4+6F':'\u1ED7', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND TILDE
  'E3+F2+41':'\u1EAC', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND DOT BELOW
  'E3+F2+45':'\u1EC6', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND DOT BELOW
  'E3+F2+4F':'\u1ED8', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND DOT BELOW
  'E3+F2+61':'\u1EAD', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND DOT BELOW
  'E3+F2+65':'\u1EC7', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND DOT BELOW
  'E3+F2+6F':'\u1ED9', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND DOT BELOW
  'E3':'',             // COMBINING CIRCUMFLEX ACCENT     \u0302

  'E4+41':'\u00C3',    // LATIN CAPITAL LETTER A WITH TILDE
  'E4+45':'\u1EBC',    // LATIN CAPITAL LETTER E WITH TILDE
  'E4+49':'\u0128',    // LATIN CAPITAL LETTER I WITH TILDE
  'E4+4E':'\u00D1',    // LATIN CAPITAL LETTER N WITH TILDE
  'E4+4F':'\u00D5',    // LATIN CAPITAL LETTER O WITH TILDE
  'E4+55':'\u0168',    // LATIN CAPITAL LETTER U WITH TILDE
  'E4+56':'\u1E7C',    // LATIN CAPITAL LETTER V WITH TILDE
  'E4+59':'\u1EF8',    // LATIN CAPITAL LETTER Y WITH TILDE
  'E4+61':'\u00E3',    // LATIN SMALL LETTER A WITH TILDE
  'E4+65':'\u1EBD',    // LATIN SMALL LETTER E WITH TILDE
  'E4+69':'\u0129',    // LATIN SMALL LETTER I WITH TILDE
  'E4+6E':'\u00F1',    // LATIN SMALL LETTER N WITH TILDE
  'E4+6F':'\u00F5',    // LATIN SMALL LETTER O WITH TILDE
  'E4+75':'\u0169',    // LATIN SMALL LETTER U WITH TILDE
  'E4+76':'\u1E7D',    // LATIN SMALL LETTER V WITH TILDE
  'E4+79':'\u1EF9',    // LATIN SMALL LETTER Y WITH TILDE
  'E4+E2+4F':'\u1E4C', // LATIN CAPITAL LETTER O WITH TILDE AND ACUTE
  'E4+E2+55':'\u1E78', // LATIN CAPITAL LETTER U WITH TILDE AND ACUTE
  'E4+E2+6F':'\u1E4D', // LATIN SMALL LETTER O WITH TILDE AND ACUTE
  'E4+E2+75':'\u1E79', // LATIN SMALL LETTER U WITH TILDE AND ACUTE
  'E4+E3+41':'\u1EAA', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND TILDE
  'E4+E3+45':'\u1EC4', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND TILDE
  'E4+E3+4F':'\u1ED6', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND TILDE
  'E4+E3+61':'\u1EAB', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND TILDE
  'E4+E3+65':'\u1EC5', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND TILDE
  'E4+E3+6F':'\u1ED7', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND TILDE
  'E4+E6+41':'\u1EB4', // LATIN CAPITAL LETTER A WITH BREVE AND TILDE
  'E4+E6+61':'\u1EB5', // LATIN SMALL LETTER A WITH BREVE AND TILDE
  'E4+E8+4F':'\u1E4E', // LATIN CAPITAL LETTER O WITH TILDE AND DIAERESIS
  'E4+E8+6F':'\u1E4F', // LATIN SMALL LETTER O WITH TILDE AND DIAERESIS
  'E4':'',             // COMBINING TILDE         \u0303

  'E5+41':'\u0100',    // LATIN CAPITAL LETTER A WITH MACRON
  'E5+45':'\u0112',    // LATIN CAPITAL LETTER E WITH MACRON
  'E5+47':'\u1E20',    // LATIN CAPITAL LETTER G WITH MACRON
  'E5+49':'\u012A',    // LATIN CAPITAL LETTER I WITH MACRON
  'E5+4F':'\u014C',    // LATIN CAPITAL LETTER O WITH MACRON
  'E5+55':'\u016A',    // LATIN CAPITAL LETTER U WITH MACRON
  'E5+61':'\u0101',    // LATIN SMALL LETTER A WITH MACRON
  'E5+65':'\u0113',    // LATIN SMALL LETTER E WITH MACRON
  'E5+67':'\u1E21',    // LATIN SMALL LETTER G WITH MACRON
  'E5+69':'\u012B',    // LATIN SMALL LETTER I WITH MACRON
  'E5+6F':'\u014D',    // LATIN SMALL LETTER O WITH MACRON
  'E5+75':'\u016B',    // LATIN SMALL LETTER U WITH MACRON
  'E5+A5':'\u01E2',    // LATIN CAPITAL LETTER AE WITH MACRON
  'E5+B5':'\u01E3',    // LATIN SMALL LETTER AE WITH MACRON
  'E5+E1+45':'\u1E14', // LATIN CAPITAL LETTER E WITH MACRON AND GRAVE
  'E5+E1+4F':'\u1E50', // LATIN CAPITAL LETTER O WITH MACRON AND GRAVE
  'E5+E1+65':'\u1E15', // LATIN SMALL LETTER E WITH MACRON AND GRAVE
  'E5+E1+6F':'\u1E51', // LATIN SMALL LETTER O WITH MACRON AND GRAVE
  'E5+E2+45':'\u1E16', // LATIN CAPITAL LETTER E WITH MACRON AND ACUTE
  'E5+E2+4F':'\u1E52', // LATIN CAPITAL LETTER O WITH MACRON AND ACUTE
  'E5+E2+65':'\u1E17', // LATIN SMALL LETTER E WITH MACRON AND ACUTE
  'E5+E2+6F':'\u1E53', // LATIN SMALL LETTER O WITH MACRON AND ACUTE
  'E5+E7+41':'\u01E0', // LATIN CAPITAL LETTER A WITH DOT ABOVE AND MACRON
  'E5+E7+61':'\u01E1', // LATIN SMALL LETTER A WITH DOT ABOVE AND MACRON
  'E5+E8+41':'\u01DE', // LATIN CAPITAL LETTER A WITH DIAERESIS AND MACRON
  'E5+E8+55':'\u1E7A', // LATIN CAPITAL LETTER U WITH DIAERESIS AND MACRON
  'E5+E8+61':'\u01DF', // LATIN SMALL LETTER A WITH DIAERESIS AND MACRON
  'E5+E8+75':'\u1E7B', // LATIN SMALL LETTER U WITH DIAERESIS AND MACRON
  'E5+F1+4F':'\u01EC', // LATIN CAPITAL LETTER O WITH OGONEK AND MACRON
  'E5+F1+6F':'\u01ED', // LATIN SMALL LETTER O WITH OGONEK AND MACRON
  'E5+F2+4C':'\u1E38', // LATIN CAPITAL LETTER L WITH DOT BELOW AND MACRON
  'E5+F2+52':'\u1E5C', // LATIN CAPITAL LETTER R WITH DOT BELOW AND MACRON
  'E5+F2+6C':'\u1E39', // LATIN SMALL LETTER L WITH DOT BELOW AND MACRON
  'E5+F2+72':'\u1E5D', // LATIN SMALL LETTER R WITH DOT BELOW AND MACRON
  'E5':'',             // COMBINING MACRON     \u0304

  'E6+41':'\u0102',    // LATIN CAPITAL LETTER A WITH BREVE
  'E6+45':'\u0114',    // LATIN CAPITAL LETTER E WITH BREVE
  'E6+47':'\u011E',    // LATIN CAPITAL LETTER G WITH BREVE
  'E6+49':'\u012C',    // LATIN CAPITAL LETTER I WITH BREVE
  'E6+4F':'\u014E',    // LATIN CAPITAL LETTER O WITH BREVE
  'E6+55':'\u016C',    // LATIN CAPITAL LETTER U WITH BREVE
  'E6+61':'\u0103',    // LATIN SMALL LETTER A WITH BREVE
  'E6+65':'\u0115',    // LATIN SMALL LETTER E WITH BREVE
  'E6+67':'\u011F',    // LATIN SMALL LETTER G WITH BREVE
  'E6+69':'\u012D',    // LATIN SMALL LETTER I WITH BREVE
  'E6+6F':'\u014F',    // LATIN SMALL LETTER O WITH BREVE
  'E6+75':'\u016D',    // LATIN SMALL LETTER U WITH BREVE
  'E6+E0+41':'\u1EB2', // LATIN CAPITAL LETTER A WITH BREVE AND HOOK ABOVE
  'E6+E0+61':'\u1EB3', // LATIN SMALL LETTER A WITH BREVE AND HOOK ABOVE
  'E6+E1+41':'\u1EB0', // LATIN CAPITAL LETTER A WITH BREVE AND GRAVE
  'E6+E1+61':'\u1EB1', // LATIN SMALL LETTER A WITH BREVE AND GRAVE
  'E6+E2+41':'\u1EAE', // LATIN CAPITAL LETTER A WITH BREVE AND ACUTE
  'E6+E2+61':'\u1EAF', // LATIN SMALL LETTER A WITH BREVE AND ACUTE
  'E6+E4+41':'\u1EB4', // LATIN CAPITAL LETTER A WITH BREVE AND TILDE
  'E6+E4+61':'\u1EB5', // LATIN SMALL LETTER A WITH BREVE AND TILDE
  'E6+F0+45':'\u1E1C', // LATIN CAPITAL LETTER E WITH CEDILLA AND BREVE
  'E6+F0+65':'\u1E1D', // LATIN SMALL LETTER E WITH CEDILLA AND BREVE
  'E6+F2+41':'\u1EB6', // LATIN CAPITAL LETTER A WITH BREVE AND DOT BELOW
  'E6+F2+61':'\u1EB7', // LATIN SMALL LETTER A WITH BREVE AND DOT BELOW
  'E6':'',             // COMBINING BREVE   \u0306

  'E7+42':'\u1E02',    // LATIN CAPITAL LETTER B WITH DOT ABOVE
  'E7+43':'\u010A',    // LATIN CAPITAL LETTER C WITH DOT ABOVE
  'E7+44':'\u1E0A',    // LATIN CAPITAL LETTER D WITH DOT ABOVE
  'E7+45':'\u0116',    // LATIN CAPITAL LETTER E WITH DOT ABOVE
  'E7+46':'\u1E1E',    // LATIN CAPITAL LETTER F WITH DOT ABOVE
  'E7+47':'\u0120',    // LATIN CAPITAL LETTER G WITH DOT ABOVE
  'E7+48':'\u1E22',    // LATIN CAPITAL LETTER H WITH DOT ABOVE
  'E7+49':'\u0130',    // LATIN CAPITAL LETTER I WITH DOT ABOVE
  'E7+4D':'\u1E40',    // LATIN CAPITAL LETTER M WITH DOT ABOVE
  'E7+4E':'\u1E44',    // LATIN CAPITAL LETTER N WITH DOT ABOVE
  'E7+50':'\u1E56',    // LATIN CAPITAL LETTER P WITH DOT ABOVE
  'E7+52':'\u1E58',    // LATIN CAPITAL LETTER R WITH DOT ABOVE
  'E7+53':'\u1E60',    // LATIN CAPITAL LETTER S WITH DOT ABOVE
  'E7+54':'\u1E6A',    // LATIN CAPITAL LETTER T WITH DOT ABOVE
  'E7+57':'\u1E86',    // LATIN CAPITAL LETTER W WITH DOT ABOVE
  'E7+58':'\u1E8A',    // LATIN CAPITAL LETTER X WITH DOT ABOVE
  'E7+59':'\u1E8E',    // LATIN CAPITAL LETTER Y WITH DOT ABOVE
  'E7+5A':'\u017B',    // LATIN CAPITAL LETTER Z WITH DOT ABOVE
  'E7+62':'\u1E03',    // LATIN SMALL LETTER B WITH DOT ABOVE
  'E7+63':'\u010B',    // LATIN SMALL LETTER C WITH DOT ABOVE
  'E7+64':'\u1E0B',    // LATIN SMALL LETTER D WITH DOT ABOVE
  'E7+65':'\u0117',    // LATIN SMALL LETTER E WITH DOT ABOVE
  'E7+66':'\u1E1F',    // LATIN SMALL LETTER F WITH DOT ABOVE
  'E7+67':'\u0121',    // LATIN SMALL LETTER G WITH DOT ABOVE
  'E7+68':'\u1E23',    // LATIN SMALL LETTER H WITH DOT ABOVE
  'E7+6D':'\u1E41',    // LATIN SMALL LETTER M WITH DOT ABOVE
  'E7+6E':'\u1E45',    // LATIN SMALL LETTER N WITH DOT ABOVE
  'E7+70':'\u1E57',    // LATIN SMALL LETTER P WITH DOT ABOVE
  'E7+72':'\u1E59',    // LATIN SMALL LETTER R WITH DOT ABOVE
  'E7+73':'\u1E61',    // LATIN SMALL LETTER S WITH DOT ABOVE
  'E7+74':'\u1E6B',    // LATIN SMALL LETTER T WITH DOT ABOVE
  'E7+77':'\u1E87',    // LATIN SMALL LETTER W WITH DOT ABOVE
  'E7+78':'\u1E8B',    // LATIN SMALL LETTER X WITH DOT ABOVE
  'E7+79':'\u1E8F',    // LATIN SMALL LETTER Y WITH DOT ABOVE
  'E7+7A':'\u017C',    // LATIN SMALL LETTER Z WITH DOT ABOVE
  'E7+E2+53':'\u1E64', // LATIN CAPITAL LETTER S WITH ACUTE AND DOT ABOVE
  'E7+E2+73':'\u1E65', // LATIN SMALL LETTER S WITH ACUTE AND DOT ABOVE
  'E7+E5+41':'\u01E0', // LATIN CAPITAL LETTER A WITH DOT ABOVE AND MACRON
  'E7+E5+61':'\u01E1', // LATIN SMALL LETTER A WITH DOT ABOVE AND MACRON
  'E7+E9+53':'\u1E66', // LATIN CAPITAL LETTER S WITH CARON AND DOT ABOVE
  'E7+E9+73':'\u1E67', // LATIN SMALL LETTER S WITH CARON AND DOT ABOVE
  'E7+F2+53':'\u1E68', // LATIN CAPITAL LETTER S WITH DOT BELOW AND DOT ABOVE
  'E7+F2+73':'\u1E69', // LATIN SMALL LETTER S WITH DOT BELOW AND DOT ABOVE
  'E7':'',             // COMBINING DOT ABOVE       \u0307

  'E8+41':'\u00C4',    // LATIN CAPITAL LETTER A WITH DIAERESIS
  'E8+45':'\u00CB',    // LATIN CAPITAL LETTER E WITH DIAERESIS
  'E8+48':'\u1E26',    // LATIN CAPITAL LETTER H WITH DIAERESIS
  'E8+49':'\u00CF',    // LATIN CAPITAL LETTER I WITH DIAERESIS
  'E8+4F':'\u00D6',    // LATIN CAPITAL LETTER O WITH DIAERESIS
  'E8+55':'\u00DC',    // LATIN CAPITAL LETTER U WITH DIAERESIS
  'E8+57':'\u1E84',    // LATIN CAPITAL LETTER W WITH DIAERESIS
  'E8+58':'\u1E8C',    // LATIN CAPITAL LETTER X WITH DIAERESIS
  'E8+59':'\u0178',    // LATIN CAPITAL LETTER Y WITH DIAERESIS
  'E8+61':'\u00E4',    // LATIN SMALL LETTER A WITH DIAERESIS
  'E8+65':'\u00EB',    // LATIN SMALL LETTER E WITH DIAERESIS
  'E8+68':'\u1E27',    // LATIN SMALL LETTER H WITH DIAERESIS
  'E8+69':'\u00EF',    // LATIN SMALL LETTER I WITH DIAERESIS
  'E8+6F':'\u00F6',    // LATIN SMALL LETTER O WITH DIAERESIS
  'E8+74':'\u1E97',    // LATIN SMALL LETTER T WITH DIAERESIS
  'E8+75':'\u00FC',    // LATIN SMALL LETTER U WITH DIAERESIS
  'E8+77':'\u1E85',    // LATIN SMALL LETTER W WITH DIAERESIS
  'E8+78':'\u1E8D',    // LATIN SMALL LETTER X WITH DIAERESIS
  'E8+79':'\u00FF',    // LATIN SMALL LETTER Y WITH DIAERESIS
  'E8+E1+55':'\u01DB', // LATIN CAPITAL LETTER U WITH DIAERESIS AND GRAVE
  'E8+E1+75':'\u01DC', // LATIN SMALL LETTER U WITH DIAERESIS AND GRAVE
  'E8+E2+49':'\u1E2E', // LATIN CAPITAL LETTER I WITH DIAERESIS AND ACUTE
  'E8+E2+55':'\u01D7', // LATIN CAPITAL LETTER U WITH DIAERESIS AND ACUTE
  'E8+E2+69':'\u1E2F', // LATIN SMALL LETTER I WITH DIAERESIS AND ACUTE
  'E8+E2+75':'\u01D8', // LATIN SMALL LETTER U WITH DIAERESIS AND ACUTE
  'E8+E4+4F':'\u1E4E', // LATIN CAPITAL LETTER O WITH TILDE AND DIAERESIS
  'E8+E4+6F':'\u1E4F', // LATIN SMALL LETTER O WITH TILDE AND DIAERESIS
  'E8+E5+41':'\u01DE', // LATIN CAPITAL LETTER A WITH DIAERESIS AND MACRON
  'E8+E5+55':'\u1E7A', // LATIN CAPITAL LETTER U WITH DIAERESIS AND MACRON
  'E8+E5+61':'\u01DF', // LATIN SMALL LETTER A WITH DIAERESIS AND MACRON
  'E8+E5+75':'\u1E7B', // LATIN SMALL LETTER U WITH DIAERESIS AND MACRON
  'E8+E9+55':'\u01D9', // LATIN CAPITAL LETTER U WITH DIAERESIS AND CARON
  'E8+E9+75':'\u01DA', // LATIN SMALL LETTER U WITH DIAERESIS AND CARON
  'E8':'',             // COMBINING DIAERESIS   \0308

  'E9+41':'\u01CD',    // LATIN CAPITAL LETTER A WITH CARON
  'E9+43':'\u010C',    // LATIN CAPITAL LETTER C WITH CARON
  'E9+44':'\u010E',    // LATIN CAPITAL LETTER D WITH CARON
  'E9+45':'\u011A',    // LATIN CAPITAL LETTER E WITH CARON
  'E9+47':'\u01E6',    // LATIN CAPITAL LETTER G WITH CARON
  'E9+49':'\u01CF',    // LATIN CAPITAL LETTER I WITH CARON
  'E9+4B':'\u01E8',    // LATIN CAPITAL LETTER K WITH CARON
  'E9+4C':'\u013D',    // LATIN CAPITAL LETTER L WITH CARON
  'E9+4E':'\u0147',    // LATIN CAPITAL LETTER N WITH CARON
  'E9+4F':'\u01D1',    // LATIN CAPITAL LETTER O WITH CARON
  'E9+52':'\u0158',    // LATIN CAPITAL LETTER R WITH CARON
  'E9+53':'\u0160',    // LATIN CAPITAL LETTER S WITH CARON
  'E9+54':'\u0164',    // LATIN CAPITAL LETTER T WITH CARON
  'E9+55':'\u01D3',    // LATIN CAPITAL LETTER U WITH CARON
  'E9+5A':'\u017D',    // LATIN CAPITAL LETTER Z WITH CARON
  'E9+61':'\u01CE',    // LATIN SMALL LETTER A WITH CARON
  'E9+63':'\u010D',    // LATIN SMALL LETTER C WITH CARON
  'E9+64':'\u010F',    // LATIN SMALL LETTER D WITH CARON
  'E9+65':'\u011B',    // LATIN SMALL LETTER E WITH CARON
  'E9+67':'\u01E7',    // LATIN SMALL LETTER G WITH CARON
  'E9+69':'\u01D0',    // LATIN SMALL LETTER I WITH CARON
  'E9+6A':'\u01F0',    // LATIN SMALL LETTER J WITH CARON
  'E9+6B':'\u01E9',    // LATIN SMALL LETTER K WITH CARON
  'E9+6C':'\u013E',    // LATIN SMALL LETTER L WITH CARON
  'E9+6E':'\u0148',    // LATIN SMALL LETTER N WITH CARON
  'E9+6F':'\u01D2',    // LATIN SMALL LETTER O WITH CARON
  'E9+72':'\u0159',    // LATIN SMALL LETTER R WITH CARON
  'E9+73':'\u0161',    // LATIN SMALL LETTER S WITH CARON
  'E9+74':'\u0165',    // LATIN SMALL LETTER T WITH CARON
  'E9+75':'\u01D4',    // LATIN SMALL LETTER U WITH CARON
  'E9+7A':'\u017E',    // LATIN SMALL LETTER Z WITH CARON
  'E9+E7+53':'\u1E66', // LATIN CAPITAL LETTER S WITH CARON AND DOT ABOVE
  'E9+E7+73':'\u1E67', // LATIN SMALL LETTER S WITH CARON AND DOT ABOVE
  'E9+E8+55':'\u01D9', // LATIN CAPITAL LETTER U WITH DIAERESIS AND CARON
  'E9+E8+75':'\u01DA', // LATIN SMALL LETTER U WITH DIAERESIS AND CARON
  'E9':'',             // COMBINING CARON      \u030C

  'EA+41':'\u00C5',    // LATIN CAPITAL LETTER A WITH RING ABOVE
  'EA+55':'\u016E',    // LATIN CAPITAL LETTER U WITH RING ABOVE
  'EA+61':'\u00E5',    // LATIN SMALL LETTER A WITH RING ABOVE
  'EA+75':'\u016F',    // LATIN SMALL LETTER U WITH RING ABOVE
  'EA+77':'\u1E98',    // LATIN SMALL LETTER W WITH RING ABOVE
  'EA+79':'\u1E99',    // LATIN SMALL LETTER Y WITH RING ABOVE
  'EA+E2+41':'\u01FA', // LATIN CAPITAL LETTER A WITH RING ABOVE AND ACUTE
  'EA+E2+61':'\u01FB', // LATIN SMALL LETTER A WITH RING ABOVE AND ACUTE
  'EA':'',             // COMBINING RING ABOVE   \u030A

  'EB':'\uFE20',       // COMBINING LIGATURE LEFT HALF
  'EC':'\uFE21',       // COMBINING LIGATURE RIGHT HALF
  'ED':'\u0315',       // COMBINING COMMA ABOVE RIGHT

  'EE+4F':'\u0150',    // LATIN CAPITAL LETTER O WITH DOUBLE ACUTE
  'EE+55':'\u0170',    // LATIN CAPITAL LETTER U WITH DOUBLE ACUTE
  'EE+6F':'\u0151',    // LATIN SMALL LETTER O WITH DOUBLE ACUTE
  'EE+75':'\u0171',    // LATIN SMALL LETTER U WITH DOUBLE ACUTE
  'EE':'',             // COMBINING DOUBLE ACUTE ACCENT    \u030B

  'EF':'\u0310',       // COMBINING CANDRABINDU    

  'F0+43':'\u00C7',    // LATIN CAPITAL LETTER C WITH CEDILLA
  'F0+44':'\u1E10',    // LATIN CAPITAL LETTER D WITH CEDILLA
  'F0+47':'\u0122',    // LATIN CAPITAL LETTER G WITH CEDILLA
  'F0+48':'\u1E28',    // LATIN CAPITAL LETTER H WITH CEDILLA
  'F0+4B':'\u0136',    // LATIN CAPITAL LETTER K WITH CEDILLA
  'F0+4C':'\u013B',    // LATIN CAPITAL LETTER L WITH CEDILLA
  'F0+4E':'\u0145',    // LATIN CAPITAL LETTER N WITH CEDILLA
  'F0+52':'\u0156',    // LATIN CAPITAL LETTER R WITH CEDILLA
  'F0+53':'\u015E',    // LATIN CAPITAL LETTER S WITH CEDILLA
  'F0+54':'\u0162',    // LATIN CAPITAL LETTER T WITH CEDILLA
  'F0+63':'\u00E7',    // LATIN SMALL LETTER C WITH CEDILLA
  'F0+64':'\u1E11',    // LATIN SMALL LETTER D WITH CEDILLA
  'F0+67':'\u0123',    // LATIN SMALL LETTER G WITH CEDILLA
  'F0+68':'\u1E29',    // LATIN SMALL LETTER H WITH CEDILLA
  'F0+6B':'\u0137',    // LATIN SMALL LETTER K WITH CEDILLA
  'F0+6C':'\u013C',    // LATIN SMALL LETTER L WITH CEDILLA
  'F0+6E':'\u0146',    // LATIN SMALL LETTER N WITH CEDILLA
  'F0+72':'\u0157',    // LATIN SMALL LETTER R WITH CEDILLA
  'F0+73':'\u015F',    // LATIN SMALL LETTER S WITH CEDILLA
  'F0+74':'\u0163',    // LATIN SMALL LETTER T WITH CEDILLA
  'F0+E2+43':'\u1E08', // LATIN CAPITAL LETTER C WITH CEDILLA AND ACUTE
  'F0+E2+63':'\u1E09', // LATIN SMALL LETTER C WITH CEDILLA AND ACUTE
  'F0+E6+45':'\u1E1C', // LATIN CAPITAL LETTER E WITH CEDILLA AND BREVE
  'F0+E6+65':'\u1E1D', // LATIN SMALL LETTER E WITH CEDILLA AND BREVE
  'F0':'',             // COMBINING CEDILLA  \u0327

  'F1+41':'\u0104',    // LATIN CAPITAL LETTER A WITH OGONEK
  'F1+45':'\u0118',    // LATIN CAPITAL LETTER E WITH OGONEK
  'F1+49':'\u012E',    // LATIN CAPITAL LETTER I WITH OGONEK
  'F1+4F':'\u01EA',    // LATIN CAPITAL LETTER O WITH OGONEK
  'F1+55':'\u0172',    // LATIN CAPITAL LETTER U WITH OGONEK
  'F1+61':'\u0105',    // LATIN SMALL LETTER A WITH OGONEK
  'F1+65':'\u0119',    // LATIN SMALL LETTER E WITH OGONEK
  'F1+69':'\u012F',    // LATIN SMALL LETTER I WITH OGONEK
  'F1+6F':'\u01EB',    // LATIN SMALL LETTER O WITH OGONEK
  'F1+75':'\u0173',    // LATIN SMALL LETTER U WITH OGONEK
  'F1+E5+4F':'\u01EC', // LATIN CAPITAL LETTER O WITH OGONEK AND MACRON
  'F1+E5+6F':'\u01ED', // LATIN SMALL LETTER O WITH OGONEK AND MACRON
  'F1':'',             // COMBINING OGONEK   \u0328

  'F2+41':'\u1EA0',    // LATIN CAPITAL LETTER A WITH DOT BELOW
  'F2+42':'\u1E04',    // LATIN CAPITAL LETTER B WITH DOT BELOW
  'F2+44':'\u1E0C',    // LATIN CAPITAL LETTER D WITH DOT BELOW
  'F2+45':'\u1EB8',    // LATIN CAPITAL LETTER E WITH DOT BELOW
  'F2+48':'\u1E24',    // LATIN CAPITAL LETTER H WITH DOT BELOW
  'F2+49':'\u1ECA',    // LATIN CAPITAL LETTER I WITH DOT BELOW
  'F2+4B':'\u1E32',    // LATIN CAPITAL LETTER K WITH DOT BELOW
  'F2+4C':'\u1E36',    // LATIN CAPITAL LETTER L WITH DOT BELOW
  'F2+4D':'\u1E42',    // LATIN CAPITAL LETTER M WITH DOT BELOW
  'F2+4E':'\u1E46',    // LATIN CAPITAL LETTER N WITH DOT BELOW
  'F2+4F':'\u1ECC',    // LATIN CAPITAL LETTER O WITH DOT BELOW
  'F2+52':'\u1E5A',    // LATIN CAPITAL LETTER R WITH DOT BELOW
  'F2+53':'\u1E62',    // LATIN CAPITAL LETTER S WITH DOT BELOW
  'F2+54':'\u1E6C',    // LATIN CAPITAL LETTER T WITH DOT BELOW
  'F2+55':'\u1EE4',    // LATIN CAPITAL LETTER U WITH DOT BELOW
  'F2+56':'\u1E7E',    // LATIN CAPITAL LETTER V WITH DOT BELOW
  'F2+57':'\u1E88',    // LATIN CAPITAL LETTER W WITH DOT BELOW
  'F2+59':'\u1EF4',    // LATIN CAPITAL LETTER Y WITH DOT BELOW
  'F2+5A':'\u1E92',    // LATIN CAPITAL LETTER Z WITH DOT BELOW
  'F2+61':'\u1EA1',    // LATIN SMALL LETTER A WITH DOT BELOW
  'F2+62':'\u1E05',    // LATIN SMALL LETTER B WITH DOT BELOW
  'F2+64':'\u1E0D',    // LATIN SMALL LETTER D WITH DOT BELOW
  'F2+65':'\u1EB9',    // LATIN SMALL LETTER E WITH DOT BELOW
  'F2+68':'\u1E25',    // LATIN SMALL LETTER H WITH DOT BELOW
  'F2+69':'\u1ECB',    // LATIN SMALL LETTER I WITH DOT BELOW
  'F2+6B':'\u1E33',    // LATIN SMALL LETTER K WITH DOT BELOW
  'F2+6C':'\u1E37',    // LATIN SMALL LETTER L WITH DOT BELOW
  'F2+6D':'\u1E43',    // LATIN SMALL LETTER M WITH DOT BELOW
  'F2+6E':'\u1E47',    // LATIN SMALL LETTER N WITH DOT BELOW
  'F2+6F':'\u1ECD',    // LATIN SMALL LETTER O WITH DOT BELOW
  'F2+72':'\u1E5B',    // LATIN SMALL LETTER R WITH DOT BELOW
  'F2+73':'\u1E63',    // LATIN SMALL LETTER S WITH DOT BELOW
  'F2+74':'\u1E6D',    // LATIN SMALL LETTER T WITH DOT BELOW
  'F2+75':'\u1EE5',    // LATIN SMALL LETTER U WITH DOT BELOW
  'F2+76':'\u1E7F',    // LATIN SMALL LETTER V WITH DOT BELOW
  'F2+77':'\u1E89',    // LATIN SMALL LETTER W WITH DOT BELOW
  'F2+79':'\u1EF5',    // LATIN SMALL LETTER Y WITH DOT BELOW
  'F2+7A':'\u1E93',    // LATIN SMALL LETTER Z WITH DOT BELOW
  'F2+E3+41':'\u1EAC', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND DOT BELOW
  'F2+E3+45':'\u1EC6', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND DOT BELOW
  'F2+E3+4F':'\u1ED8', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND DOT BELOW
  'F2+E3+61':'\u1EAD', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND DOT BELOW
  'F2+E3+65':'\u1EC7', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND DOT BELOW
  'F2+E3+6F':'\u1ED9', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND DOT BELOW
  'F2+E5+4C':'\u1E38', // LATIN CAPITAL LETTER L WITH DOT BELOW AND MACRON
  'F2+E5+52':'\u1E5C', // LATIN CAPITAL LETTER R WITH DOT BELOW AND MACRON
  'F2+E5+6C':'\u1E39', // LATIN SMALL LETTER L WITH DOT BELOW AND MACRON
  'F2+E5+72':'\u1E5D', // LATIN SMALL LETTER R WITH DOT BELOW AND MACRON
  'F2+E6+41':'\u1EB6', // LATIN CAPITAL LETTER A WITH BREVE AND DOT BELOW
  'F2+E6+61':'\u1EB7', // LATIN SMALL LETTER A WITH BREVE AND DOT BELOW
  'F2+E7+53':'\u1E68', // LATIN CAPITAL LETTER S WITH DOT BELOW AND DOT ABOVE
  'F2+E7+73':'\u1E69', // LATIN SMALL LETTER S WITH DOT BELOW AND DOT ABOVE
  'F2':'',             // COMBINING DOT BELOW    \u0323

  'F3+55':'\u1E72',    // LATIN CAPITAL LETTER U WITH DIAERESIS BELOW
  'F3+75':'\u1E73',    // LATIN SMALL LETTER U WITH DIAERESIS BELOW
  'F3':'',             // COMBINING DIAERESIS BELOW    \u0324

  'F4+41':'\u1E00',    // LATIN CAPITAL LETTER A WITH RING BELOW
  'F4+61':'\u1E01',    // LATIN SMALL LETTER A WITH RING BELOW
  'F4':'',             // COMBINING RING BELOW    \u0325

  'F5':'\u0333',       // COMBINING DOUBLE LOW LINE

  'F6+42':'\u1E06',    // LATIN CAPITAL LETTER B WITH LINE BELOW
  'F6+44':'\u1E0E',    // LATIN CAPITAL LETTER D WITH LINE BELOW
  'F6+4B':'\u1E34',    // LATIN CAPITAL LETTER K WITH LINE BELOW
  'F6+4C':'\u1E3A',    // LATIN CAPITAL LETTER L WITH LINE BELOW
  'F6+4E':'\u1E48',    // LATIN CAPITAL LETTER N WITH LINE BELOW
  'F6+52':'\u1E5E',    // LATIN CAPITAL LETTER R WITH LINE BELOW
  'F6+54':'\u1E6E',    // LATIN CAPITAL LETTER T WITH LINE BELOW
  'F6+5A':'\u1E94',    // LATIN CAPITAL LETTER Z WITH LINE BELOW
  'F6+62':'\u1E07',    // LATIN SMALL LETTER B WITH LINE BELOW
  'F6+64':'\u1E0F',    // LATIN SMALL LETTER D WITH LINE BELOW
  'F6+68':'\u1E96',    // LATIN SMALL LETTER H WITH LINE BELOW
  'F6+6B':'\u1E35',    // LATIN SMALL LETTER K WITH LINE BELOW
  'F6+6C':'\u1E3B',    // LATIN SMALL LETTER L WITH LINE BELOW
  'F6+6E':'\u1E49',    // LATIN SMALL LETTER N WITH LINE BELOW
  'F6+72':'\u1E5F',    // LATIN SMALL LETTER R WITH LINE BELOW
  'F6+74':'\u1E6F',    // LATIN SMALL LETTER T WITH LINE BELOW
  'F6+7A':'\u1E95',    // LATIN SMALL LETTER Z WITH LINE BELOW
  'F6':'',             // COMBINING LOW LINE     \u0332

  'F7':'\u0326',       // COMBINING COMMA BELOW
  'F8':'\u0321',       // COMBINING OGONEK

  'F9+48':'\u1E2A',    // LATIN CAPITAL LETTER H WITH BREVE BELOW
  'F9+68':'\u1E2B',    // LATIN SMALL LETTER H WITH BREVE BELOW
  'F9':'',             // COMBINING BREVE BELOW    \u032E
  
  'FA':'\uFE22',       // COMBINING DOUBLE TILDE LEFT HALF
  'FB':'\uFE23',       // COMBINING DOUBLE TILDE RIGHT HALF

  'FE+41':'\u1F08',    // Ἀ Greek Capital Letter Alpha with Psili
  'FE+45':'\u1F18',    // Ἐ Greek Capital Letter Alpha with Psili
  'FE+61':'\u1F00',    // ἀ Greek Small Letter Alpha with Psili
  'FE+65':'\u1F10',    // ἐ Greek Small Letter Epsilon with Psili
  'FE+4e':'\u1F08',    // ἠ Greek Capital Letter Alpha with Psili
  'FE':'',             // Psili  \u1F08

  'FC':'',             // ̸   - diacritic slash through char  \u0338
}