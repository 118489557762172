<template>
  <b-jumbotron header="Contact Us" lead="">
    <b-card class="narrow-container-0">
      <b-card-text>
        <b-table-simple borderless>
          <b-tbody>
            <b-tr>
              <b-td class="text-right"><img src="@/assets/CompanyLogo.png" height="70px" width="75px"></b-td>
              <b-td class="align-middle"><h5>Ibex Consulting Inc.</h5></b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">Address:</b-th>
              <b-td>22-7250 144ST<br>Surrey, BC Canada<br>V3W 1L7</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-right">Email:</b-th>
              <b-td><b-link href="mailto:support@relativetome.com">support@relativetome.com</b-link></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-text>
    </b-card>
  </b-jumbotron>
</template>

<script>

export default {
  data() {
    return {}
  }
}
</script>