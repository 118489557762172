<template>
  <div :class="classIndividualName">
    <span v-html="name" />
    <b-icon v-if="isDuplicate"
            v-b-tooltip.hover.bottomright="duplicateText"
            class="hover-pointer"
            icon="info-square" />
    <b-badge v-if="showButtons" @click="onShowTree" class="action-icon" variant="primary"><font-awesome-icon :icon="['fas', 'project-diagram']" />Tree</b-badge>
    <b-badge v-if="showButtons && !hideDetailButton" @click="onShowIndividualDetails" class="action-icon" variant="primary"><font-awesome-icon :icon="['fas', 'book']" />Particulars</b-badge>
    <b-badge v-if="unionId" @click="onShowUnionDetails" class="action-icon" variant="primary"><font-awesome-icon :icon="['fas', 'link']" />Union</b-badge>
  </div>
</template>

<script>
  import _ from 'lodash' 
  import { mapState } from 'vuex'
  import * as individualUtils from '@/logic/family/individualUtils'
  import * as urlUtils from '@/logic/general/urlUtils'
  
  export default {
    name: 'IndividualName',
    props: {
      citationNumber: {type: Number},
      hideDetailButton: {type: Boolean, default: false},
      individualId: {type: String, required: true},
      isInTree: {type: Boolean, default: false},
      showButtons: {type: Boolean, default: false},
      unionId:  {type: String},
      useFormalName: {type: Boolean, default: false}
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState('family', [
        'familyData',
        'familyId',
        'familyName'
      ]),
      ...mapState('viewState', [
        'renderedIndividuals'
      ]),
      classIndividualName: function () {
        return {
          "individual-name": this.isInTree,
          "individual-name-info": !this.isInTree
        }
      },
      duplicateId () {
        return `duplicate-${this.individualId}`
      },
      duplicateText () {
        return `${_.unescape(this.name)} appears ${this.renderedIndividuals[this.individualId].count} times within this family tree.  Descendants or ancestors of the parents will only be shown for one these occurances.`
      },
      name: function () {
        const base = this.useFormalName ? individualUtils.getFormalName(this.individual) : individualUtils.getName(this.individual)
        return this.citationNumber ? `${base}<sup>${this.citationNumber}</sup>` : base
      },
      individual: function () {
        return individualUtils.getIndividual(this.familyData, this.individualId)
      },
      isDuplicate: function() {
        return this.isInTree && this.renderedIndividuals[this.individualId].count > 1
      }
    },
    methods: {
      onShowTree: function () {
        const pathNew = urlUtils.getIndividualURL(this.familyId, this.familyName, this.individual.id, this.name)
        this.$router.push(pathNew)
        this.$bvModal.hide('infoModal')
      },
      onShowIndividualDetails: function () {
        const pathNew = urlUtils.getIndividualURL(this.familyId, this.familyName, this.individual.id, this.name)
        this.$store.dispatch('viewState/loadInfoModal',{infoModalEntityId: this.individualId, infoModalEntityType: 'individual'} )
        this.$router.push(pathNew)
      },
      onShowUnionDetails: function () {
        this.$store.dispatch('viewState/loadInfoModal',{infoModalEntityId: this.unionId, infoModalEntityType: 'union'} )
      }
    },
    created() {
      if (this.isInTree) {
        const individual = individualUtils.getIndividual(this.familyData, this.individualId)
        this.$store.dispatch('viewState/recordRenderedIndividual', individual)
      }
    }
  }

</script>