<template>
  <b-modal id="displayOptionsModal" size="lg" hide-header @show="onShow">
    <h5>Display Options</h5>
    <div class="option-row">
      <b-button size="sm" @click="switchFamily()">
        Switch Family
      </b-button>
    </div>
    <div class="option-row">
      <div>
        <b-form-checkbox v-model="renderOptions.showAncestors" @change="onRenderOptionChange">Show Ancestors?</b-form-checkbox>
      </div>
      <div class="option-cell-slider">
        <div v-if="renderOptions.showAncestors">
          <b-input-group size="sm" prepend="Maximum Generations:">
            <b-form-input v-model.number="inputAncestorGenerations"
                          v-mask="'###'"
                          type="number"
                          :max="browserMaxGenerations"
                          min="1"
                          size="sm"
                          step="1" />
            <template #append>
              <b-input-group-text>
                <b-icon 
                  class="hover-pointer"
                  icon="info-square" 
                  v-b-popover.click.blur.bottomright="generationPopoverText" />
              </b-input-group-text>
            </template>
          </b-input-group>
          <vue-slider v-model="sliderValueAncestor" 
                      :dot-style="dotStyle"
                      :label-style="labelStyle"
                      :marks="marksGeneration"
                      :max="sliderValueMax"
                      :min="1"
                      :tooltip="'none'" />
        </div>
      </div>
    </div>
    <div class="option-row">
      <div>
        <b-form-checkbox v-model="renderOptions.showDecendents" @change="onRenderOptionChange">Show Decendents?</b-form-checkbox>
      </div>
      <div class="option-cell-slider">
        <div v-if="renderOptions.showDecendents">
          <b-input-group size="sm" prepend="Maximum Generations:">
            <b-form-input v-model.number="inputDescendentGenerations"
                          v-mask="'###'"
                          type="number"
                          :max="browserMaxGenerations"
                          min="1"
                          size="sm"
                          step="1" />
            <template #append>
              <b-input-group-text>
                <b-icon 
                  class="hover-pointer"
                  icon="info-square" 
                  v-b-popover.click.blur.bottomright="generationPopoverText" />
              </b-input-group-text>
            </template>
          </b-input-group>
          <vue-slider v-model="sliderValueDescendent" 
                      :dot-style="dotStyle"
                      :label-style="labelStyle"
                      :marks="marksGeneration"
                      :max="sliderValueMax"
                      :min="1"
                      :tooltip="'none'" />
        </div>
      </div>
    </div>
    <div class="option-row">
      <div>
        <b-form-checkbox v-model="renderOptions.showGenderInTree" @change="onRenderOptionChange">
          Show Gender In Tree?
          <span v-if="suppressGender" class="inactive-option">(This option ignored for the current family)</span>
        </b-form-checkbox>
      </div>
    </div>
    <div class="option-row">
      <div>
        Zoom
      </div>
      <div class="option-cell-slider">
        <vue-slider v-model="sliderValueZoom" 
                    absorb="true"
                    :dot-style="dotStyle"
                    :label-style="labelStyle"
                    :marks="marksZoom"
                    :max="2"
                    :min="0"
                    :tooltip="'none'" />
      </div>
    </div>
    <div class="option-row">
      <div>
        Generation Width
      </div>
      <div class="option-cell-slider">
        <vue-slider v-model="sliderValueGenerationWidth" 
                    :dot-style="dotStyle"
                    :marks="marksWidth"
                    :tooltip="'none'" />
      </div>
    </div>
    
    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-button size="sm" class="float-right" @click="close ()">
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapState } from 'vuex'
  import * as objectUtils from '@/logic/general/objectUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import _ from 'lodash'
  
  export default {
    name: 'DisplayOptionsModal',
    components: {
    },
    data() {
      return {
        debounceLength: 500,
        renderOptions: {
          generationRelativeWidth: 0,
          maxAncestorGenerations: 1000,
          maxDescendentGenerations: 1000,
          treeZoom: 2,
          showAncestors: true,
          showDecendents: true,
          showGenderInTree: true
        },
        inputAncestorGenerations: 10,
        inputDescendentGenerations: 10,
        marksWidth: {
          '1': {label: 'Narrow'},
          '100': {label: 'Wide'}
        },
        marksZoom: {
          '0': {label: 'Small'},
          '1': {label: 'Medium'},
          '2': {label: 'Large'}
        },
        sliderValueAncestor: 10,
        sliderValueDescendent: 10,
        sliderValueGenerationWidth: 50,
        sliderValueZoom: 2
      }
    },
    computed: {
      ...mapState('family', [
        'suppressGender',
      ]),
      ...mapState('viewState', [
        'browserMaxGenerations',
        'generationRelativeWidth',
        'maxAncestorGenerations',
        'maxDescendentGenerations',
        'showAncestors',
        'showDecendents',
        'showGenderInTree',
        'treeZoom'
      ]),
      dotStyle: function () {
        return {
          "background-color":"#3498db"  
        }
      },
      generationPopoverText: function () {
        return `The maximum number of generations that will be shown.  This is limited by the browser to ${this.maxEffectiveGeneration} generations.  If this family has more generations than this, we recommend you use Firefox.`
      },
      labelStyle: function () {
        return {
          "margin-top":"3px"  
        }
      },
      marksGeneration: function () {
        const base = {
          '1': {label: '1'},
          '10': {label: '10'},
          '20': {label: '20'},
          '30': {label: '30'},
          '40': {label: '80'},
          '50': {label: '130'},
          '60': {label: '180'},
          '70': {label: '230'},
          '80': {label: '280'},
          '90': {label: '330'},
          '100': {label: '380'}
        }

        return objectUtils.filter(base, p => {
          return parseInt(p.label) < this.browserMaxGenerations ? true : false
        })
      },
      maxEffectiveGeneration: function () {
        return this.calculateMaxGenerations(this.sliderValueMax)
      },
      sliderValueMax: function () {
        return this.calculateSliderValue(this.browserMaxGenerations) 
      }
    },
    methods: {
      calculateMaxGenerations: function (sliderValue) {
        return sliderValue < 31 ? sliderValue : (sliderValue - 30) * 5 + 30
      },
      calculateSliderValue: function (generations) {
        const g = generations > this.browserMaxGenerations ? this.browserMaxGenerations : generations
        const v = g < 31 ? g : Math.floor((g - 30) / 5) + 30 
        return v > 100 ? 100 : v
      },
      coerceGenerationValue: function (currentValue) {
        if (currentValue < 1) return 1
        else if (currentValue > this.maxEffectiveGeneration) return this.maxEffectiveGeneration
        else return currentValue
      },
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onGenerationInputChange: function () {
        this.renderOptions.maxAncestorGenerations = this.inputAncestorGenerations = this.coerceGenerationValue(this.inputAncestorGenerations)
        this.renderOptions.maxDescendentGenerations = this.inputDescendentGenerations = this.coerceGenerationValue(this.inputDescendentGenerations)
        this.sliderValueAncestor = this.calculateSliderValue(this.inputAncestorGenerations)
        this.sliderValueDescendent = this.calculateSliderValue(this.inputDescendentGenerations)
        this.onRenderOptionChange()
      },
      onShow: function () {
        this.renderOptions.generationRelativeWidth = this.generationRelativeWidth
        this.renderOptions.maxAncestorGenerations = this.inputAncestorGenerations = this.maxAncestorGenerations
        this.renderOptions.maxDescendentGenerations = this.inputDescendentGenerations = this.maxDescendentGenerations
        this.renderOptions.showAncestors = this.showAncestors
        this.renderOptions.showDecendents = this.showDecendents
        this.renderOptions.showGenderInTree = this.showGenderInTree
        this.renderOptions.treeZoom = this.treeZoom
        this.sliderValueAncestor = this.calculateSliderValue(this.maxAncestorGenerations)
        this.sliderValueDescendent = this.calculateSliderValue(this.maxDescendentGenerations)
        this.sliderValueGenerationWidth = this.generationRelativeWidth
        this.sliderValueZoom = this.treeZoom
      },
      onSliderChange: function () {
        this.renderOptions.generationRelativeWidth = this.sliderValueGenerationWidth
        this.renderOptions.treeZoom = this.sliderValueZoom
        this.renderOptions.maxAncestorGenerations = this.inputAncestorGenerations = this.calculateMaxGenerations(this.sliderValueAncestor)
        this.renderOptions.maxDescendentGenerations= this.inputDescendentGenerations = this.calculateMaxGenerations(this.sliderValueDescendent)
        this.onRenderOptionChange()
      },
      onRenderOptionChange: function () {
        this.$store.dispatch('viewState/updateRenderOptions', this.renderOptions)
      },
      switchFamily: function () {
        this.$bvModal.hide('displayOptionsModal')
        this.$router.push({ path: '/family/selection' })
      }
    },
    created() {
      // Add this function to the component to handle debouncing of slider values
      this.debouncedGenerationInputValues = _.debounce(this.onGenerationInputChange, this.debounceLength)
      this.debouncedSliderValues = _.debounce(this.onSliderChange, this.debounceLength)
    },
    watch: {
      inputAncestorGenerations: function (newValue) {
        this.debouncedGenerationInputValues()
      },
      inputDescendentGenerations: function (newValue) {
        this.debouncedGenerationInputValues()
      },
      sliderValueAncestor: function (newValue) {
        this.debouncedSliderValues()
      },
      sliderValueDescendent: function (newValue) {
        this.debouncedSliderValues()
      },
      sliderValueGenerationWidth: function (newValue) {
        this.debouncedSliderValues()
      },
      sliderValueZoom: function (newValue) {
        this.debouncedSliderValues()
      }
    },
  }
</script>