// Determine the maximum stack size that this browser supports.
// Thanks: https://stackoverflow.com/a/7828803/1864995
export function maxStackSize() {
  let i = 0;

  function inc() {
    i++;
    inc();
  }
      
  try {
    inc();
  }
  catch(e) {
    return i;
  }
}