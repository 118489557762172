import * as shortUUID from 'short-uuid'
import * as apiUtils from '@/logic/general/apiUtils'
import * as authenticationUtils from '@/logic/general/authenticationUtils'
import staticConfig from '@/staticConfig'

export default {
  forceReaction(context) {
    context.commit('incrementVersion')
  },

  logoff(context, vm) {
    if (vm.$auth) vm.$auth.removeToken()
    
    context.commit('clearAccessToken')
    context.commit('clearTimers')
    localStorage.setItem('ls-ctss', shortUUID.generate()) 
    vm.$gapi.logout()  // Log out of any google sessions if they happen to exist.
  },

  processAccessToken(context, vm) {
    const {commit, dispatch} = context 
    const jwt = vm.$auth.getPayload()
    commit('clearTimers')

    if (jwt) {
      context.commit('setAccessToken', jwt)

      // Access token found
      const exp = jwt.exp
      const now = Date.now() / 1000
      const timeUntilExpiry = exp - now
      const timeUntilRefresh = exp - now - staticConfig.tokenRefreshPreexpirySeconds

      // Expiry
      if (timeUntilExpiry > 0) {
        // If the expiry is in the future. Schedule a reaction one millisecond afterwards
        // In theory, this should never be hit as the refresh token logic should happen taticConfig.tokenRefreshPreexpirySeconds prior 
        const expiryTimer = setTimeout(() => dispatch('forceReaction'), (timeUntilExpiry * 1000) + 1)
        commit('setExpiryTimer', expiryTimer)
      } 

      // Refresh token
      if (timeUntilRefresh > 0) {
        // The time is far enough into the future so schedule the refresh
        const refreshTimer = setTimeout(() => dispatch('refreshAccessToken',vm), timeUntilRefresh * 1000)
        commit('setRefreshTimer', refreshTimer)
      } else {
        // The access token has already expired or will expire very shortly.  Do the refresh immediately.  Return the promise
        return dispatch('refreshAccessToken',vm)
      }
    } else {
      context.commit('clearAccessToken')
    } 
  },

  refreshAccessToken({commit, dispatch}, vm) {
    return new Promise((resolve, reject) => {
      commit('setRefreshTimer', null)

      // Send the refresh request.
      // Note: This strange syntax forces the creation the authentication instance prior to the post request so that the interceptor inserts the bearer token.  
      //       This is necessary when this method is called prior to any other reference to $auth.  This will happen when the application is loaded.
      vm.$auth.$http.post(apiUtils.buildAPIUrl('account/refresh'))
        .then(response => {
          const {data} = response

          if (data.errorCode) {
            // Refresh token is invalid.  Logoff
            console.info(`Token refresh failed.  errorCode: ${data.errorCode}   reason: ${data.reason}`)
            dispatch('logoff',vm)

            if (data.promptRelogin === true) {
              vm.$bvModal.show('authenticationModal')
            }
          } else {
            vm.$auth.storageType = authenticationUtils.determineStorageLocation(data.stayLoggedIn)
            vm.$auth.setToken(data)
            dispatch('signalNewAccessToken',vm)
          }
        })
        .catch(error => {
          const postState = error.postState

          if (postState.errorType === apiUtils.APIErrorTypes.NETWORK) {
            // If this is a network error, we will try again in 30 seconds
            const refreshTimer = setTimeout(() => dispatch('refreshAccessToken',vm), staticConfig.tokenRefreshNetworkFailureRetrySeconds * 1000)
            commit('setRefreshTimer', refreshTimer)
          } else {
            // Refresh token is invalid.  Logoff
            console.info(`Token refresh failed; reason Unknown.`)
            dispatch('logoff',vm)
          }
        })
        .finally(() => {
          // Force vue to be reactive to the new security state.
          // This may not be needed here but is a fail safe.
          dispatch('forceReaction')

          // One way or the other we resolve the dispatch promise.  If there was a network error, the user will continue on with
          // whatever authority they already have on the client.  In some cases, this might have the effect of being logged off even if they have a valid refresh
          // token; however, this should be rare.
          resolve()
        })
    })
  },

  signalNewAccessToken({dispatch}, vm) {
    localStorage.setItem('ls-ctss', shortUUID.generate())
    dispatch('processAccessToken',vm)
  }
}