import { gzip, ungzip } from 'node-gzip'

export async function objToJsonGzippedBase64(obj) {
  const jsonString = JSON.stringify(obj)
  return await stringToGzippedBase64(jsonString)
}

export async function stringToGzippedBase64(source) {
  const compressed = await gzip(source)
  return Buffer.from(compressed).toString('base64')
}

export async function base64GzippedToString(source) {
  const compressed = Buffer.from(source, 'base64')
  const uncompressed = await ungzip(compressed)
  const result = uncompressed.toString('utf-8')
  return result
}

export async function base64GzippedToObj(source) {
  const jsonString = await base64GzippedToString(source)
  return JSON.parse(jsonString)
}