import AncestorUnit from "@/models/AncestorUnit"
import Generation from "@/models/Generation"
import Household from "@/models/Household"
import * as individualUtils from '@/logic/family/individualUtils'

export default class AncestorFactory {
  ancestryGrid = []
  firstSiblingRow = Number.MAX_SAFE_INTEGER
  generations = []
  maxGenerations = 0
  maxColumn = -1
  maxRow = 0
  minRow = 0
  siblingGrid = []
  ancestorsFound = {}
  duplicateAncestorCount = 0

  constructor(familyData, individualId, maxGenerations = 1){
    this.familyData = familyData
    this.individualId = individualId
    this.maxGenerations = maxGenerations
    this.individual = individualUtils.getIndividual(this.familyData, this.individualId)
    this.generateAncestryGrids()
  }

  // This grid is one primary indiviual per cell. When time comes to render this will rendered as more cells to accomodate the lines
  // In each cell within this grid we are going to indicate how these cells are going to be rendered later on.  When 
  // rendered, we need to indicate the lines that will be drawn on the left
  buildAncestryGrid () {
    const gridAncestors = []
    const gridSiblings = []
    this.generations.reverse()  // This puts the first generation on the right.

    this.generations.forEach((generation,index) => {
      const rows = []

      generation.households.forEach((household) => {
        const first = household.startRow
        const last = household.endRow()
        const parentInformation = this.buildParentInformation(generation, household)

        for (let n = first; n <= last; n++) {
          const cellA = new AncestorUnit()
          cellA.generationId = generation.generationId
          cellA.individual = household.siblings[n - household.startRow]
          cellA.isOrphan = household.parents.length === 0
          cellA.isParent = generation.generationId > 0 && cellA.individual.id === household.focusIndividual.id  // If this individual is one of the parents of the child household, indicate that here
          cellA.hasMoreChildren = n < last

          // Parent Continuation within the household
          if (parentInformation.parentHouseholds.length == 2) {
            if (parentInformation.isFirstParentHousehold) {
              cellA.isBetweenParents = n >= household.startRow + household.focusIndividualOffset
            } else if (parentInformation.isSecondParentHousehold) {
              cellA.isBetweenParents = n < household.startRow + household.focusIndividualOffset
            }
          }

          // If this is generation 0, let's also store the individuals in the sibling grid.  Rendering will be handled differently for these guys
          // Otherwise, put it in the ancestor grid
          if (cellA.generationId === 0) {
            if (n < this.firstSiblingRow)
              this.firstSiblingRow = n

            gridSiblings.push(cellA)

          } else {
            rows[n] = cellA
          }
        }

        // We need to do parent continuation between households.  We do this at the end of the first household
        if (parentInformation.parentHouseholds.length == 2 && parentInformation.isFirstParentHousehold) {
          for (let i = parentInformation.parentHouseholds[0].endRow() + 1; i <= parentInformation.parentHouseholds[1].startRow - 1 ; i++) {
            const cellB = new AncestorUnit()
            cellB.generationId = generation.generationId
            cellB.isBetweenParents = true
            rows[i] = cellB
          }
        }
      })

      gridAncestors.push(rows)
    })

    const rotated = this.rotate(gridAncestors)
    this.ancestryGrid = this.flatten(rotated)
    this.siblingGrid = gridSiblings
  }

  // This is a single household made up of up to two parents and 1 or more children.  It also could be a single person 
  // if there are no parents.
  buildHousehold(focusIndividual, generationId, childHousehold, isFirstParent) {
    // Check to see if the focus individual has been duplicated.  This can happen for large old families where your mother and father could be related through some very distant great-great-great-grandparent.
    if (focusIndividual.id in this.ancestorsFound) {
      focusIndividual.isDuplicate = true
      const duplicateId = this.ancestorsFound[focusIndividual.id]
      this.ancestorsFound[focusIndividual.id] = duplicateId === 0 ? ++this.duplicateAncestorCount : duplicateId
    } else {
      this.ancestorsFound[focusIndividual.id] = 0
      focusIndividual.isDuplicate = false
    }

    const household = new Household(this.familyData, focusIndividual)
    const nextAvailableRow = this.buildGeneration(household, generationId)

    // If this is a parent's household, add it to the childHousehold to assist in postioning later on.
    if (childHousehold) {
      household.childHousehold = childHousehold
      childHousehold.parentHouseholds.push(household)
    }

    // Find out how far down the list of siblings is our focus individual.  The oldest sibling should have 0
    household.focusIndividualOffset = household.siblings.findIndex((individual) => individual.id === focusIndividual.id)
    
    // Simple postioning
    if (generationId == 0) {
      // Generation 0 is always positioned in a fixed position
      household.startRow = 0
    } else if (isFirstParent) {
      // If this is the first household of the generation, we can shift the household up to match the parent to the first sibling.
      this.positionFirstParent(household, nextAvailableRow)
    } else {
      // If this is the second parent, we can move the household down to match the parent with the last sibling in the child household.
      this.positionSecondParent(household)
    } 

    // Build the parent's households but only if that does not exceed our maximum number of generations
    if (generationId < this.maxGenerations) {
      household.parents.forEach((parent, index) => {
        this.buildHousehold(parent, generationId + 1, household, index === 0);

        if (index === 0) {
          // Shift the child down after creating the first parent to match.
          this.positionChildHousehold(household)
        }
      })
    }
  }

  buildParentInformation(generation, household) {
    const parentInformation = {
      hasFirstParentHousehold: false,
      hasSecondParentHousehold: false,
      isFirstParentHousehold: false,
      isSecondParentHousehold: false,
      parentHouseholds: []
    }

    if (generation.generationId > 0) {
      const parentHouseholds = household.childHousehold.parentHouseholds
      parentInformation.parentHouseholds = parentHouseholds

      if (parentHouseholds.length > 0) {
        parentInformation.hasFirstParentHousehold = true
        parentInformation.isFirstParentHousehold = parentHouseholds[0] === household
      }

      if (parentHouseholds.length == 2) {
        parentInformation.hasSecondParentHousehold = true
        parentInformation.isSecondParentHousehold = parentHouseholds[1] === household
      }
    }

    return parentInformation
  }

  flatten(rotatedGrid) {
    const flattenedGrid = []
    let n = -1
    let count = 0

    // Excludes generation 0 in each row.
    // Design note. The flattening of the array happens here instead of above in the template as a double v-for loop because that method
    //              caused a stack overflow in some complex families.
    rotatedGrid.forEach((row, rowIndex) => {
      row.slice(0,row.length-1).forEach((ancestorUnit, columnIndex) => {
        flattenedGrid[++n] = { 
            ancestorUnit: ancestorUnit, 
            isRenderable: ancestorUnit ? true : false,
            rowIndex, 
            columnIndex 
          }

        if (columnIndex > this.maxColumn) this.maxColumn = columnIndex
        if (ancestorUnit) count++
      })
    })

    console.log(`Individuals in ancestor grid: ${count}`)
    return flattenedGrid
  }

  generateAncestryGrids() {
    this.buildHousehold(this.individual, 0, null)
    this.shiftdown()
    this.buildAncestryGrid()
  }

  positionChildHousehold(household) {
    // When we return to the child household after creating the first parent household, we may need to push the child household down.
    // This is because the parent household could not have been placed properly or may have itself been shifted down as we added ancestor households
    // We are going position the start row, the first sibling, at the first parent.
    const parentHousehold = household.parentHouseholds[0]
    household.startRow = parentHousehold.startRow + parentHousehold.focusIndividualOffset
  }

  positionFirstParent(household, nextAvailableRow) {
    // We will shift up the parent's household so the parent matches the first row of the children.  If this is the first household of the generation
    // we are not restricted by how high we shift up; however, at any other time, we need to get that maximum value from the generation
    const childHouseholdStart = household.childHousehold.startRow
    household.startRow = Math.max(childHouseholdStart - household.focusIndividualOffset, nextAvailableRow)

    // We need to record the lowest row so that we can shift everything down afterwards
    if (household.startRow < this.minRow)
      this.minRow = household.startRow
  }

  positionSecondParent(household) {
    // Initially, place this household directly under the first parent's household.
    // If that first parent has numerous ancestors we need to place it under the last one of those
    const childHousehold = household.childHousehold
    household.startRow = childHousehold.parentHouseholds[0].endAncestorsRow() + 1

    // We will then try to shift down the parent's household so the parent matches the 
    // last row of the children. If the parent is already below the last child row, we will not move it.
    const minimum = childHousehold.endRow() - household.focusIndividualOffset

    if (household.startRow < minimum)
      household.startRow = minimum
  } 

  rotate(grid) {
    // We build the grid with generation in the first dimension and rows in the second dimension.  To render we need the rows to be the first
    // dimension and columns to to be the second
    const rotatedGrid = []

    for (let c = 0; c < grid.length; c++) {
      for (let r = 0; r <= this.maxRow; r++) {
        if (c===0) {
          rotatedGrid[r] = []
        }

        rotatedGrid[r][c] = grid[c][r]
      }
    }

    return rotatedGrid
  }

  buildGeneration(household, generationId) {
    if (!this.generations[generationId]) {
      this.generations[generationId] = new Generation()
    }

    const generation = this.generations[generationId]
    const nextAvailableRow = generation.nextAvailableRow()
    generation.generationId = generationId
    generation.households.push(household)

    return nextAvailableRow
  }

  shiftdown() {
    // The households may have been created below zero.  We must shift everything down so that no household will exist below row 0.
    if (this.minRow < 0) {
      const shift = 0 - this.minRow
      this.minRow = 0

      for (const generation of this.generations) {
        for (const household of generation.households) {
          household.startRow += shift
        }
      }
    }

    this.maxRow = Math.max(0, ...this.generations.map(x => x.endRow()))
  }
}
