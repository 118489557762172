<template>
  <fieldset v-if="hasFacts" class="info-section border">
    <legend class="w-auto" v-html="legend" />
    <b-table-simple hover stacked="md" class="info-table">
      <b-tbody class="align-top">
        <info-fact v-for="(fact,index) in facts" :key="`${index}`" :fact="fact" />
      </b-tbody>
    </b-table-simple>
  </fieldset>
</template>

<script>
import InfoFact from "@/components/familyInfo/InfoFact"

  export default {
    name: 'InfoFactSection',
    components: {
      InfoFact
    },
    props: {
      citationNumber: {type: Number, default: null},
      facts: {type: Array, required: true},
      name: {type: String, required: true},
    },
    computed: {
      hasFacts: function () {
        return this.facts.length > 0 ? true : false
      },
      legend: function () {
        return this.citationNumber ? `${this.name}<sup>${this.citationNumber}</sup>` : this.name
      }
    }
  }
</script>