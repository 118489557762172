<template>
  <b-jumbotron header="Terms of Use" lead="">
    <ol>
      <li>
        <h6>General</h6>
        <p>
          The relativetome website is operated by Ibex Consulting Inc. of 22-7250 144ST, Surrey, BC Canada V3W 1L7 (“relativetome”, the “Website”, or the “Service”).
          Access and use of the Service is subject to the following Terms and Conditions of Service (“Terms and Conditions”). 
          By accessing or using any part of the Service, you represent that you have read, understood, and agree to be bound by these Terms and Conditions including any future modifications. 
          “relativetome” may amend, update or change these Terms and Conditions. 
          If we do this, we will post a notice that we have made changes to these Terms and Conditions on the Website for at least 7 days after the changes are posted and will 
          indicate at the bottom of the Terms and Conditions the date these terms were last revised. Any revisions to these Terms and Conditions will become effective the earlier 
          of (i) the end of such 7-day period or (ii) the first time you access or use the Service after such changes. 
          If you do not agree to abide by these Terms and Conditions, you are not authorized to use, access or participate in the Service.
        </p>
      </li>

      <li>
        <h6>Description of Website and Service</h6>
        <p>
          The Service allows users to access and use a variety of family tree services. 
          relativetome may, in its sole discretion and at any time, update, change, suspend, make improvements to or discontinue any aspect of the Service, temporarily or permanently.
        </p>
      </li>

      <li>
        <h6>Registration</h6>
        <p>
          In connection with registering for and using the Service, you agree 
          (i) to provide accurate, current and complete information about you and/or your organization as requested by the Service; 
          (ii) to maintain the confidentiality of your password and other information related to the security of your account; 
          (iii) to maintain and promptly update any registration information you provide to the Service, to keep such information accurate, current and complete; and 
          (iv) to be fully responsible for all use of your account and for any actions that take place through your account.
        </p>
      </li>

      <li>
        <h6>Your Representations and Warranties</h6>
        <p>
          You represent and warrant to relativetome that your access and use of the Service will be in accordance with these Terms and Conditions and with all applicable laws, rules and regulations of Canada and any other relevant jurisdiction, 
          including those regarding online conduct or acceptable content, and those regarding the transmission of data or information exported from Canada and/or the jurisdiction in which you reside. 
          You further represent and warrant that you have created or own any material you submit via the Service (including Family Data) and that you have the right, as applicable, to grant us a license to use that material as 
          set forth above or the right to assign that material to us as set forth below.
        </p>
      </li>

      <li>
        <h6>Inappropriate Use</h6>
        <p>
          You will not upload, display or otherwise provide on or through the Service any content that: (i) is libelous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise violates any law or 
          infringes upon the right of any third party (including copyright, trademark, privacy, publicity or other personal or proprietary rights); or (ii) in relativetome's sole judgment, is objectionable or which restricts 
          or inhibits any other person from using the Service or which may expose relativetome or its users to any harm or liability of any kind.
        </p>
      </li>

      <li>
        <h6>Indemnification of relativetome</h6>
        <p>
          You agree to defend, indemnify and hold harmless relativetome and its directors, officers, employees, contractors, agents, suppliers, licensors, successors and assigns, from and against any and all losses, 
          claims, causes of action, obligations, liabilities and damages whatsoever, including attorneys' fees, arising out of or relating to your access or use of the Service, any false representation made to us 
          (as part of these Terms and Conditions or otherwise), your breach of any of these Terms and Conditions, or any claim that any translation we provide to you is inaccurate, inappropriate or defective in any way whatsoever.
        </p>
      </li>

      <li>
        <h6>Purchases</h6>
        <p>
          If you purchase an auto-renewing periodic subscription through the Service, your relativetome account will be billed continuously for the subscription until you terminate it as set forth below. 
          After your initial subscription period, and again after any subsequent subscription period, your subscription will automatically renew for an additional equivalent period. 
          If you do not wish your subscription to renew automatically, or if you want to change or terminate your subscription, you will need to log in to your relativetome account and follow instructions 
          to terminate or change your subscription, even if you have deleted your account.
        </p>
        <p>
          You agree to pay all fees and applicable taxes incurred by you or anyone using a relativetome account registered to you. relativetome may revise the pricing for the goods and services offered 
          through the Service at any time. All information that you provide in connection with a purchase or transaction or other monetary transaction interaction with the Service must be accurate, 
          complete, and current. You agree to pay all charges incurred by users of your credit card, debit card, or other payment method used in connection with a purchase or transaction or other monetary 
          transaction interaction with the Service at the prices in effect when such charges are incurred. You will pay any applicable taxes, if any, relating to any such purchases, transactions or other monetary transaction interactions.
        </p>
      </li>

      <li>
        <h6>Payment Processors</h6>
        <p>
          All financial transactions made in connection with the Service will be processed by a third party in accordance with their respective terms of use, privacy policy, and/or any applicable payment 
          terms and conditions. We encourage you to learn about the practices of such third party. In no event will relativetome be responsible for the actions or inactions of any third party payment processor, 
          including, but not limited to, system downtime or payment service outages.
        </p>
      </li>

      <li>
        <h6>Refund Policy</h6>
        <p>
          In the event that relativetome suspends or terminates your use of the Service or these Terms and Conditions or you close your account voluntarily, you understand and agree that you will receive no refund or 
          exchange of any kind, including for any Content or data associated with your use of the Service, or for anything else.
        </p>
      </li>

      <li>
        <h6>Third-Party Links, Sites, and Services</h6>
        <p>
          The Service may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by relativetome. 
          We do not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services. If you access any third party website, service, or content from relativetome, 
          you understand that these Terms and Conditions and our Privacy Policy do not apply to your use of such sites. You expressly acknowledge and agree that relativetome shall not be 
          responsible or liable, directly or indirectly, for any damage or loss arising from your use of any third-party website, service, or content.
        </p>
        <p>
          The Service may include advertisements, which may be targeted to the Content or information on the Service, or other information. The types and extent of advertising by relativetome on the Service are subject to change. 
          In consideration for relativetome granting you access to and use of the Service, you agree that relativetome and its third party providers and partners may place such advertising in connection with the display of 
          content or information submitted by you or others.
        </p>
      </li>

      <li>
        <h6>NO REPRESENTATIONS OR WARRANTIES BY RELATIVETOME</h6>
        <p>
          THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER CONTENT THEREIN, AND ANY OTHER INFORMATION, PROPERTY AND RIGHTS GRANTED OR PROVIDED TO YOU BY RELATIVETOME ARE PROVIDED TO YOU ON AN “AS IS” BASIS. 
          RELATIVETOME AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE, EITHER EXPRESS OR IMPLIED, AND ALL SUCH REPRESENTATIONS AND WARRANTIES, 
          INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, 
          RELATIVETOME DOES NOT MAKE ANY REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO ACCURACY, SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT, ERROR-FREE OPERATION, 
          RESULTS TO BE OBTAINED FROM USE, OR NON-INFRINGEMENT. ACCESS AND USE OF THE SERVICE MAY BE UNAVAILABLE DURING PERIODS OF PEAK DEMAND, SYSTEM UPGRADES, MALFUNCTIONS OR SCHEDULED OR UNSCHEDULED 
          MAINTENANCE OR FOR OTHER REASONS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
        </p>
      </li>

      <li>
        <h6>LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY</h6>
        <p>
          IN NO EVENT WILL RELATIVETOME BE LIABLE TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU (WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY OR OTHER THEORY) FOR INDIRECT, INCIDENTAL, SPECIAL, 
          CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATING TO THE ACCESS OR USE OF, OR THE INABILITY TO ACCESS OR USE, THE SERVICE OR ANY PORTION THEREOF, 
          INCLUDING BUT NOT LIMITED TO THE LOSS OF USE OF THE SERVICE, INACCURATE RESULTS, LOSS OF PROFITS, BUSINESS INTERRUPTION, OR DAMAGES STEMMING FROM LOSS OR CORRUPTION OF DATA OR DATA BEING RENDERED INACCURATE, 
          THE COST OF RECOVERING ANY DATA, THE COST OF SUBSTITUTE SERVICES OR CLAIMS BY THIRD PARTIES FOR ANY DAMAGE TO COMPUTERS, SOFTWARE, MODEMS, TELEPHONES OR OTHER PROPERTY, 
          EVEN IF RELATIVETOME HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. RELATIVETOME'S LIABILITY TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU FOR ANY CAUSE WHATSOEVER, 
          AND REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO RELATIVETOME FOR THE SERVICE IN THE 12 MONTHS PRIOR TO THE INITIAL ACTION GIVING RISE TO LIABILITY. 
          THIS IS AN AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
        </p>
      </li>

      <li>
        <h6>Termination</h6>
        <p>
          relativetome may terminate your access and use of the Service immediately at any time, for any reason, and at such time you will have no further right to use the Service. 
          You may terminate your relativetome account at any time by following the instructions available through the Service. 
          The provisions of these Terms and Conditions relating to the protection and enforcement of relativetome's proprietary rights, your representations and warranties, 
          disclaimer of representations and warranties, release and indemnities, limitations of liability and types of damages, ownership of data and information, governing law and venue, 
          and miscellaneous provisions shall survive any such termination.
        </p>
      </li>

      <li>
        <h6>Proprietary Rights in Content</h6>
        <ol type="a">
          <li>
            <p>
              relativetome does not claim any ownership rights in the contents of any text, information, files, images, photos, video, sounds, musical works, works of authorship, or any other materials that you post to the Service, 
              including without limitation, to your profile and to your family tree data (“Family Data”). After posting your Content to the Service, you continue to retain all ownership rights in such Content, and you continue to have 
              the right to use your Content in any way you choose. 
              
              By inviting other Users to share your Family Data or by displaying or publishing ("posting") any Content on or through the Service, you hereby grant, and you represent and warrant that 
              you have the right to do  so and to grant those Users a non-exclusive license to access your Content through the Service, and to use, reproduce, distribute, display and perform such Content as permitted 
              through the functionality of the Service, under this Agreement and your privacy settings.
            </p>
          </li>
          <li>
            <p>
              Except for your Content, the Service and all materials therein or transferred thereby, including, without limitation, software, images, text, graphics, illustrations, logos, patents, trademarks, service marks, 
              copyrights, photographs, audio, videos, and music, and all intellectual property rights related thereto, are the exclusive property of relativetome and its licensors. The Service Content is protected by copyright, 
              trademark, patent, trade secret and other laws, and relativetome owns or licenses and retains all rights in the Service Content and the Service. 
              relativetome hereby grants you a limited, revocable, nonsublicensable, non-transferable license to reproduce and display the Service Content (excluding any software code) solely for your personal non-commercial use in connection 
              with viewing the Service and using the Service in accordance with this Agreement.
            </p>
          </li>
          <li>
            <p>
              The Service contains Content of Users. Except for Content posted by you, or as expressly permitted by the privacy settings of a user, you may not copy, modify, translate, publish, broadcast, transmit, 
              distribute, perform, display, or sell any Content or Website Content appearing on or through the Website.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h6>Trademarks</h6>
        <p>
          “relativetome” and all other trademarks, service marks, graphics and logos used in connection with the Service are trademarks or service marks of relativetome or their respective owners, 
          and certain of them are registered with the United States Patent and Trademark Office. Access and use of the Service does not grant or provide you with the right or license to reproduce or 
          otherwise use the relativetome name or any relativetome or third-party trademarks, service marks, graphics or logos.
        </p>
      </li>

      <li>
        <h6>Privacy</h6>
        <p>
          Use of the Service is also governed by our Privacy Policy, a copy of which is located at <b-link :to="'/info/privacy'">relativetome.com/info/privacy</b-link>. By using the Service, you consent to the terms of the Privacy Policy
        </p>
      </li>

      <li>
        <h6>Governing Law</h6>
        <p>
          These Terms and Conditions, its subject matter and relativetome’s and your respective rights under these Terms and Conditions, as well as and any claim, cause of action or dispute (“claim”) arising out of 
          or related to these Terms and Conditions, shall be governed by and construed under the laws of the Province of British Columbia, Canada, excluding the conflict of law provisions of that or any other jurisdiction, 
          regardless of your country of origin or where you access the Service.
        </p>
      </li>

      <li>
        <h6>Language</h6>
        <p>
          This agreement was originally written in English (Canada). To the extent any translated version of this agreement conflicts with the English version, the English version controls.
        </p>
      </li>

      <li>
        <h6>Miscellaneous</h6>
        <p>
          These Terms and Conditions constitute the entire agreement between relativetome and you concerning the subject matter hereof. In the event that any of the Terms and Conditions are held by a 
          court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that these Terms and Conditions shall 
          otherwise remain in full force and effect. A waiver by relativetome or you of any provision of these Terms and Conditions or any breach thereof, in any one instance, will not waive such term 
          or condition or any subsequent breach thereof. relativetome may assign its rights or obligations under these Terms and Conditions without condition. These Terms and Conditions will be binding upon 
          and will inure to the benefit of relativetome and you, and relativetome’s and your respective successors and permitted assigns.
        </p>
      </li>
    </ol>

    <i>Last revised on January 21, 2021</i>
  </b-jumbotron>
</template>

<script>

export default {
  data() {
    return {}
  }
}
</script>