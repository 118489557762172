<template>
  <div class="footer">
    <b-navbar toggleable="sm" type="dark" variant="info">
      <b-navbar-nav>
        <b-nav-item :to="'/info/terms'">
          Terms
        </b-nav-item>
        <b-nav-item :to="'/info/privacy'">
          Privacy
        </b-nav-item>
        <b-nav-item :to="'/info/contactUs'">
          Contact Us
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script> 
  export default {
    components: {
    },
    data() {
      return {
      }
    },
    computed: {

    },
    methods: {
    }
  }
</script>