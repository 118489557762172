export default {
  author: null,
  familyData: {},
  familyAuthorityId: 1,
  familyDescription: null,
  familyId: null,
  familyName: '',
  individualsArray: [],
  individualSearchMap: null,
  isPublic: false,
  isLoading: true,
  sourceUrl: null,
  suppressGender: false
}