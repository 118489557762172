<template>
  <div class="info-couple info-item-striped">
    <info-fact-section :facts="facts" name="Details" />
    <div v-for="(spouse, index) in spouses" :key="index" :class="classWrapper(index)">
      <div :class="classLeft(index)" />
      <div class="info-couple-person">
        <info-individual :individual-id="spouse.id" />
      </div>
    </div>
  </div>
</template>

<script>
import InfoFactSection from "@/components/familyInfo/InfoFactSection"
import InfoIndividual from "@/components/familyInfo/InfoIndividual"

  export default {
    name: 'InfoFact',
    components: {
      InfoFactSection,
      InfoIndividual
    },
    props: {
      coupleIndex: {type: Number},
      spouses: {type: Array, required: true},
      facts: {type: Array, default: function () { return []}}
    },
    computed: {

    },
    methods: {
      classLeft: function (index) {
        return {
          lineLeft: index < this.spouses.length - 1,
          lineTop: true
        }
      },
      classWrapper: function (index) {
        return {
          "ancestor-wrapper": true,
          "info-last-spouse": index === this.spouses.length - 1
        }
      }
    }
  }
</script>