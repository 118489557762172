<template>
  <div :class="classAncestorGrid" :style="gridStyle()">
    <!-- Ancestor Portion.  It includes all but generation 0. -->
    <div v-for="(cell) in ancestryGrid" :key="cellKeyAncestor(cell.rowIndex, cell.columnIndex)" :style="cellStyle(cell.rowIndex, cell.columnIndex)">
      <AncestorCell v-if="cell.isRenderable" :ancestor-unit="cell.ancestorUnit" :is-sibling-area="false" />
    </div>

    <!-- Sibling Portion.  All will be generated in a single large cell -->
    <div :style="cellStyleSiblings()">
      <template v-for="(ancestorUnit, siblingIndex) in siblingGrid">
        <AncestorCell :ancestor-unit="ancestorUnit" :is-sibling-area="true" :key="siblingIndex" />
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import AncestorCell from "@/components/tree/AncestorCell"
  import AncestorFactory from "@/logic/family/AncestorFactory"
  
  export default {
    name: 'AncestorGrid',
    components: {
      AncestorCell
    },
    props: {
      individualId: {type: String, required: true}
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState('family', [
        'familyData'
      ]),
      ...mapState('viewState', [
        'maxAncestorGenerations'
      ]),
      ancestryGrid: function () {
        return Object.freeze(this.ancestorFactory.ancestryGrid)
      },
      ancestorFactory: function () {
        const ancestorFactory = new AncestorFactory(this.familyData, this.individualId, this.maxAncestorGenerations)
        return Object.freeze(ancestorFactory)
      },
      classAncestorGrid: function () {
        return {
          "ancestor-grid": true
        }
      },
      siblingGrid: function () {
        return Object.freeze(this.ancestorFactory.siblingGrid)
      },
    },
    methods: {
      cellKeyAncestor: function (rowIndex, columnIndex) {
        return `${rowIndex}Φ${columnIndex}`
      },
      cellStyle: function (rowIndex, columnIndex) {
        return {
          'grid-area': `${rowIndex + 1} / ${columnIndex + 1}`
        }
      },
      cellStyleSiblings: function () {
        const af = this.ancestorFactory

        // We add 1000 to sibling area to make room as this area may be larger than the max row.
        // TODO: Temporary fix because vue cannot set complex styles. See note below
        return {
          'grid-row': `${af.firstSiblingRow + 1} / ${af.maxRow + 2 + 1000}`, 
          'grid-column': af.maxColumn + 2  
        }
      },
      gridStyle: function () {
        // TODO: This will not work. Vue is validating the values for the style and can't handle complex values
        //var af = this.ancestorFactory
        //return {
        //  'grid-template-columns': `repeat(${af.generations.length - 1}, 300px) auto-fill`,
        //  'grid-template-rows': `repeat(${af.maxRow}, auto) auto-fill`
        //}

        return {}
      }
    }
  }
</script>