import { getLabel } from '@/logic/culture/cultureUtils'

// Page sizes in points
// https://www.prepressure.com/library/paper-size
export const paperSizes = {
  'a0': {h: 3370, w: 2384, imperial: '33.11" x 46.81"', metric: '841mm x 1189mm', useName: true, displayName: 'A0'},
  'a1': {h: 2384, w: 1684, imperial: '23.39" x 33.11"', metric: '594mm x 841mm',  useName: true, displayName: 'A1'},
  'a2': {h: 1684, w: 1190, imperial: '16.54" x 23.39"', metric: '420mm x 594mm',  useName: true, displayName: 'A2'},
  'a3': {h: 1190, w: 842,  imperial: '11.69" x 16.54"', metric: '297mm x 420mm',  useName: true, displayName: 'A3'},
  'a4': {h: 842, w: 595,   imperial: '8.27" x 11.69"',  metric: '210mm x 297mm',  useName: true, displayName: 'A4'},

  'b0': {h: 4008, w: 2835, imperial: '39.37" x 55.67"', metric: '1000mm x 1414mm', useName: true, displayName: 'B0'},
  'b1': {h: 2835, w: 2004, imperial: '27.83" x 39.37"', metric: '707mm x 1000mm',  useName: true, displayName: 'B1'},
  'b2': {h: 2004, w: 1417, imperial: '19.69" x 27.83"', metric: '500mm x 707mm',   useName: true, displayName: 'B2'},
  'b3': {h: 1417, w: 1001, imperial: '13.90" x 19.69"', metric: '353mm x 500mm',   useName: true, displayName: 'B3'},
  'b4': {h: 1001, w: 709,  imperial: '9.84" x 13.90"',  metric: '250mm x 353mm',   useName: true, displayName: 'B4'},

  'legal': {h: 1008, w: 612,  imperial: '8.5" x 14"', metric: '215.9mm x 355.6mm', useName: true},
  'letter': {h: 792, w: 612,  imperial: '8.5" x 11"', metric: '215.9mm x 279.4mm', useName: true},
  'ledger': {h: 1224, w: 792, imperial: '11" x 17"',  metric: '279.4mm x 431.8mm', useName: true},
  'ANSIC': {h: 1584, w: 1224, imperial: '17" x 22"',  metric: '432mm x 559mm',     useName: false, displayName: 'ANSI C'},
  'ANSID': {h: 2448, w: 1584, imperial: '22" x 34"',  metric: '559mm x 864mm',     useName: false, displayName: 'ANSI D'},
  'ANSIE': {h: 3168, w: 2448, imperial: '34" x 44"',  metric: '864mm x 1118mm',    useName: false, displayName: 'ANSI E'},
}

export function getPaperDimensions (paperType, layout) {
  const dim = paperSizes[paperType]
  return layout === 'p' ? { height: dim.h, width: dim.w} : { height: dim.w, width: dim.h} 
}

export function paperSizesSorted(language) {
  const ps = []

  for (const paperSize in paperSizes) {
    const details = paperSizes[paperSize]
    const translated = details.displayName ? details.displayName : getLabel(paperSize,language)
    ps.push({
      name: translated === 'Unknown' ? paperSize : translated,
      value: paperSize,
      imperial: details.imperial,
      metric: details.metric
    })
  }

  return ps.sort(function(a,b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    else return 0
  })
}