<template>
  <b-modal id="infoModal"
           size="lg"
           hide-header
           @show="onShow">
    <div v-if="!isLoading && hasTarget">
      <div v-if="isHeader">
        <div class="individual-name-info">{{ familyName }}</div>
        <table class="info-header">
          <tr v-if="familyDescription">
            <td class="info-family-description" colspan="2" v-html="familyDescriptionHtml" />
          </tr>
          <tr v-if="author">
            <td>Author:</td>
            <td>{{ author }}</td>
          </tr>
          <tr v-if="sourceUrl">
            <td>Source:</td>
            <td class="long-url"><b-link :href="sourceUrl">{{ sourceUrl }}</b-link></td>
          </tr>
        </table>
      </div>

      <div v-if="isIndividual">
        <InfoIndividual :individual-id="entity.id" :hide-detail-button="true" />
      </div>

      <fieldset v-if="showUnionSpouses" class="info-section border">
        <legend class="w-auto" v-html="coupleTitle" />
        <info-couple :spouses="unionSpouses" />
      </fieldset>

      <b-card no-body class="info-tab">
        <b-tabs v-model="tabIndex" card pills>
          <b-tab title="Particulars">
            <info-fact-section :facts="facts.generalFacts" name="General" :citation-number="facts.primaryFact.citationNumber" />
            <info-fact-section :facts="facts.eventFacts" name="Events" />
            <info-fact-section :facts="facts.ldsEventFacts" name="LDS Events" />
            <info-fact-section :facts="facts.attributeFacts" name="Attributes" />
            <info-fact-section :facts="facts.noteFacts" name="Notes" />
            <info-fact-section :facts="facts.otherFacts" name="Other" />
            <info-citation-section :citations="facts.citations" />
            <div v-if="!hasFacts" class="no-facts">No Particulars Available</div>
          </b-tab>

          <b-tab title="Images" v-if="hasDisplayableImages">
            <b-carousel
              :interval="4000"
              controls
              indicators>
              <b-carousel-slide v-for="(image,index) in displayableImages" 
                                :key="`image-${index}`"
                                :caption="image.title"
                                :img-src="image.url" />
            </b-carousel>
          </b-tab>

          <b-tab title="Family" v-if="showFamily">
            <fieldset v-if="showIndividualParents" class="info-section border">
              <legend class="w-auto">Parents</legend>
              <info-couple v-for="(parents, index) in individualFamilyParents"
                           :key="parents.pointer"
                           :couple-index="index"
                           :spouses="parents.spouses"
                           :facts="familyUnionFacts(parents)" />
            </fieldset>
            <fieldset v-if="showIndividualUnions" class="info-section border">
              <legend class="w-auto">Unions</legend>
              <ul class="union-list">
                <li v-for="familyRelationship in individualFamilyUnions" :key="familyRelationship.pointer" class="info-entity-list">
                  <info-individual v-if="familyRelationship.union.spouse" :individual-id="familyRelationship.union.spouse.id" :union-id="familyRelationship.union.id" />
                  <div v-if="!familyRelationship.union.spouse" class="unknown-spouse">Unknown</div>
                  <info-kid-list :union="familyRelationship.union" />
                </li>
              </ul>
            </fieldset>
            <fieldset v-if="showUnionChildren" class="info-section border">
              <legend class="w-auto">Children</legend>
              <info-kid-list :union="unionChildren" />
            </fieldset>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-button size="sm" class="float-right" @click="close()">
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import marked from 'marked'
  import FamilyFactFactory from '@/logic/family/FamilyFactFactory'
  import InfoCitationSection from "@/components/familyInfo/InfoCitationSection"
  import InfoCouple from "@/components/familyInfo/InfoCouple"
  import InfoFactSection from "@/components/familyInfo/InfoFactSection"
  import InfoIndividual from "@/components/familyInfo/InfoIndividual"
  import InfoKidList from "@/components/familyInfo/InfoKidList"
  import * as familyUtils from '@/logic/family/familyUtils'
  import * as individualUtils from '@/logic/family/individualUtils'
  
  export default {
    name: 'InfoModal',
    components: {
      InfoCitationSection,
      InfoCouple,
      InfoFactSection,
      InfoIndividual,
      InfoKidList
    },
    props: {
    },
    data() {
      return {
        tabIndex: 0
      }
    },
    computed: {
      ...mapGetters('family', [
        'canViewSensitive'
      ]),
      ...mapState('family', [
        'familyData',
        'familyDescription',
        'familyName',
        'author',
        'sourceUrl',
        'isLoading'
      ]),
      ...mapState('viewState', [
        'infoModalEntityId',
        'infoModalEntityType',
        'language'
      ]),
      coupleTitle: function () {
        const citationNumber = this.facts.primaryFact.citationNumber
        const title = 'Couple'
        return citationNumber ? `${title}<sup>${citationNumber}</sup>` : title
      },
      displayableImages: function () {
        return this.facts.displayableImages
      },
      facts: function () {
        switch (this.infoModalEntityType)
        {
          case 'header':
            return this.familyFactFactory.buildHeaderFacts(this.entity)
          case 'individual':
            return this.familyFactFactory.buildIndividualFacts(this.entity)
          case 'union':
            return this.familyFactFactory.buildUnionFacts(this.entity)
          default: 
            throw `Unsupported infoModalEntityType: ${this.infoModalEntityType}`
        }
      },
      familyDescriptionHtml: function () {
        return marked.parseInline(this.familyDescription || '')
      },
      familyFactFactory: function () {
        const familyFactFactory = new FamilyFactFactory(this.familyData, this.language, this.canViewSensitive)
        return Object.freeze(familyFactFactory)
      },
      hasDisplayableImages: function () {
        return this.displayableImages.length > 0
      },
      hasFacts: function () {
        const f = this.facts
        return (f.generalFacts.length + f.eventFacts.length + f.ldsEventFacts.length + f.attributeFacts.length + f.noteFacts.length + f.otherFacts.length + f.citations.length) > 0
      },
      hasTarget: function () {
        return !!this.infoModalEntityId || !!this.infoModalEntityType
      },
      individualFamilyUnions: function () {
        return this.isIndividual ? familyUtils.getFamilyUnions(this.familyData, this.entity) : []
      },
      individualFamilyParents: function () {
        return this.isIndividual ? familyUtils.getFamilyParents(this.familyData, this.entity) : []
      },
      isHeader: function () {
        return this.infoModalEntityType === 'header'
      },
      isIndividual: function () {
        return this.infoModalEntityType === 'individual'
      },
      isUnion: function () {
        return this.infoModalEntityType === 'union'
      },
      entity: function () {
        switch (this.infoModalEntityType)
        {
          case 'header':
            return this.familyData.header
          case 'individual':
            return individualUtils.getIndividual(this.familyData, this.infoModalEntityId)
          case 'union':
            return this.familyData.families[this.infoModalEntityId]
          default: 
            throw `Unsupported infoModalEntityType: ${this.infoModalEntityType}`
        }
      },
      showFamily: function () {
        return this.showIndividualParents || this.showIndividualUnions || this.showUnionChildren
      },
      showIndividualParents: function () {
        return this.isIndividual && this.individualFamilyParents.length > 0
      },
      showIndividualUnions: function () {
        return this.isIndividual && this.individualFamilyUnions.length > 0
      },
      showUnionChildren: function () {
        return this.isUnion && this.unionChildren.kids.length > 0
      },
      showUnionSpouses: function () {
        return this.isUnion && this.unionSpouses.length > 0
      },
      unionSpouses: function ()  {
        return this.isUnion ? familyUtils.getSpouses(this.familyData, this.entity) : []
      },
      unionChildren: function () {
        return this.isUnion ? familyUtils.enhanceUnion(this.familyData, this.infoModalEntityId) : {}
      }
    },
    methods: {
      familyUnionFacts: function (familyRelationship) {
        return this.familyFactFactory.buildFamilyRelationshipFacts(familyRelationship)
      },
      onShow: function () {
        this.tabIndex = 0
        console.log(`Modal: Info Type:${this.infoModalEntityType} Id:${this.infoModalEntityId}`)
      }
    },
    watch: {
      infoModalEntityId: function () {
        this.tabIndex = 0
      },
      infoModalEntityType: function () {
        this.tabIndex = 0
      }
    }
  }
</script>
