<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <b-card v-if="accountProvider !== 0"
              bg-variant="light"
              title="Note"
              tag="article"
              class="mb-2">
        <b-card-text>
          This will only change your relativetome.com password.  It does not change your {{ accountProviderText }} password.
        </b-card-text>
      </b-card>
      <input id="dummyUserName" type="text" style="display:none;" autocomplete="username">
      <validation-provider vid="newPassword" name="New Password" :rules="{required: true, regex: passwordRegex }" v-slot="validationContext">
        <b-form-group
          class="form-group-in-table"
          label-cols-sm="3"
          label="New Password:"
          label-align-sm="right"
          label-for="newPassword-box">
          <b-form-input id="newPassword-box"
                        type="password"
                        v-model="form.newPassword"
                        :state="getValidationState(validationContext)"
                        autocomplete="new-password"
                        tabindex="1" />
          <b-form-invalid-feedback id="newPassword-feedback">{{ passwordText }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Confirm New Password" :rules="{required: true, confirmed:'newPassword' }" v-slot="validationContext">
        <b-form-group
          class="form-group-in-table"
          label-cols-sm="3"
          label="Confirm New Password:"
          label-align-sm="right"
          label-for="confirmNewPassword-box">
          <b-form-input id="confirmNewPassword-box"
                        type="password"
                        v-model="form.confirmNewPassword"
                        :state="getValidationState(validationContext)"
                        autocomplete="new-password"
                        tabindex="1" />
          <b-form-invalid-feedback id="confirmNewPassword-feedback">The new passwords must match.</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider vid="password" name="Password" :rules="{required: true }" v-slot="validationContext">
        <b-form-group
          class="form-group-in-table"
          label-cols-sm="3"
          label="Re-enter Old Password:"
          label-align-sm="right"
          label-for="password-box">
          <b-form-input id="password-box"
                        type="password"
                        v-model="form.password"
                        :state="getValidationState(validationContext)"
                        autocomplete="new-password"
                        tabindex="4" />
          <b-form-invalid-feedback id="password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="d-flex justify-content-end">
        <b-button type="button" variant="outline-secondary" tabindex="12" @click="resetPage()">Cancel</b-button>
        <b-button :disabled="postState.inProgress"
                  type="submit"
                  variant="primary"
                  tabindex="11"
                  class="ml-2">
          Submit
        </b-button>
      </div>
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"
  import staticConfig from '@/staticConfig'

  export default {
    name: 'EditPassword',
    components: {
      ApiPostFeedback
    },
    data() {
      return {
        form: {
          confirmNewPassword: "",
          newPassword: "",
          password: ""
        },
        passwordRegex: staticConfig.passwordRegex,
        passwordText: staticConfig.passwordText,
        postState: {}
      }
    },
    computed: {
      ...mapGetters('authentication', [
        'accountProvider',
        'accountProviderText'
      ]),
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()
        this.axios.post(apiUtils.buildAPIUrl('account/passwordUpdate'), this.form)
          .then(response => {
            this.postState = response.postState
            this.resetForm()
            this.resetPage()
            this.$auth.setToken(response.data)
            this.$store.dispatch('authentication/signalNewAccessToken', this)
          })
          .catch(error => {
            this.postState = error.postState
            this.form.password = ""
            validationUtils.resetFormDelayed(this)
          })
      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          confirmNewPassword: "",
          newPassword: "",
          password: ""
        }
        validationUtils.resetFormImmediate(this)
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>