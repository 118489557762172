export function determineStorageLocation (stayLoggedIn) {
  return stayLoggedIn ? 'localStorage' : 'cookieStorage'

  // Note:  cookieStorage is used as it allows the same session to span multiple tabs.  While, in a SPA, this is not normally important, when you are 
  //        verifying the email, it is likely that another tab will be opened.  The system allows for login; however, it is nice if they are already logged in on 
  //        another tab to not have to log in again.
}

export function determineCookieDomain() {
  return process.env.VUE_APP_COOKIE_DOMAIN
}

export function determineCookieSecure () {
  return location.protocol === 'https:' ? true : false
}

export function isAuthenticated (jwt) {
  if (jwt && jwt.exp && typeof jwt.exp === 'number') {
    return Math.round(new Date().getTime() / 1000) < jwt.exp
  }

  return false
}

export function checkRouteLoggedIn (vm, next) {
    if (vm.isLoggedIn) {
      next() 
      
    } else {
      const message = `Attempted to display the route ${vm.$route.fullPath}; however, was not logged in.`
      console.log(message)

      // Analytics
      vm.$gtag.exception({
        'description': message,
        'fatal': false
      })

      next('/error/authorization')
    }
}

export function checkRouteSiteAdministrator (vm, next) {
  if (vm.isLoggedIn && vm.isSiteAdministrator) {
    next() 
    
  } else {
    const message = `Attempted to display the route ${vm.$route.fullPath}; however, was not a site administrator.`
    console.log(message)

    // Analytics
    vm.$gtag.exception({
      'description': message,
      'fatal': false
    })

    next('/error/authorization')
  }
}