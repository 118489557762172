<template>
  <b-jumbotron header="Routing Error" :lead="lead" />
</template>

<script>
export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const message = `Route Error: ${to.fullPath}`
      vm.$gtag.exception({
            'description': message,
            'fatal': false
          })
    })
  },
  computed: {
    lead: function () {
      return `The route ${this.$route.fullPath} is not valid.`
    }
  }
}
</script>