<template>
  <b-modal v-model="showModal" size="md" hide-header>
    <div class="px-2 pt-2">
      <p>You have received one or more new invitations to view a private family. Any invitations that you do not accept at this time can be viewed under "Account Actions".</p>
      <AcceptInvitations :show-viewed="false" @no-more-invitations="onNoMoreInvitations()" />
    </div>
    <template #modal-footer="{ }">
      <div class="w-100">
        <b-button size="sm" class="float-right" @click="onClose()">
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import * as apiUtils from '@/logic/general/apiUtils'
import { mapGetters } from 'vuex'
import AcceptInvitations from '@/components/familyAdmin/AcceptInvitations.vue'

export default {
  name: 'NewInvitationsModal',
  components: {
    AcceptInvitations
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedInFully'
    ])
  },
  methods: {
    load: function () {
      this.axios.get(apiUtils.buildAPIUrl(`familyInvitation/status`))
        .then(response => {
          if (response.data.availableInvitationCount > 0) {
            this.showModal = true
          } 
        })
    },
    onClose: function () {
      this.showModal = false
    },
    onNoMoreInvitations: function () {
      this.showModal = false
    },
  },
  watch: {
    isLoggedInFully: function (newValue, oldValue) {
      if (oldValue === false && newValue === true){
        this.load()
      }
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.load()
    }
  }
}
</script>
