<template>
  <b-card no-body>
    <b-tabs card pills>
      <b-tab title="General">
        <h4>General Account Settings</h4>
        <b-table-simple hover stacked="md" class="narrow-container-2">
          <b-tbody>
            <b-tr :class="getInfoTableRowClass(1)">
              <b-th class="text-nowrap">Name<b-badge v-if="hasRelativeToMeLogin && mode!==1" @click="setMode(1)" class="action-icon" variant="primary"><b-icon icon="pencil" />Edit</b-badge></b-th>
              <b-td>
                <span v-if="mode!==1">{{ userInfo.fullName }}</span>
                <EditUserName v-if="mode===1" @force-reset="reset()" :initial-family-name="userInfo.familyName" :initial-given-name="userInfo.givenName" />
              </b-td>
            </b-tr>
            <b-tr :class="getInfoTableRowClass(2)">
              <b-th class="text-nowrap">Email<b-badge v-if="hasRelativeToMeLogin && mode!==2" @click="setMode(2)" class="action-icon" variant="primary"><b-icon icon="pencil" />Edit</b-badge></b-th>
              <b-td>
                <span v-if="mode!==2">{{ userInfo.email }}</span>
                <EditEmail v-if="mode===2" @force-reset="reset()" :initial-email="userInfo.email" />
              </b-td>
            </b-tr>
            <b-tr v-if="hasRelativeToMeLogin" :class="getInfoTableRowClass(3)">
              <b-th class="text-nowrap">Password<b-badge v-if="mode!==3" @click="setMode(3)" class="action-icon" variant="primary"><b-icon icon="pencil" />Edit</b-badge></b-th>
              <b-td>
                <EditPassword v-if="mode===3" @force-reset="reset()" />
              </b-td>
            </b-tr>
            <b-tr v-if="!hasRelativeToMeLogin" :class="getInfoTableRowClass(4)">
              <b-th class="text-nowrap">Password<b-badge v-if="mode!==4" @click="setMode(4)" class="action-icon" variant="primary"><b-icon icon="pencil" />Add</b-badge></b-th>
              <b-td>
                <AddPassword v-if="mode===4" @force-reset="reset()" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tab>

      <b-tab title="Email Settings">
        <h4>Email Settings<b-badge v-if="mode!==21" @click="setMode(21)" class="action-icon" variant="primary"><b-icon icon="pencil" />Edit</b-badge></h4>
        <b-table-simple hover stacked="md" class="narrow-container-0">
          <b-tbody>
            <b-tr>
              <b-td>
                <b-table-simple v-if="mode!==21"
                                borderless
                                class="narrow-container-2"
                                hover
                                stacked="md">
                  <b-tbody>
                    <b-tr>
                      <b-th class="text-nowrap">Receive promotions and offers</b-th>
                      <b-td v-text="getEmailPreference(userInfo.emailPreferences.receiveOffers)" class="text-center" />
                    </b-tr>
                    <b-tr>
                      <b-th class="text-nowrap">Receive product information</b-th>
                      <b-td v-text="getEmailPreference(userInfo.emailPreferences.receiveProductInformation)" class="text-center" />
                    </b-tr>
                    <b-tr>
                      <b-th class="text-nowrap">Receive invitations to join families.</b-th>
                      <b-td v-text="getEmailPreference(userInfo.emailPreferences.receiveInvitations)" class="text-center" />
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-td>
            </b-tr>
            <b-tr v-if="mode===21" :class="getInfoTableRowClass(21)">
              <b-td>
                <EditEmailSettings v-if="mode===21" @force-reset="reset()" :initial-state="userInfo.emailPreferences" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tab>

      <b-tab title="Subscription">
        <h4>Subscription Status<b-badge v-if="mode!==31" @click="setMode(31)" class="action-icon" variant="primary"><b-icon icon="pencil" />Edit</b-badge></h4>
        <b-table-simple hover stacked="md" class="narrow-container-2">
          <b-tbody>
            <b-tr :class="getInfoTableRowClass(31)">
              <b-td>
                <span v-if="mode!==31">This feature is not yet available</span>
                <EditSubscription v-if="mode===31" @force-reset="reset()" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tab>

      <b-tab title="Logins">
        <h4>Where You're Logged In</h4>
        <b-table :busy="busy"
                 :items="userInfo.refreshTokens"
                 :fields="refreshTokenFields"
                 hover
                 responsive
                 show-empty
                 stacked="md"
                 striped>
          <template #cell(actions)="row">
            <span v-if="row.item.isCurrent" class="text-success">Current</span>
            <b-button v-else
                      @click="forceLogoff(row.item.refreshTokenId)"
                      class="action-icon"
                      variant="primary"
                      size="sm">
              <b-icon icon="box-arrow-left" />Logoff
            </b-button>
          </template>
          <template #table-busy>
            <TableBusy />
          </template>
        </b-table>
      </b-tab>

      <b-tab title="Account Deletion">
        <h4>Account Deletion</h4>
        <b-table-simple hover stacked="md" class="narrow-container-2">
          <b-tbody>
            <b-tr :class="getInfoTableRowClass(11)">
              <b-th class="text-nowrap">Permanently Delete Account<b-badge v-if="mode!==11" @click="setMode(11)" class="action-icon" variant="primary"><b-icon icon="person-x" />Delete</b-badge></b-th>
              <b-td>
                <EditDeleteAccount v-if="mode===11" @force-reset="reset()" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { checkRouteLoggedIn } from '@/logic/general/authenticationUtils'
import * as apiUtils from '@/logic/general/apiUtils'
import TableBusy from "@/components/TableBusy"
import AddPassword from "@/components/security/AddPassword"
import EditDeleteAccount from "@/components/security/EditDeleteAccount"
import EditEmail from "@/components/security/EditEmail"
import EditEmailSettings from "@/components/email/EditEmailSettings"
import EditPassword from "@/components/security/EditPassword"
import EditSubscription from "@/components/security/EditSubscription"
import EditUserName from "@/components/security/EditUserName"

export default {
  components: {
    AddPassword,
    EditDeleteAccount,
    EditEmail,
    EditEmailSettings,
    EditPassword,
    EditSubscription,
    EditUserName,
    TableBusy
  },
  data() {
    return {
      busy: false,
      mode: 0,
      refreshTokenFields: [
        {key: 'actions', tdClass: 'info-table-action'},
        {key: 'os', label: 'Operating System'},
        {key: 'ua', label: 'Browser'},
        {key: 'device', label: 'Device'},
        {key: 'ip', label: 'IP Address'},
        {key: 'timeDifferenceText', label: 'Last Activity'}
      ],
      userInfo: {
        email: "Loading ...",
        familyName: "",
        fullName: "Loading ...",
        givenName: "",
        emailPreferences: {},
        refreshTokens: []
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => checkRouteLoggedIn(vm, next))
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn'
    ]),
    ...mapState('authentication', [
      'jwt'
    ]),
    hasRelativeToMeLogin: function () {
      return this.jwt && this.jwt.xla === true
    }
  },
  methods: {
    forceLogoff: function (refreshTokenId) {
      const data = {UserRefreshTokenId: refreshTokenId}
      this.axios.post(apiUtils.buildAPIUrl('account/logoutOther'),data)
        .finally(() => {
          this.reset()       // Will refresh whether it has succeeded or not just incase it has been updated elsewhere.   
        })
    },
    getEmailPreference: function (isSelected) {
      return isSelected ? "Yes" : "-"
    },
    getInfoTableRowClass: function (mode){
      return { 'embedded-form':this.mode===mode }
    },
    reset: function () {
      this.busy = true
      this.mode = 0
      this.axios.get(apiUtils.buildAPIUrl('account/userInfo'))
          .then(response => {
            this.userInfo = response.data     
            this.busy = false      
          })
    },
    setMode: function(mode) {
      this.mode = mode
    }
  },
  created() {
    this.reset()
  },
  watch: {
    isLoggedIn: function (newValue) {
      if (newValue === false) {
        this.$router.push({ path: `/` })
      }
    }
  }
}
</script>