<template>
  <b-modal id="authenticationModal" size="lg" hide-header @hidden="onModalHidden">
    <Login v-if="displayMode==='login'" @set-display-mode="onSetDisplayMode" />
    <PasswordForgot v-if="displayMode==='forgot'" @set-display-mode="onSetDisplayMode" />
    <Register v-if="displayMode==='register'" @set-display-mode="onSetDisplayMode" />

    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-button size="sm" class="float-right" @click="close()">
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import Login from "@/components/security/Login"
  import PasswordForgot from "@/components/security/PasswordForgot"
  import Register from "@/components/security/Register"

  export default {
    name: 'AuthenticationModal',
    components: {
      Login,
      PasswordForgot,
      Register
    },
    props: {
    },
    data() {
      return {
        displayMode: 'login',
      }
    },
    methods: {
      onModalHidden: function () {
        this.displayMode = 'login'
      },
      onSetDisplayMode: function(newMode) {
        this.displayMode = newMode
      }
    }
  }
</script>