export default {
  clearAccessToken (state) {
    state.forceAuthenticationReactivity++
    state.jwt = null
  },
  clearTimers (state) {
    if (state.expiryTimer) {
      clearTimeout(state.expiryTimer)
      state.expiryTimer = null
    }

    if (state.refreshTimer) {
      clearTimeout(state.refreshTimer)
      state.refreshTimer = null
    }
  },
  incrementVersion (state) {
    state.forceAuthenticationReactivity++
  },
  setAccessToken (state, jwt) {
    state.forceAuthenticationReactivity++
    state.jwt = jwt
  },
  setExpiryTimer (state, expiryTimer) {
    clearTimeout(state.expiryTimer)
    state.expiryTimer = expiryTimer
  },
  setRefreshTimer (state, refreshTimer) {
    clearTimeout(state.refreshTimer)
    state.refreshTimer = refreshTimer
  }
}