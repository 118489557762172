import * as authenticationUtils from '@/logic/general/authenticationUtils'
import { accountProviderText } from '@/logic/enums'

// To be fully authenticated the token must exist and not be expired and the email must be verified 
// To be partially authenticated only the token must exist and not be expired.
export default {
  accountProvider: (state) => {
    return state.jwt["provider"]
  },
  accountProviderText: (state) => {
    return accountProviderText(state.jwt["provider"])
  },
  isLoggedIn: (state) => {
    state.forceAuthenticationReactivity  // Used to force reactivity. See /store/authentication/state.js
    return authenticationUtils.isAuthenticated(state.jwt)
  },
  isLoggedInFully: (state) => {
    state.forceAuthenticationReactivity  // Used to force reactivity. See /store/authentication/state.js
    return authenticationUtils.isAuthenticated(state.jwt) && state.jwt.email_verified 
  },
  isLoggedInUnverified: (state) => {
    state.forceAuthenticationReactivity  // Used to force reactivity. See /store/authentication/state.js
    return authenticationUtils.isAuthenticated(state.jwt) && !(state.jwt.email_verified || false)
  },
  isSiteAdministrator: (state) => {
    state.forceAuthenticationReactivity  // Used to force reactivity. See /store/authentication/state.js
    
    if (authenticationUtils.isAuthenticated(state.jwt)) {
      const role = state.jwt.role

      if (role) {
        if (Array.isArray(role)) {
          return role.includes('Admin')
        } else {
          return role === 'Admin'
        }
      }
    } 
      
    return false
  },
  hasSubscription: (state) => {
    state.forceAuthenticationReactivity  // Used to force reactivity. See /store/authentication/state.js

    if (state.jwt["subscription"]) {
      const expiry = state.jwt["subscription"] / 1000
      return Date.now() > expiry
    }

    return false;
  }
}
