import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { getPaperDimensions, paperSizes } from '@/logic/file/pageSizes'
import canvasSize from 'canvas-size'

// Constants
const marginMM = 10 
const cutGuideLengthPTs = 8
const topOfPageAdjustment = 10 // This number removes some of the extra padding at the top of the page.

// With much thanks to https://stackoverflow.com/a/55965338/1864995
export function generateFile (elementId, individualName, fileType, layout, paperType, scaling, printCutGuides) {
  return new Promise((resolve, reject) => {
    const paper = getPaperDimensions(paperType,layout)
    const marginPt = Math.round(marginMM * 72 / 25.4)
    const paperImageMaxHeight= getPixelDimension(paper.height, marginPt) 
    const paperImageMaxWidth = getPixelDimension(paper.width, marginPt) 
    const element = document.getElementById(elementId)       
    const options = {
      imageTimeout: 0,
      scale: scaling,
      x: 0,
      y: getTopPx(element),
      scrollX: 0,
      scrollY: 0,
      height: element.scrollHeight,
      width: element.scrollWidth
    }

    // Test to see if we have exceeded the canvas size maximums for this browser
    const isValidCanvas = canvasSize.test({
      width : element.scrollWidth,
      height: element.scrollHeight
    });

    if (!isValidCanvas) {
      reject("The size of the family tree is too large to save.  Try using Firefox or reduce the number of generations.")
      console.log(`Canvas too large: height:${element.scrollHeight} width:${element.scrollWidth}`)
      return
    }
    
    html2canvas(element, options).then((canvas) => {
      // Go to this site to view the image: https://codebeautify.org/base64-to-image-converter
      const test1 = canvas.toDataURL("image/png")  // eslint-disable-line no-unused-vars

      if (fileType === 'image') {
        const link = document.createElement('a')
        link.download = `Family Tree - ${individualName}.png`
        link.href = canvas.toDataURL("image/png")
        link.dataset.downloadurl = ["image/png", link.download, link.href].join(':')

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

      } if (fileType === 'pdf') {
        const dimensions = paperSizes[paperType].useName ? paperType : [paper.width, paper.height]
        const pdf = new jsPDF(layout, 'pt', dimensions)
        let sourceX = 0
        let sourceY = Math.round(topOfPageAdjustment * scaling)  // The space on the first page must be scaled
        let isPage1 = true
      
        while (sourceX < canvas.width) {
          while (sourceY < canvas.height) {
            const subCanvas = document.createElement('canvas')
            const context = subCanvas.getContext('2d')
            const imageHeight = canvas.height - sourceY > paperImageMaxHeight ? paperImageMaxHeight : canvas.height - sourceY
            const imageWidth = canvas.width - sourceX > paperImageMaxWidth ? paperImageMaxWidth : canvas.width - sourceX
      
            subCanvas.height = imageHeight
            subCanvas.width = imageWidth 
            context.drawImage(canvas, sourceX, sourceY, imageWidth, imageHeight, 0, 0, imageWidth, imageHeight)
            
            // Go to this site to view the image: https://codebeautify.org/base64-to-image-converter
            //const test2 = subCanvas.toDataURL("image/png")  // eslint-disable-line no-unused-vars
      
            // Add the page except on page 1
            if (isPage1) {
              isPage1 = false
            } else {
              pdf.addPage();
            }
      
            const imageHeightPts = Math.ceil(imageHeight * .75)
            const imageWidthPts = Math.ceil(imageWidth * .75)
            pdf.addImage(subCanvas, 'PNG', marginPt, marginPt, imageWidthPts, imageHeightPts);
      
            // Draw cut guides
            if (printCutGuides) {
              drawCutGuides(pdf, marginPt, cutGuideLengthPTs, imageWidthPts, imageHeightPts)
            }
      
            sourceY += paperImageMaxHeight
          }
      
          sourceY = topOfPageAdjustment
          sourceX += paperImageMaxWidth
        }
      
        pdf.save(`Family Tree - ${individualName}.pdf`);
      }

      resolve()
    })
  })
}

function drawCutGuides(pdf, marginPt, cutGuideLengthPTs, imageWidthPts, imageHeightPts) {
  pdf.line(marginPt - cutGuideLengthPTs, marginPt, marginPt, marginPt, 'S')  // Top left horizontal
  pdf.line(marginPt, marginPt - cutGuideLengthPTs, marginPt, marginPt, 'S')  // Top left vertical
  pdf.line(marginPt + imageWidthPts, marginPt, marginPt + imageWidthPts + cutGuideLengthPTs, marginPt,'S')  // Top right horizontal
  pdf.line(marginPt + imageWidthPts, marginPt - cutGuideLengthPTs, marginPt + imageWidthPts, marginPt,'S')  // Top right vertical
  pdf.line(marginPt - cutGuideLengthPTs, imageHeightPts + marginPt, marginPt, imageHeightPts + marginPt, 'S')  // Bottom left horizontal
  pdf.line(marginPt, imageHeightPts + marginPt, marginPt, imageHeightPts + marginPt + cutGuideLengthPTs, 'S')  // Bottom left vertical
  pdf.line(marginPt + imageWidthPts, imageHeightPts + marginPt, marginPt + imageWidthPts + cutGuideLengthPTs, imageHeightPts + marginPt, 'S')  // Bottom right horizontal
  pdf.line(marginPt + imageWidthPts, imageHeightPts + marginPt, marginPt + imageWidthPts, imageHeightPts + marginPt + cutGuideLengthPTs, 'S')  // Bottom right vertical
}

function getPixelDimension (pts, marginPt) {
  return Math.round((pts - (2 * marginPt)) * (4/3))
}

function getTopPx (element) {
  const styles = element.style.cssText

  if (styles) {
    const matches = styles.match(/top:\s*(\d+)px;?/i) 

    if (matches.length >= 2) {
      return parseInt(matches[1])
    }
  }

  return 0
}