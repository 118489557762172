<template>
  <div class="px-2 pt-2">
    <h4>New Private Family<b-badge v-if="mode!==1" @click="setMode(1)" class="action-icon" variant="primary"><b-icon icon="upload" />Import</b-badge></h4>
    <div :class="getInfoTableRowClass(1)">
      <ImportNewFamily v-if="mode===1" @force-reset="reset()" />
    </div>

    <div class="d-flex flex-wrap justify-content-between">
      <div>
        <h4>
          Manage Existing Private Families
        </h4>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="rows"
          aria-controls="my-table"
          align="right" />
      </div>
      <div>
        <b-form-group
          class="narrow-container-0"
          label-cols-sm="4"
          label="Name Filter:"
          label-align-sm="right"
          label-for="individuals-filter-box">
          <b-form-input id="individuals-filter-box" 
                        type="text" 
                        v-model="filter" 
                        tabindex="1" />
        </b-form-group>
      </div>
    </div>

    <b-table :current-page="currentPage"
             :busy="busy"
             :filter="filter"
             :items="families"
             :fields="familyFields"
             :filter-included-fields="filterOn"
             :per-page="perPage"
             @filtered="onFilterChange"
             empty-filtered-text="No families match the filter"
             responsive
             show-empty
             stacked="md"
             striped>
      <template #cell(familyName)="row">
        {{ row.item.familyName }} 
        <FamilyDescription :family-description="row.item.familyDescription"
                           :author="row.item.author"
                           :source-url="row.item.sourceUrl"
                           :id="`fdi-${row.item.familyId}`"
                           placement="rightbottom" />
      </template>
      <template #cell(isPublic)="row">
        <b-icon icon="check" v-if="row.item.isPublic" />
      </template>
      <template #cell(isShowcased)="row">
        <b-icon icon="check" v-if="row.item.isShowcased" />
      </template>
      <template #cell(suppressGender)="row">
        <b-icon icon="check" v-if="row.item.suppressGender" />
      </template>
      <template #cell(actions)="row">
        <b-button @click="onManageFamily(row)" class="action-icon" variant="primary" size="sm"><b-icon icon="tools" />Manage</b-button>
      </template>
      <template #table-busy>
        <TableBusy />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkRouteLoggedIn } from '@/logic/general/authenticationUtils'
import * as apiUtils from '@/logic/general/apiUtils'
import * as enums from '@/logic/enums'
import TableBusy from "@/components/TableBusy"
import FamilyDescription from "@/components/family/FamilyDescription"
import ImportNewFamily from '@/components/familyAdmin/ImportNewFamily'

export default {
  components: {
    FamilyDescription,
    ImportNewFamily,
    TableBusy
  },
  data() {
    return {
      busy: false,
      currentPage: 1,
      families: [],
      filter: '',
      filterOn: ['familyName','familyNameBase'],
      hasPageTwo: false,
      mode: 0,
      perPage: 15,
      rows: 0
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => checkRouteLoggedIn(vm, next))
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn',
      'isSiteAdministrator'
    ]),
    familyFields: function () {
      const f = []
      f.push({key: "actions", tdClass: "info-table-action"})
      f.push({key: "familyName", label: "Name"})
      f.push({key: "familyNameBase", label: "Base Name"})

      if (this.isSiteAdministrator) {
        f.push({key: "isPublic", label: "Public", class: "text-center"})
        f.push({key: "isShowcased", label: "Showcased", class: "text-center"})
        f.push({key: "suppressGender", label: "Hide Gender", class: "text-center"})
      }

      f.push({key: "familyAuthority", label: "Authority", formatter: this.formatFamilyAuthority})

      return f
    }
  },
  methods: {
    determinePageTwo: function () {
      this.hasPageTwo = this.rows > this.perPage
    },
    formatFamilyAuthority: function (value, key, item) {
      return enums.familyAuthorityText(value)
    },
    getInfoTableRowClass: function (mode){
      return { 
        'edit-container': true,
        'embedded-form': this.mode===mode,
        'mb-2': this.mode===mode,
        'narrow-container-2': true
      }
    },
    onFilterChange: function (filteredItems) {
      this.rows = filteredItems.length
      this.currentPage = 1
      this.determinePageTwo()
    },
    onManageFamily: function (row) {
      this.$router.push({ path: `/admin/family/${row.item.familyId}` })
    },
    reset: function () {
      this.busy = true
      this.mode = 0
      this.axios.get(apiUtils.buildAPIUrl('family/list/private'))
          .then(response => {
            this.families = response.data  
            this.rows = this.families.length  
            this.busy = false       
          })
    },
    setMode: function(mode) {
      this.mode = mode
    }
  },
  created() {
    this.reset()
  },
  watch: {
    isLoggedIn: function (newValue) {
      if (newValue === false) {
        this.$router.push({ path: `/` })
      }
    },
    watch: {
      families: function (newVal){
        this.rows = newVal.length
        this.determinePageTwo()
      }
    }
  }
}
</script>