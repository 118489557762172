import Vue from 'vue'
import axios from 'axios'
import marked from 'marked'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Fragment from 'vue-fragment'
import VueAxios from 'vue-axios'
import VueAuthenticate from '@mdornian/vue-authenticate'
import VueCookies from 'vue-cookies'
import VueDragscroll from 'vue-dragscroll'
import VueGapi from 'vue-gapi'
import VueGtag from 'vue-gtag'
import VueScrollTo from 'vue-scrollto'
import VueTheMask from 'vue-the-mask'
import staticConfig from '@/staticConfig'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// vee-validate
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBlind, faBinoculars, faBook, faClipboardList, faExchangeAlt, faGenderless, faLink, faMars, faProjectDiagram, faSpinner, faTree, faVenus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as apiUtils from '@/logic/general/apiUtils'
import * as authenticationUtils from '@/logic/general/authenticationUtils'

import App from './App.vue'
import router from '@/router'
import { patchRouterMethod, beforeEachGlobal } from '@/logic/routerExtensions'
import store from './store'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

console.log('Application Loading ...')

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Add url VeeValidation Rule
// Thanks: https://stackoverflow.com/a/62972724/1864995
extend('url', {
  validate: (value) => {
    if (value) {
      // eslint-disable-next-line
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }

    return false;
  },
  message: 'This value must be a valid URL',
})

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Font Awesome
library.add(faBlind, faBinoculars, faBook, faClipboardList, faExchangeAlt, faGenderless, faLink, faMars, faProjectDiagram, faSpinner, faTree, faVenus)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

// Bootstrap
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Vue Odds & Ends
Vue.use(VueCookies)
Vue.use(Fragment.Plugin)
Vue.use(VueAxios, axios)
Vue.use(VueTheMask)
Vue.component('VueSlider', VueSlider)

// Vue Scroll To
Vue.use(VueScrollTo, { 
  easing: 'ease-in',
  duration: 1000,
  offset: -100, 
  x: true
})

// VueAuthenticate
// The storage type will determined dynamically based upon the existance of a token either in LocalStorage or SessionStorage.   If none exists, it will be set during login or registration
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_BASE_API,  
  loginUrl: '/oauth2/token',
  logoutUrl: 'api/account/logout',
  storageNamespace: 'auth',
  tokenPrefix: 'jwt',
  cookie: {
    domain: authenticationUtils.determineCookieDomain(),
    secure: authenticationUtils.determineCookieSecure()
  }
})

// Google OAuth2 library
Vue.use(VueGapi, {
  clientId: staticConfig.googleClientId,
  scope: 'https://www.googleapis.com/auth/userinfo.profile',
})

// TODO: research version 3.0
Vue.config.errorHandler = function (err, vm, info) {
  console.log(err)

  if (vm) {
    // Analytics
    vm.$gtag.exception({
      'description': err.message,
      'fatal': false
    })

    if (err.stack && err.stack.startsWith("RangeError")) {
      vm.$router.push({ path: `/error/stackOverflow` })
    } else {
      vm.$router.push({ path: `/error/unexpected` })
    }
  }
}
Vue.config.productionTip = false

// Google Analytics
Vue.use(VueGtag, { 
  config: { id: staticConfig.googleGtagId },
  enabled: process.env.VUE_APP_ENABLE_ANALYTICS === 'true' ? true : false
}, router)

// Drag Scroll
Vue.use(VueDragscroll)

// Create the Vue instance
const vm = new Vue({
  router,
  store,
  render: h => h(App),
})

// Add custom dimensions to Google Analytics
vm.$gtag.customMap({
  'dimension1': 'familyId',
  'dimension2': 'familySEO',
  'dimension3': 'individualId',
  'dimension4': 'individualSEO',
})

// Router
// Update title & meta data
router.beforeEach((to, from, next) =>{ beforeEachGlobal(to, from, next) })

// This is here to prevent the "NavigationDuplicated" error
patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')

// Axios
axios.defaults.withCredentials = true  // To allow cookies to be transmitted in CORS environment

// Add standard error handling functionality to axios.
// Add new property postState to the response
axios.interceptors.response.use(
  response => {
    response.postState = apiUtils.handleAPISuccess(vm, response)
    return response
  }, 
  error => {
    error.postState = apiUtils.handleAPIError(vm, error)
    return Promise.reject(error)
})

// Markdown options
marked.setOptions({
  breaks: true
})


vm.$mount('#app')