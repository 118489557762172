export const generalMap = {
  'abbreviation': {default: 'Abbreviation'},
  'address': {default: 'Address'},
  'adoptiveParent': {default: 'Adoptive Parents'},
  'age': {default: 'Age'},
  'ageHusband': {default: "Husband's Age"},
  'ageWife': {default: "Wife's Age"},
  'agency': {default: 'Agency'},
  'aliases': {default: 'Aliases'},
  'ancestorInterest': {default: 'Ancestor Interest'},
  'ancestralFileNumber': {default: 'Ancestral File Number'},
  'associations': {default: 'Associations'},
  'cause': {default: 'Cause'},
  'certaintyAssessment': {default: 'Certainty Assessment'},
  'creationDate': {default: 'Creation Date'},
  'contact': {default: 'Contact'},
  'copyright': {default: 'Copyright'},
  'corporate': {default: 'Corporate'},
  'date': {default: 'Date'},
  'descendentInterest': {default: 'Descendent Interest'},
  'emails': {default: 'Email Addresses'},
  'externalFile': {default: 'External file'},
  'event': {default: 'Event'},
  'faxes': {default: 'Fax Numbers'},
  'file': {default: 'File'},
  'files': {default: 'Files'},
  'fileNotDisplayable': {default: 'File not displayable'},
  'generationsOfAncestors': {default: 'Generations Of Ancestors'},
  'generationsOfDescendants': {default: 'generations Of Descendants'},
  'lastChange': {default: 'Last Change'},
  'ledger': {default: 'Ledger'},
  'legal': {default: 'Legal'},
  'letter': {default: 'Letter'},
  'map': {default: 'Map Coordinates'},
  'media': {default: 'Media'},
  'multimedia': {default: 'Multimedia'},
  'name': {default: 'Name'},
  'nickname': {default: 'Known As'},
  'ordinance': {default: 'Ordinance'},
  'pedigree': {default: 'Pedigree'},
  'place': {default: 'Place'},
  'phones': {default: 'Phone Numbers'},
  'photoRecordIdNumber': {default: 'Photo Record Id Number'},
  'publication': {default: 'Publication'},
  'recordFileNumber': {default: 'Record File Number'},
  'recordIdNumber': {default: 'Record Id Number'},
  'reference': {default: 'Reference'},
  'relation': {default: 'Relation'},
  'repository': {default: 'Repository'},
  'restrictionNotice': {default: 'Restriction Notice'},
  'role': {default: 'Role'},
  'sex': {default: 'Sex'},
  'shares': {default: 'Shares'},
  'sourceCallNumbers': {default: 'Source Call Numbers'},
  'status': {default: 'Status'},
  'submitter': {default: 'Submitter'},
  'temple': {default: 'Temple'},
  'time': {default: 'Time'},
  'title': {default: 'Title'},
  'type': {default: 'Type'},
  'url': {default: 'Url'},
  'urls': {default: 'Urls'},
  'version': {default: 'Version'},
  'witnesses': {default: 'Witnesses'},

  

  ADOP: {default: 'Adoption'},
  ANUL: {default: 'Annulment'},
  BAPL: {default: 'Baptism'},
  BAPM: {default: 'Baptism'},
  BARM: {default: 'Bar Mitzvah'},
  BASM: {default: 'Bas Mitzvah'},
  BIRT: {default: 'Birth'},
  BLES: {default: 'Blessing'},
  BURI: {default: 'Burial'},
  CAST: {default: 'Caste'},
  CENS: {default: 'Census'},
  CHR: {default: 'Christening'},
  CHRA: {default: 'Adult Christening'},
  CONF: {default: 'Confirmation'},
  CONL: {default: 'Confirmation'},
  CREM: {default: 'Cremation'},
  DEAT: {default: 'Death'},
  DIV: {default: 'Divorce'},
  DIVF: {default: 'Divorce Filed'},
  DSCR: {default: 'Physical Description'},
  EDUC: {default: 'Education'},
  EMIG: {default: 'Emmigration'},
  ENDL: {default: 'Endowment'},
  ENGA: {default: 'Engagement'},
  EVEN: {default: 'Event'},
  FACT: {default: 'Fact'},
  FCOM: {default: 'First Communion'},
  GRAD: {default: 'Graduation'},
  IDNO: {default: 'National Id Number'},
  IMMI: {default: 'Immigration'},
  MARR: {default: 'Marriage'},
  MARB: {default: 'Marriage Public Notice'},
  MARC: {default: 'Marriage Contract'},
  MARL: {default: 'Marriage License'},
  MARS: {default: 'Marriage Settlement'},
  NATI: {default: 'National or Tribal Origin'},
  NATU: {default: 'Naturalization'},
  NCHI: {default: 'Number of Children'},
  NMR: {default: 'Number of Marriages'},
  OCCU: {default: 'Occupation'},
  ORDN: {default: 'Ordination'},
  PROB: {default: 'Probate'},
  PROP: {default: 'Possessions'},
  RELI: {default: 'Religious Affiliation'},
  RESI: {default: 'Residence'},
  RETI: {default: 'Retirement'},
  SLGC: {default: 'Sealing'},
  SSN: {default: 'Social Security Number'},
  TITL: {default: 'Title'},
  WAC: {default: 'Washing and Clothing'},
  WILL: {default: 'Will'},
}

export const ageReplacer = {
  'y': {default: ' years'},
  'm': {default: ' months'},
  'd': {default: ' days'},
  'CHILD': {default: '< 8 years'},
  'INFANT': {default: '< 1 year'},
  'STILLBORN': {default: 'stillborn'}
}

export const dateReplacer = {
    'ABT': {default: 'about'},
    'AFT': {default: 'after'},
    'AND': {default: 'and'},
    'BEF': {default: 'before'},
    'BET': {default: 'between'},
    'CAL': {default: 'calculated'},
    'EST': {default: 'estimated'},
    'FROM': {default: 'from'},
    'INT ': {default: ''},
    'TO': {default: 'to'},
    'JAN': {default: 'Jan'},
    'FEB': {default: 'Feb'},
    'MAR': {default: 'Mar'},
    'APR': {default: 'Apr'},
    'MAY': {default: 'May'},
    'JUN': {default: 'Jun'},
    'JUL': {default: 'Jul'},
    'AUG': {default: 'Aug'},
    'SEP': {default: 'Sep'},
    'OCT': {default: 'Oct'},
    'NOV': {default: 'Nov'},
    'DEC': {default: 'Dec'}
}

export const ldsStatusMap = {
  'BIC': {default: 'Born in the covenant receiving blessing of child to parent sealing.'},
  'CANCELED': {default: 'Canceled and considered invalid.'},
  'CHILD': {default: 'Died before eight years old.'},
  'CLEARED': {default: 'Cleared for temple ordinance.'},
  'COMPLETED': {default: 'Completed but the date is not known.'},
  'DNS': {default: 'This record is not being submitted for this temple ordinances.'},
  'DNS/CAN': {default: 'This record is not being submitted for this temple ordinances.'},
  'INFANT': {default: 'Died before less than one year old, baptism not required.'},
  'QUALIFIED': {default: 'Ordinance request qualified by authorized criteria.'},
  'PRE-1970': {default: 'Ordinance is likely completed, another ordinance for this person was converted from temple records of work completed before 1970, therefore this ordinance is assumed to be complete until all records are converted.'},
  'STILLBORN': {default: 'Stillborn, ordinance not required.'},
  'SUBMITTED': {default: 'Ordinance was previously submitted.'},
  'UNCLEARED': {default: 'Data for clearing ordinance request was insufficient.'},
}

export const noteReplacer = {
  '{geni:about_me}': {default: 'About:'},
  '{geni:hair_color}': {default: 'Hair Color'},
  '{geni:eye_color}': {default: 'Eye Color'},
  '{geni:height_1}': {default: 'Height'},
  '{geni:height_2}': {default: 'Height'}
}

export const pedigreeMap = {
  '0': {default: 'Unreliable evidence or estimated data'},
  '1': {default: 'Questionable reliability of evidence (interviews, census, oral genealogies, or potential for bias for example, an autobiography)'},
  '2': {default: 'Secondary evidence, data officially recorded sometime after event'},
  '3': {default: 'Direct and primary evidence used, or by dominance of the evidence'}
}

export const quayMap = {
  'adopted': {default: 'Adopted'},
  'birth': {default: 'Birth'},
  'foster': {default: 'Foster'},
  'sealing': {default: 'Sealing'}
}

export const restrictionMap = {
  'confidential': {default: 'Confidential'},
  'locked': {default: 'Locked'},
  'privacy': {default: 'Private'}
}

export const roleMap = {
  'CHIL': {default: 'Child'},
  'HUSB': {default: 'Husband'},
  'WIFE': {default: 'Wife'},
  'MOTH': {default: 'Mother'},
  'FATH': {default: 'Father'},
  'SPOU': {default: 'Spouse'},
  'WitnessRole': {default: 'Witness'},
}

export const sexMap = {
  'F': {default: 'Female'},
  'M': {default: 'Male'}
}

export const statusMap = {
  'challenged': {default: 'Challenged'},
  'disproven': {default: 'Disproven'},
  'proven': {default: 'Proven'}
}

export const typeMap = {
  'NameBirth': {default: 'Birth'},
  'NameMarried': {default: 'Married'}
}

export const ynReplacer = {
  'Y': {default: 'Yes'},
  'N': {default: 'No'}
}