<template>
  <b-modal id="apiUnexpectedErrorModal" size="lg" hide-header>
    <div>
      <h3>Unexpected Error</h3>
      <p>An unexpected error has occurred on the site. We apologize for any inconvenience.</p>
      <b-table-simple>
        <caption>If you report this problem please quote the information below:</caption>
        <b-tbody>
          <b-tr>
            <b-th class="text-right">Local Time:</b-th>
            <b-td>{{ getTimeLocal() }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">UTC Time:</b-th>
            <b-td>{{ getTimeUtc() }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-button size="sm" class="float-right" @click="close ()">
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'APIUnexpectedErrorModal',
    components: {
    },
    props: {
    },
    data() {
      return {
      }
    },
    methods: {
      getTimeLocal: function () {
        return moment().format("YYYY-MM-DD HH:mm:ss Z")
      },
      getTimeUtc: function () {
        return moment().utc().format()
      }
    }
  }
</script>