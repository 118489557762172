import shortUUID from 'short-uuid'

export default {
  areCookiesAccepted: false,
  browserMaxGenerations: 1000,
  hasNetworkError: false,
  infoModalEntityId: null,
  infoModalEntityType: null,
  generationRelativeWidth: 50,
  language: "en",
  maxAncestorGenerations: 380,
  maxDescendentGenerations: 380,
  measurement: "imperial",
  showAncestors: true,
  showDecendents: true,
  showGenderInTree: true,
  treeRenderOptionVersion: shortUUID.generate(),
  treeZoom: 2,
  renderedIndividuals: {},
  renderedUnions: {}
}