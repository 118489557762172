<template>
  <fragment>
    <h4>New Invitation</h4>
    <div class="edit-container embedded-form narrow-container-1 mb-2">
      <SendInvitation @force-reset="reset()" />
    </div>

    <div class="d-flex flex-wrap justify-content-between">
      <div>
        <h4>
          Invitations Outstanding
          <b-icon class="hover-pointer"
                  icon="info-square"
                  v-b-popover.click.blur.bottomright="'If this is a private family, these are all the people that have been invited to view it but have not yet accepted.  Accepting an invitation is optional.  Invitations are via  a user\'s email and can be made even prior to that user having an account. That person will see the invitation the next time they login or, if they are already logged in, the next time they user the website.  If the user may also be sent an email with the invitation if their email settings allow it.'" />
        </h4>
      </div>
      <div>
        <b-pagination
          v-if="hasInvitationsPageTwo"
          v-model="invitationsCurrentPage"
          :per-page="invitationsPerPage"
          :total-rows="invitationsRows"
          aria-controls="my-table"
          align="right" />
      </div>
      <div>
        <b-form-group
          class="narrow-container-0"
          label-cols-sm="4"
          label="Name Filter:"
          label-align-sm="right"
          label-for="invitations-filter-box">
          <b-form-input id="invitations-filter-box" 
                        type="text" 
                        v-model="invitationsFilter" 
                        tabindex="1" />
        </b-form-group>
      </div>
    </div>

    <b-table :busy="busy"
             :current-page="invitationsCurrentPage"
             :items="familyInvitations" 
             :fields="invitationsFields"
             :filter="invitationsFilter"
             :filter-included-fields="invitationsFilterFields"
             :per-page="invitationsPerPage"
             @filtered="onInvitationsFiltered"
             hover
             responsive
             show-empty
             stacked="md"
             striped>
      <template #head(inviteeUserName)>
        <div>
          Invitee
          <b-icon class="hover-pointer"
                  icon="info-square"
                  v-b-popover.click.blur.bottomright="'If the user already has an account on the website, their name will appear here.'" />
        </div>
      </template>
      <template #head(sponsorUserName)>
        <div>
          Sponsor
          <b-icon class="hover-pointer"
                  icon="info-square"
                  v-b-popover.click.blur.bottomright="'This is the person who extended the invitation to view this family.'" />
        </div>
      </template>
      <template #cell(hasBeenViewed)="row">
        <b-icon icon="check" v-if="row.item.hasBeenViewed" />
      </template>
      <template #cell(actions)="row">
        <b-button v-if="canDeleteInvitation(row.item.sponsorUserId) && !isInvitationProcessing(row)"
                  @click="revokeInvitation(row.item.familyInvitationId)"
                  class="action-icon"
                  variant="primary"
                  size="sm">
          <b-icon icon="x-circle" />Revoke
        </b-button>
        <div v-if="hasRowError(row)" class="alert alert-danger alert-table" v-b-tooltip.hover :title="rowError(row)">Error</div>
      </template>
      <template #table-busy>
        <TableBusy />
      </template>
    </b-table>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import * as apiUtils from '@/logic/general/apiUtils'
import * as familyAdminUtils from '@/logic/family/familyAdminUtils'
import SendInvitation from '@/components/familyAdmin/SendInvitation'
import TableBusy from "@/components/TableBusy"

export default {
  components: {
    SendInvitation,
    TableBusy
  },
  props: {
    familyInvitations: {type: Array},
    operatorFamilyAuthorityId: {type: Number}
  },
  data() {
    return {
      busy: false,
      invitationsFields: [
        {key: "actions", tdClass: "info-table-action"},
        {key: "email", label: "Email"},
        {key: "inviteeUserName"},
        {key: "hasBeenViewed", label: "Has Been Viewed?", class: "text-center"},
        {key: "sponsorUserName"}
      ],
      invitationsFilter: '',
      invitationsFilterFields: ['email'],
      invitationsCurrentPage: 1,
      invitationsPerPage: 10,
      invitationsRows: 0,
      invitationProcessingRows: {},
      invitationRowErrors: {}
    }
  },
  computed: {
    ...mapState('authentication', [
      'jwt'
    ]),
    currentUser: function () {
      return this.jwt.nameid
    },
    hasInvitationsPageTwo: function () {
      return this.invitationsRows > this.invitationsPerPage
    },
  },
  methods: {
    canDeleteInvitation: function (sponsorUserId) {
      return familyAdminUtils.canDeleteInvitation(this.operatorFamilyAuthorityId, this.currentUser, sponsorUserId)
    },
    hasRowError: function (row) {
      return this.invitationRowErrors[row.item.familyInvitationId] ? true : false
    },
    isInvitationProcessing: function (row) {
      return this.invitationProcessingRows[row.item.familyInvitationId]
    },
    onInvitationsFiltered(filteredItems) {
      this.invitationsRows = filteredItems.length
      this.invitationsCurrentPage = 1
    },
    reset: function () {
      this.$emit('force-reset')
    },
    revokeInvitation: function (familyInvitationId) {
      this.$set(this.invitationProcessingRows, familyInvitationId, true)
      this.axios.post(apiUtils.buildAPIUrl(`familyInvitation/revokeInvitation/${familyInvitationId}`))
        .then(response => {
          this.reset()
        })
        .catch(error => {
          this.$set(this.invitationRowErrors, familyInvitationId, error.postState.errorMessages[0].message)
        })
    },
    rowError: function (row) {
      return this.invitationRowErrors[row.item.familyInvitationId]
    }
  },
  watch: {
    familyInvitations: function (newValue) {
      this.invitationsRows = newValue ? newValue.length : 0
    }
  }
}
</script>