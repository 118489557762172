<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onRelativeToMe)">
      <h3>Login</h3>
      <p>If you want to view your's or other people's private family trees you need to login.  Logging in also allows you to share private family trees with specific people.  You can login directly to this site or use your login credentials from one of the supported account providers.</p>

      <LoginOptions v-model="form.stayLoggedIn" tabindex="1" />

      <h5>Continue With ...</h5>
      <b-row>
        <b-col offset-md="1">
          <div>
            <b-form-group>
              <b-img :src="iconGoogle"
                     @click="onGoogle()"
                     :class="classGoogle"
                     fluid />
              <ApiPostFeedback :post-state="postStateGoogle" :push-right="false" />
            </b-form-group>
            <p> - or - </p>
          </div>
          <h6>Login to relativetome.com</h6>
          <validation-provider name="Email" :rules="{required: true, email: true}" v-slot="validationContext">
            <b-form-group
              label-cols-sm="2"
              label="Email:"
              label-align-sm="right"
              label-for="email-box">
              <b-form-input id="email-box" 
                            type="text" 
                            v-model="form.email" 
                            :state="getValidationState(validationContext)"
                            autocomplete="username" 
                            tabindex="2" />
              <b-form-invalid-feedback id="emailBox-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              <div class="d-flex justify-content-start">
                <b-button variant="link" tabindex="11" @click="showForm('register')" :disabled="isLoginInprogress">Register New Account</b-button>
              </div>
            </b-form-group>
          </validation-provider>
          <validation-provider name="Password" :rules="{required: true}" v-slot="validationContext">
            <b-form-group
              label-cols-sm="2"
              label="Password:"
              label-align-sm="right"
              label-for="password-box">
              <b-form-input id="password-box"
                            type="password"
                            v-model="form.password"
                            :state="getValidationState(validationContext)"
                            autocomplete="current-password"
                            tabindex="3" />
              <b-form-invalid-feedback id="password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              <div class="d-flex justify-content-start">
                <b-button variant="link" tabindex="12" @click="showForm('forgot')">Forgot Your Password?</b-button>
              </div>
              <div class="d-flex justify-content-end">
                <b-button :disabled="isLoginInprogress" type="submit" variant="primary" tabindex="5">Submit</b-button>
              </div>
              <ApiPostFeedback :post-state="postStateRelativeToMe" />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer> 
</template>

<script>
  import qs from 'qs'
  import * as analyticsUtils from '@/logic/general/analyticsUtils'
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as authenticationUtils from '@/logic/general/authenticationUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"
  import LoginOptions from "@/components/security/LoginOptions"

  const files = require.context('@/assets/', false, /\.png$/)

  export default {
    name: 'Login',
    components: {
      ApiPostFeedback,
      LoginOptions
    },
    props: {
    },
    data() {
      return {
        form: {
          email: "",
          stayLoggedIn: false,
          password: ""
        },
        oauthProviderGoogle: null,
        postStateGoogle: {},
        postStateRelativeToMe: {}
      }
    },
    computed: {
      classGoogle: function () {
        return {
          "hover-pointer": true, 
          "partner": !this.postStateGoogle.inProgress
        }
      },
      iconGoogle: function () {
        return files('./btn_google_signin_light_normal_web.png')
      },
      isLoginInprogress: function () {
        return this.postStateGoogle.inProgress || this.postStateRelativeToMe.inProgress
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      isPostError: function () {
        return !apiUtils.isPostError(this.postStateRelativeToMe)
      },
      onGoogle: function () {
        if (this.isLoginInprogress) return

        this.postStateGoogle = apiUtils.resetPostState()

        // https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow
        // https://github.com/google/google-api-javascript-client/blob/master/samples/authSample.html
        // https://developers.google.com/identity/protocols/oauth2/openid-connect    See the OpenID section.  
        // https://developers.google.com/identity/sign-in/web/reference#googleauthsignin
        // This is an implicit flow which happens completely within Googles libraries.  The result of the signin() method will be the access_token and the id_token.   
        // We will then send the id_token to the server and check its signature there to ensure that it is valid.  This means the server does not have to do a server flow authorization_code grant type.
        this.oauthProviderGoogle.signIn()
          .then((currentUser) => {   
            // Placing a breakpoint here will case a "popup_blocked_by_browser" error
            const authResponse = currentUser.getAuthResponse(true)          
            const payload = {
              id_token: authResponse.id_token, 
              stayLoggedIn: this.form.stayLoggedIn
            }
            
            this.axios.post(apiUtils.buildAPIUrl('account/googleLogin'), payload)
              .then(response => this.onGoogleSuccess(response))
              .catch(error => this.onGoogleFailure(error))
          })
          .catch((error) => {
            this.postStateGoogle.errorType = apiUtils.APIErrorTypes.USER
            this.postStateGoogle.inProgress = false
            let message = error.error

            switch (message) {
              case 'access_denied':
                message = "Access denied"
                break;
              case 'popup_blocked_by_browser':
                message = "Popup blocked by browser"
                break;
              case 'popup_closed_by_user':
                message = "Popup closed by user"
                break;
            }

            this.postStateGoogle.errorMessages.push({message})
          })
      },
      onGoogleSuccess: function (response) {
        this.postStateGoogle = response.postState
        this.$auth.storageType = authenticationUtils.determineStorageLocation(this.form.stayLoggedIn)
        this.$auth.setToken(response.data)
        this.$store.dispatch('authentication/signalNewAccessToken', this)
          .then(() => {
            analyticsUtils.eventLogin(this)
          })
        this.$bvModal.hide('authenticationModal')
      },
      onGoogleFailure: function (error) {
        this.postStateGoogle = error.postState
      },

      onRelativeToMe: function () {
        const data = qs.stringify({
          grant_type: "password",
          username: this.form.email,
          password: this.form.password,
          stayLoggedIn: this.form.stayLoggedIn
        })

        this.postStateRelativeToMe = apiUtils.resetPostState()
        const storageType = authenticationUtils.determineStorageLocation(this.form.stayLoggedIn)

        // axios defaults content-type to 'application/x-www-form-urlencoded'
        this.$auth.login(data, null, storageType)
          .then(response => this.onRelativeToMeSuccess(response))
          .catch(error => this.onRelativeToMeFailure(error))
      },
      onRelativeToMeSuccess: function (response) {
        this.postStateRelativeToMe = response.postState
        this.resetForm()
        this.$store.dispatch('authentication/signalNewAccessToken', this)
          .then(() => {
            analyticsUtils.eventLogin(this)
          })
        this.$bvModal.hide('authenticationModal')
      },
      onRelativeToMeFailure: function (error) {
        this.postStateRelativeToMe = error.postState
        this.form.password = ""
        validationUtils.resetFormDelayed(this)
      },
      resetForm: function () {
        this.postStateRelativeToMe = {}
        this.form = {
          email: "",
          stayLoggedIn: false,
          password: ""
        }
        validationUtils.resetFormImmediate(this)
      },
      showForm: function (formName) {
        this.resetForm()
        this.$emit('set-display-mode',formName)
      }
    },
    created() {
      this.$gapi.getAuthInstance().then(instance => {
        this.oauthProviderGoogle = instance
      })
    }
  }
</script>

<style scoped>
.partner {
  -webkit-filter: drop-shadow(2px 2px 2px #666);
  filter: drop-shadow(2px 2px 2px #666);
}

.partner:active:hover {
  -webkit-filter: none;
  filter: none;
}
</style>