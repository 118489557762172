import { setHeaderTags } from '@/logic/seo/headerUtils'

// Prevent 'NavigationDuplicated' error
// https://stackoverflow.com/a/63263736/1864995
export function patchRouterMethod(router, methodName)
{
    router['old' + methodName] = router[methodName]
    router[methodName] = async function (location)
    {
        return router['old' + methodName](location).catch((error) =>
        {
            if (error.name === 'NavigationDuplicated')
            {
                return this.currentRoute
            }
            throw error
        })
    }
}

// Thanks to: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
export function beforeEachGlobal(to, from, next) 
{
  if (!to.skipMetaLoadInBeforeEach) {
    setHeaderTags(to)
  }
  
  console.log(`Route: ${to.path}`)
  next()
}
