import _ from 'lodash'
import * as individualUtils from '@/logic/family/individualUtils'

export function getFamilyRelationships(familyData, individual, group) {
  const familyUnions = _.get(individual, group, [])

  // Materialize the unions
  // Sometimes the original Gedcom file can contain bad data.  If the union does not exist we will filter it out
  const fu = familyUnions.map(familyRelationship => enhanceFamilyRelationship(familyData, familyRelationship, individual.id)).filter(union => union != null)

  // Unions are not sorted and are presented in the same order they were received in the original GEDCOM file.  
  // GEDCOM does not support union order so we must assume that the exports have sorted the unions appropriately.
  return fu
}

export function getFamilyParents(familyData, individual) {
  return getFamilyRelationships(familyData, individual, 'familyParents')
}

export function getFamilyUnions(familyData, individual) {
  return getFamilyRelationships(familyData, individual, 'familyUnions')
}

export function enhanceFamilyRelationship(familyData, familyRelationship, individualId = null) {
  if (!familyRelationship) {
    return null
  }

  const eunion = enhanceUnion(familyData, familyRelationship.pointer, individualId)

  // Bad data check
  if (!eunion) {
    return null
  }

  const eFamilyUnion = _.cloneDeep(familyRelationship)
  eFamilyUnion["union"] = eunion
  eFamilyUnion["spouses"] = getSpouses(familyData, eunion)

  return eFamilyUnion
}

export function enhanceUnion(familyData, unionId, individualId = null) {
  const union = familyData.families[unionId]

  // Bad data check
  if (!union) {
    return null
  }

  const eUnion = _.cloneDeep(union)

  if (!eUnion.spouses) eUnion.spouses = []

  // Materialize and the other spouse.  We will assume no more then one at this point but there could be zero
  // This can only be done if the individualId is known.
  const spouseId = eUnion.spouses.find(x => x !== individualId)

  if (spouseId) {
    const spouse = individualUtils.getIndividual(familyData, spouseId)  

    // Bad data check
    if (spouse) eUnion['spouse'] = spouse
  }

  eUnion['kids'] = getChildren(familyData, union)

  return eUnion
}

// Materialize and sort the children of a union
// Sometimes the original Gedcom file can contain bad data.  If the union does not exist we will filter it out
export function getChildren(familyData, union) {
  if (union.children) {
    return union.children
                .map(item => {
                  const individual = individualUtils.getIndividual(familyData, item.pointer, false)
                  return individual ? _.merge(_.cloneDeep(individual), _.omit(item,['pointer'])) : null
                })
                .filter(eIndividual => eIndividual != null)
  } else {
    return []
  }
}

// TODO introduce option that favours adoption over birth
export function getBirthParents(familyData, individual) {
  const parents = _.get(individual, 'familyParents', [])

  const birthParents = parents.filter(item => {
    const pedigree = _.get(item, 'pedigree.value')
    return pedigree === 'birth'
  })

  let familyRelationship = null

  if (birthParents.length > 0)
    familyRelationship = birthParents[0]
  else if (parents.length > 0)
    familyRelationship = parents[0]

  return enhanceFamilyRelationship(familyData, familyRelationship)
}

export function getParents(familyData, individual) {
  const parents = getBirthParents(familyData, individual)
  
  if (parents) {
    return getSpouses(familyData, parents.union)
  } else {
    return []
  }
}

export function getParentsChildren(familyData, individual) {
  const parents = getBirthParents(familyData, individual)

  if (parents) {
    return getChildren(familyData, parents.union)
  }
}

export function getSpouses(familyData, union) {
  if (union) {
    const spouses = union.spouses

    if (spouses) {
      return spouses.map(individualId => individualUtils.getIndividual(familyData, individualId))
        .sort((a,b) => {
          const namesA = individualUtils.getName(a).split(' ')
          const namesB = individualUtils.getName(b).split(' ')
          const nameA = namesA[namesA.length - 1]
          const nameB = namesB[namesB.length - 1]

          if (nameA < nameB) {
            return -1
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
    } else {
      return []
    }
  } else {
    return []
  }
}