<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-card bg-variant="light"
            tag="article"
            class="mb-2">
      <b-card-text>
        Please select those emails that you wish to receive and click the "Submit" button to save your preferences.  Please note that security emails used to protect your account will always be sent.
      </b-card-text>
    </b-card>
    <b-form-checkbox v-model="form.receiveOffers" class="py-1" tabindex="1">
      Receive promotions and offers
    </b-form-checkbox>
    <b-form-checkbox v-model="form.receiveProductInformation" class="py-1" tabindex="2">
      Receive product information
    </b-form-checkbox>
    <b-form-checkbox v-model="form.receiveInvitations" class="py-1" tabindex="3">
      Receive invitations to join families.
    </b-form-checkbox>
    <div class="py-1">
      <b-link @click="onUnsubscribeAll">Unsubscribe</b-link>
    </div>
    <div class="d-flex justify-content-end">
      <b-button v-if="!isStandAlone"
                type="button"
                variant="outline-secondary"
                tabindex="12"
                @click="resetPage()">
        Cancel
      </b-button>
      <b-button :disabled="postState.inProgress"
                type="submit"
                variant="primary"
                tabindex="11"
                class="ml-2">
        Submit
      </b-button>
    </div>
    <ApiPostFeedback :post-state="postState" success-message="Your settings have been updated" />
  </b-form>
</template>

<script>
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"

  export default {
    name: 'EditEmailSettings',
    components: {
      ApiPostFeedback
    },
    props: {
      isStandAlone: {type: Boolean, default: false},
      initialState: {type: Object},
      manageEmailToken: {type: String}
    },
    data() {
      return {
        form: {
          manageEmailToken: this.manageEmailToken,
          receiveOffers: this.initialState.receiveOffers,
          receiveProductInformation: this.initialState.receiveProductInformation,
          receiveInvitations: this.initialState.receiveInvitations
        },
        postState: {}
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()
        this.axios.patch(apiUtils.buildAPIUrl('emailManagement'), this.form)
          .then(response => {
            this.postState = response.postState
            this.resetPage()
          })
          .catch(error => {
            this.postState = error.postState
          })
      },
      onUnsubscribeAll: function () {
        this.form.receiveOffers = false
        this.form.receiveProductInformation = false
        this.form.receiveInvitations = false
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>