import staticConfig from '@/staticConfig'
import { maxStackSize } from '@/logic/general/browserUtils'

export default {
  acceptCookies(context) {
    localStorage.setItem('ls-acv', staticConfig.acceptedCookieVersion)
    context.commit('setCookiesAccepted')
  },
  loadHasNetworkError(context, hasNetworkError) {
    context.commit('setHasNetworkError', hasNetworkError)
  },
  loadInfoModal(context, infoModalOptions) {
    context.commit('setInfoModalIndividualId', infoModalOptions)
  },
  updateRenderOptions(context, renderOptions) {
    context.commit('setRenderOptions', renderOptions)
    context.commit('resetRenderedEntities')
  },
  recordRenderedIndividual(context, individual) {
    context.commit('setRenderedIndividual', individual)
  },
  recordRenderedUnion(context, union) {
    context.commit('setRenderedUnion', union)
  },
  resetModalIds(context) {
    context.commit('resetModalIds')
  },
  resetRenderedEntities(context) {
    context.commit('resetRenderedEntities')
  },
  setInitialState(context) {
    const initialState = {
      areCookiesAccepted: localStorage.getItem('ls-acv') === staticConfig.acceptedCookieVersion,
      browserMaxGenerations: Math.floor(maxStackSize() / 100)  // 100 is an arbitrary number of steps used for each generation
    }
    context.commit('setInitialState', initialState)
  }
}