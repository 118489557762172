import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'

import authentication from './authentication'
import family from './family'
import viewState from './viewState'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,

  modules: {
    authentication: authentication,
    family: family,
    viewState: viewState
  }
})
