<template>
  <div class="px-2 pt-2">
    <AcceptInvitations :show-viewed="true" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkRouteLoggedIn } from '@/logic/general/authenticationUtils'
import AcceptInvitations from '@/components/familyAdmin/AcceptInvitations.vue'

export default {
  components: {
    AcceptInvitations
  },
  data() {
    return {
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => checkRouteLoggedIn(vm, next))
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn'
    ])
  },
  watch: {
    isLoggedIn: function (newValue) {
      if (newValue === false) {
        this.$router.push({ path: `/` })
      }
    }
  }
}
</script>