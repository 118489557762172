<template>
  <div class="individual-container" @click="onShowDetails">
    <individual-name :individual-id="individualId" :is-in-tree="true" />
    <individual-summary :individual-id="individualId" :is-in-tree="true" />
  </div>
</template>

<script>
  import IndividualName from "@/components/tree/IndividualName"
  import IndividualSummary from "@/components/tree/IndividualSummary"
  
  export default {
    name: 'Individual',
    components: {
      IndividualName,
      IndividualSummary
    },
    props: {
      individualId: {type: String, required: true}
    },
    data() {
      return {
      }
    },
    methods: {
      onShowDetails: function (event) {
        this.$store.dispatch('viewState/loadInfoModal',{infoModalEntityId: this.individualId, infoModalEntityType: 'individual'} )
        this.$bvModal.show('infoModal')
      }
    }
  }

</script>