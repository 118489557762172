import * as familyUtils from '@/logic/family/familyUtils'

export default class Household {
  focusIndividual = null
  focusIndividualOffset = 0
  parents = []
  parentHouseholds = []
  siblings =  []
  startRow = 0
  endRow = () => { return this.startRow + this.siblings.length - 1 }
  endAncestorsRow = () => { return Math.max(this.endRow(), ...this.parentHouseholds.map(x => x.endRow())) }

  constructor(familyData, focusIndividual) {
    this.focusIndividual = focusIndividual

    // For duplicate individuals we are not going to develop their ancestors.  Ignore parents and other siblings
    if (!focusIndividual.isDuplicate) {
      this.parents = familyUtils.getParents(familyData, focusIndividual)

      if (focusIndividual.familyParents && focusIndividual.familyParents.length > 0) {
        // Include the focus individual and all their siblings within the household's siblings
        this.siblings = familyUtils.getParentsChildren(familyData, focusIndividual)
      } 
    }

    // If the individual does not have any siblings due to not having parents or their is bad data in the parents then set this person as the only child in the household.
    if (this.siblings.length === 0) {
      this.siblings = [focusIndividual]
    }
  }
}