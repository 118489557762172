<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <h3>Register New Account</h3>
      <p>Fill this form to register a new account.  You will be sent a verifying email.</p>
      <h5>Personal Information</h5>
      <validation-provider name="Given Name" :rules="{required: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Given Name:"
          label-align-sm="right"
          label-for="given-name-box">
          <b-form-input id="given-name-box"
                        type="text"
                        v-model="form.givenName"
                        :state="getValidationState(validationContext)"
                        tabindex="1" />
          <b-form-invalid-feedback id="givenName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Family Name" :rules="{required: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Family Name:"
          label-align-sm="right"
          label-for="family-name-box">
          <b-form-input id="family-name-box"
                        type="text"
                        v-model="form.familyName"
                        :state="getValidationState(validationContext)"
                        tabindex="2" />
          <b-form-invalid-feedback id="familyName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Email" :rules="{required: true, email: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Email:"
          label-align-sm="right"
          label-for="email-box">
          <b-form-input id="email-box" 
                        type="text" 
                        v-model="form.email" 
                        :state="getValidationState(validationContext)"
                        autocomplete="username"
                        tabindex="3" />
          <b-form-invalid-feedback id="email-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider vid="password" name="Password" :rules="{required: true, regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/gm }" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Password:"
          label-align-sm="right"
          label-for="password-box">
          <b-form-input id="password-box"
                        type="password"
                        v-model="form.password"
                        :state="getValidationState(validationContext)"
                        autocomplete="new-password"
                        tabindex="4" />
          <b-form-invalid-feedback id="password-feedback">The password must be at least 10 characters long containing the following: upper case letters, lower case letters, and digits.</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Confirm Password" :rules="{required: true, confirmed:'password' }" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Confirm Password:"
          label-align-sm="right"
          label-for="confirmPassword-box">
          <b-form-input id="confirmPassword-box"
                        type="password"
                        v-model="form.confirmPassword"
                        :state="getValidationState(validationContext)"
                        autocomplete="new-password"
                        tabindex="5" />
          <b-form-invalid-feedback id="confirmPassword-feedback">The passwords must match.</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Accept Terms" :rules="{ required: { allowFalse: false } }" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label-for="acceptTerms-box">
          <b-form-checkbox id="acceptTerms-box"
                           v-model="form.acceptTerms" 
                           :state="getValidationState(validationContext)"
                           tabindex="6">
            You agree to our use of 
            <b-link href="/info/privacy#cookies" target="_blank">cookies</b-link>, 
            <b-link href="/info/terms" target="_blank">Terms of Use</b-link>, and 
            <b-link href="/info/privacy" target="_blank">Privacy Policy</b-link>.
          </b-form-checkbox>
          <b-form-invalid-feedback id="acceptTerms-feedback"
                                   :state="getValidationState(validationContext)">
            You must agree to our use of cookies, Terms of Use, and Privacy Policy.
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <LoginOptions v-model="form.stayLoggedIn" tabindex="6" />

      <div class="d-flex justify-content-end pb-2">
        <vue-recaptcha :sitekey="reCaptchaSiteKey" 
                       @verify="onCaptchaVerified"
                       @expired="onCaptchaExpired"
                       size="normal"
                       tabindex="10" />
      </div>
      <div class="d-flex justify-content-end">
        <b-button type="button" variant="outline-secondary" tabindex="12" @click="onCancelRegistration">Cancel</b-button>
        <b-button type="submit"
                  variant="primary"
                  tabindex="11"
                  class="ml-2"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  :title="submitTitle"
                  :disabled="!form.recaptchaToken">
          Submit
        </b-button>
      </div>
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import * as analyticsUtils from '@/logic/general/analyticsUtils'
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as authenticationUtils from '@/logic/general/authenticationUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"
  import LoginOptions from "@/components/security/LoginOptions"
  import VueRecaptcha from 'vue-recaptcha';

  const submitCaptchaMissing = "Please respond to reCaptcha"

  export default {
    name: 'Register',
    components: {
      ApiPostFeedback,
      LoginOptions,
      VueRecaptcha
    },
    props: {
    },
    data() {
      return {
        form: {
          recaptchaToken: "",
          confirmPassword: "",
          email: "",
          familyName: "",
          givenName: "",
          password: "",
          acceptTerms: false,
          stayLoggedIn: false
        },
        postState: {},
        reCaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        submitTitle: submitCaptchaMissing,
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onCancelRegistration: function () {
        this.$emit('set-display-mode','login')
        this.resetForm()
      },
      onCaptchaExpired: function () {
        this.form.recaptchaToken = ''
        this.submitTitle = submitCaptchaMissing
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.form.recaptchaToken = recaptchaToken
        this.submitTitle = ''
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()
        this.axios.post(apiUtils.buildAPIUrl('account/register'), this.form)
          .then(response => this.onSubmitSuccess(response))
          .catch(error => this.onSubmitFailure(error))
      },
      onSubmitSuccess: function (response) {
        this.postState = response.postState
        this.$auth.storageType = authenticationUtils.determineStorageLocation(this.form.stayLoggedIn)
        this.$auth.setToken(response.data)
        this.resetForm()
        this.$store.dispatch('authentication/processAccessToken', this)
          .then(x => {
            analyticsUtils.eventLogin(this)
          })
        this.$bvModal.hide('authenticationModal')
      },
      onSubmitFailure: function (error) {
        this.postState = error.postState
      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          confirmPassword: "",
          email: "",
          familyName: "",
          givenName: "",
          password: "",
          stayLoggedIn: false
        }
        validationUtils.resetFormDelayed(this)
      }
    }
  }
</script>