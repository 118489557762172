import Vue from 'vue'
import shortUUID from 'short-uuid'
import * as individualUtils from '@/logic/family/individualUtils'

export default {
  resetModalIds (state) {
    state.infoModalEntityId = null
    state.infoModalEntityType = null
  },
  resetRenderedEntities (state) {
    state.renderedIndividuals = {}
    state.renderedUnions = {}
  },
  setCookiesAccepted(state) {
    state.areCookiesAccepted = true
  },
  setHasNetworkError (state, hasNetworkError) {
    state.hasNetworkError = hasNetworkError
  },
  setInfoModalIndividualId (state, {infoModalEntityId, infoModalEntityType}) {
    state.infoModalEntityId = infoModalEntityId
    state.infoModalEntityType = infoModalEntityType
  },
  setInitialState (state, initialState){
    state.areCookiesAccepted = initialState.areCookiesAccepted
    state.browserMaxGenerations = initialState.browserMaxGenerations
  },
  setRenderOptions (state, renderOptions) {
    state.generationRelativeWidth = renderOptions.generationRelativeWidth,
    state.maxAncestorGenerations = renderOptions.maxAncestorGenerations,
    state.maxDescendentGenerations = renderOptions.maxDescendentGenerations,
    state.showAncestors = renderOptions.showAncestors,
    state.showDecendents = renderOptions.showDecendents,
    state.showGenderInTree = renderOptions.showGenderInTree,
    state.treeRenderOptionVersion = shortUUID.generate(),
    state.treeZoom = renderOptions.treeZoom
  },
  setRenderedIndividual (state, individual) {
    const individualId = individual.id
    const instant = state.renderedIndividuals[individualId]

    if (instant){
      instant.count++
      Vue.set(state.renderedIndividuals, individualId, instant) 
    } else {
      const name = individualUtils.getName(individual)
      const nameLength = name ? name.length : 0
      Vue.set(state.renderedIndividuals, individualId, { count: 1, nameLength: nameLength})
    }
  },
  setRenderedUnion (state, union) {
    Vue.set(state.renderedUnions, union.id, union.orderKey) 
  }
}