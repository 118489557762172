import _ from 'lodash'
import * as individualUtils from '@/logic/family/individualUtils'

// Create a map of names whose values are a set of individuals with that name.  All names converted to lower case.
export function buildIndividualNameSearchMap(individualsArray) {
  const map = new Map()

  // Let's get all the names for each individual
  for (const individual of individualsArray) {
    const names = _.get(individual, 'names', [])
    
    for (const name of names) {
      // Get all the names we are interested in.
      const names1 = _.get(name, 'fullName', '').toLowerCase().split(' ')
      const names2 = _.get(name, 'surname', '').toLowerCase().split(' ')
      const names3 = _.get(name, 'marriedName', '').toLowerCase().split(' ')
      const names4 = _.get(name, 'nickname', '').toLowerCase().split(' ')

      // ... and combine them together into a unique list
      const names = new Set([...names1, ...names2, ...names3, ...names4])

      // Work through each of this individual's names
      for (const name of Array.from(names).filter(x => x)) {
        let s = map.get(name)

        // Add this new name to our map
        if (!s) {
          s = new Set()
          map.set(name, s)
        }
        
        // Add to this individual to this name's set
        s.add(individual.id)
      }
    }
  }

  return map
}

export function loadIndividuals(familyData) {
  const individualsArray = []

  for (const key of Object.getOwnPropertyNames(familyData.individuals || {})) {
    individualsArray.push(familyData.individuals[key])
  }

  return individualsArray
}

// Splits the search text into multiple name parts and finds all individuals that match all the names
// Typeahead control does not support searching on multiple name parts.  To get around that set  `
//     v-model="searchText" 
//     :serializer="item => searchText" 
export function individualNameSearch(searchText, familyData, individualSearchMap, minimumSearchChars, defaultResult, language) {
  const re = /\s*[\s|,|;|)|(]+\s*/   // At least one character, commas, colons, or parenthesis
  const searchNames = searchText.trim().toLowerCase().split(re)
  const totalSearchCharacters = searchNames.reduce((accumulator, currentValue) => accumulator + currentValue.length, 0)
  let individuals = []
  
  // The user must type the minimum number of characters either in a single search element or spread between multiple search characters
  if (totalSearchCharacters < minimumSearchChars) {
    individuals = defaultResult
  } else {
    let individualIds = new Set()

    // Search all the names in the search text.  We allow a person to type 'Bo Sm' and this will search 'Bob' and 'Smith'
    for (let n = 0; n < searchNames.length; n++) {
      const searchName = searchNames[n]
      const subset = new Set()

      // Search all the names of our family
      for (const [name, individualIds] of individualSearchMap) {

        // If the search name is in the name
        if (name.includes(searchName)) {

          // Add each of individuals in the set to the list of individuals found so far
          for (const individualId of individualIds) {
            subset.add(individualId)
          }
        }
      }

      if (n === 0) {
        individualIds = subset
      } else {
        individualIds = new Set([...individualIds].filter(x => subset.has(x)))  // Intersection between the two sets
      }
    }

    individuals = Array.from(individualIds).map(x => familyData.individuals[x])
  }

  // Filter out blank names and sort by those names.
  return individuals
    .map((individual) => { return {
        individualId: individual.id,
        name: individualUtils.getFormalName(individual),
        gender: individualUtils.getGender(individual),
        birthDeathSummary: individualUtils.getBirthDeathSummary(individual, language)
      }
    })
    .filter(x => !!x.name)  
    .sort( (a,b) => {
      const namea = a.name.toLowerCase()
      const nameb = b.name.toLowerCase()

      if (namea < nameb)
        return - 1
      else 
        return 1
    })
}

export function individualNameSearchHeader(searchText, familyData, individualSearchMap, language) {
  const limit = 10
  const minimumSearchChars = 2
  const individuals = individualNameSearch(searchText, familyData, individualSearchMap, minimumSearchChars, [], language)
  return Object.freeze(individuals.slice(0, limit))   
}

export function individualNameSearchGrid(searchText, familyData, individualSearchMap, individualsArray, language) {
  return Object.freeze(individualNameSearch(searchText, familyData, individualSearchMap, 1, individualsArray, language))
}