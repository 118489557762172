
<template>
  <div :class="classAncestorWrapper">
    <div :class="classChildLineage(ancestorUnit)" />
    <div class="ancestor-individual">
      <Child :individual-id="ancestorUnit.individual.id"
             :key="ancestorUnit.individual.id"
             :show-unions="showUnions(ancestorUnit)"
             v-if="ancestorUnit.individual" />
    </div>
    <div :class="classParentLineage(ancestorUnit)" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Child from "@/components/tree/Child"
  
  export default {
    name: 'AncestorUnit',
    components: {
      Child
    },
    props: {
      ancestorUnit: {type: Object, required: true},
      isSiblingArea: {type: Boolean, required: true},
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState('viewState', [
        'maxDescendentGenerations',
        'showAncestors',
        'showDecendents'
      ]),
      classAncestorWrapper: function () {
        return {
          "ancestor-wrapper": true
        }
      }
    },
    methods: {
      classChildLineage: function (ancestorUnit) {
        return {
          lineLeft: ancestorUnit.hasMoreChildren,
          lineTop: ancestorUnit.individual && !ancestorUnit.isOrphan
        }
      },
      classParentLineage: function (ancestorUnit) {
        return {
          "ancestor-right": true,
          lineRight: ancestorUnit.isBetweenParents,
          lineTop: ancestorUnit.isParent
        }
      },
      showUnions: function (ancestorUnit) {
        return ancestorUnit.generationId === 0 && this.showDecendents ? true : false
      }
    }
  }
</script>