<template>
  <span v-if="hasDescription">
    <b-icon v-if="displayInfoModal"
            @click.stop.prevent="onShowHeaderDetails"
            class="hover-pointer"
            icon="info-square" />
    <b-icon v-if="!displayInfoModal"
            :id="id"
            @click.stop.prevent 
            class="hover-pointer"
            icon="info-square" />
    <b-popover :target="id"
               :placement="placement"
               triggers="click blur"
               custom-class="popover-wide"
               title="Description">
      <table class="info-header">
        <tr v-if="familyDescription">
          <td colspan="2" v-html="familyDescriptionHtml" />
        </tr>
        <tr v-if="author">
          <td>Author:</td>
          <td>{{ author }}</td>
        </tr>
        <tr v-if="sourceUrl">
          <td>Source:</td>
          <td><b-link :href="sourceUrl">{{ sourceUrl }}</b-link></td>
        </tr>
      </table>
    </b-popover>
  </span>
</template>

<script>
import marked from 'marked'

  export default {
    name: 'FamilyDescription',
    props: {
      displayInfoModal: {type: Boolean, default: false},
      familyDescription: {type: String},
      id: {type: String, required: true},
      placement: {type: String, required: true},
      author: {type: String},
      sourceUrl: {type: String}
    },
    data() {
      return {
      }
    },
    computed: {
      familyDescriptionHtml: function () {
        return marked.parseInline(this.familyDescription || '')
      },
      hasDescription: function () {
        return this.familyDescription || this.author || this.sourceUrl || this.displayInfoModal ? true : false
      }
    },
    methods: {
      onShowHeaderDetails: function () {
        this.$store.dispatch('viewState/loadInfoModal',{infoModalEntityType: 'header'} )
        this.$bvModal.show('infoModal')
      }
    }
  }
</script>