<template>
  <div v-if="!areCookiesAccepted" class="banner-cookie-notice text-center" data-nosnippet="data-nosnippet">
    <h3>This website uses cookies</h3>
    By continuing to browse our site you agree to our use of 
    <b-link href="/info/privacy#cookies" target="_blank">cookies</b-link>, 
    <b-link href="/info/terms" target="_blank">Terms of Use</b-link>, and 
    <b-link href="/info/privacy" target="_blank">Privacy Policy</b-link>.
    <div>
      <b-button pill variant="outline-secondary" size="sm" @click="acceptCookies">Allow All Cookies</b-button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'BannerCookieWarning',
    computed: {
      ...mapState('viewState', [
        'areCookiesAccepted'
      ])
    },
    methods: {
      acceptCookies: function () {
        this.$store.dispatch('viewState/acceptCookies')
      }
    }
  }
</script>

<style scoped>
  .banner-cookie-notice {
    color: white;
    background:midnightblue;
    padding: 0 16px;
    width: 100%;
  }

  .banner-cookie-notice > a {
    color: gold;
  }

  .banner-cookie-notice > div {
    padding: 5px 0;
  }

  .banner-cookie-notice > div > button {
    background-color: gold;
    color: midnightblue;
  }
</style>