<template>
  <fieldset v-if="hasCitations" class="info-section border">
    <legend class="w-auto">Footnotes</legend>
    <b-table-simple hover stacked="md" class="info-table">
      <b-tbody class="align-top">
        <tr v-for="(citation,a) in citations" :key="`citation-row-${a}`">
          <td class="text-nowrap text-right zero-width" v-html="cardinal(citation)" />
          <td>
            <div v-html="footnote(citation)" />
            <InfoSubfact v-for="(subfact,b) in citation.subfacts" :key="`citation-subfact-${b}`" :subfact="subfact" />
          </td>
        </tr>
      </b-tbody>
    </b-table-simple>
  </fieldset>
</template>

<script>
import marked from 'marked'
import InfoSubfact from "@/components/familyInfo/InfoSubfact"

  export default {
    name: 'InfoCitationSection',
    components: {
      InfoSubfact
    },
    props: {
      citations: {type: Array, required: true}
    },
    computed: {
      hasCitations: function () {
        return this.citations.length > 0 ? true : false
      },
      legend: function () {
        return this.citationNumber ? `${this.name}<sup>${this.citationNumber}</sup>` : this.name
      }
    },
    methods: {
      cardinal: function (citation) {
        return `${citation.citationNumber}.`
      },
      footnote: function (citation) {
        return marked.parseInline(citation.footnote || '')
      }
    }
  }
</script>