import * as gedcomToJson from "@/logic/gedcom/gedcomToJson"
import { objToJsonGzippedBase64, stringToGzippedBase64} from '@/logic/general/gzipUtils'
import { generateSitemapFile } from '@/logic/seo/sitemapUtils'

export function isAdministrator (familyAuthorityId) {
  return familyAuthorityId > 1
}

export function isOwner (familyAuthorityId) {
  return familyAuthorityId === 3
}

export function canUpdateFamilyUser (operatorFamilyAuthorityId, targetFamilyAuthorityId) {
  return isAdministrator(operatorFamilyAuthorityId) && operatorFamilyAuthorityId >= targetFamilyAuthorityId
}

export function canDeleteInvitation (operatorFamilyAuthorityId, operatorUserId, sponsorUserId) {
  return isOwner(operatorFamilyAuthorityId) ||  operatorUserId === sponsorUserId
}

export async function processFamilyData (cleanData, familyId, familyName) {
  const result = {}
  result.gedcomDataCompressed = await stringToGzippedBase64(cleanData)
  const familyData = gedcomToJson.convert(cleanData)
  result.familyDataCompressed = await objToJsonGzippedBase64(familyData)
  const sitemap = generateSitemapFile(familyData.individuals, familyId, familyName)
  result.sitemapCompressed = await stringToGzippedBase64(sitemap)
  return result
}