<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <validation-provider name=".GED file" 
                           :rules="{ ext:'ged', required: true }" 
                           :custom-messages="{ 'ext':'Please select a .ged file', 'required': 'A .ged file is required' }" 
                           v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="GED File:"
          label-align-sm="right"
          label-for="file-name-box">
          <b-form-file id="file-name-box"
                       ref="file-name"
                       v-model="fileName"
                       :state="validationContext.errors.length === 0"
                       placeholder="Choose a .ged file or drop it here..."
                       drop-placeholder="Drop .ged file here..."
                       class="mt-1"
                       tabindex="3" />
          <b-form-invalid-feedback id="givenName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="d-flex justify-content-end">
        <b-button type="button" variant="outline-secondary" tabindex="12" @click="resetPage()">Cancel</b-button>
        <b-button :disabled="postState.inProgress"
                  type="submit"
                  variant="primary"
                  tabindex="11"
                  class="ml-2">
          Submit
        </b-button>
      </div>
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import ApiPostFeedback from "@/components/ApiPostFeedback"
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import * as urlUtils from '@/logic/general/urlUtils'
  import { convertToUtf8 } from '@/logic/gedcom/characterSetUtils'
  import { processFamilyData } from '@/logic/family/familyAdminUtils'

  export default {
    name: 'ImportExistingFamily',
    components: {
      ApiPostFeedback
    },
    props: {
      familyName: {type: String }
    },
    data() {
      return {
        form: {
          familyDataCompressed: null,
          gedcomDataCompressed: null,
          sitemapCompressed: null
        },
        fileName: null,
        postState: {}
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()
        const familyId = this.$route.params.familyId
        const file = this.$refs['file-name'].files[0]
        
        file.arrayBuffer().then(async rawData => {
          const cleanData = convertToUtf8(rawData)
          Object.assign(this.form, await processFamilyData(cleanData, familyId, this.familyName))

          this.axios.post(apiUtils.buildAPIUrl(`familyAdmin/upload/${familyId}`), this.form)
            .then(response => {
              const payload = { vm: this, familyId: response.data.familyId}
              
              this.$store.dispatch('family/loadFamily', payload)
                .then(() => {
                  this.postState = response.postState
                  const pathNew = urlUtils.getFamilyURL(response.data.familyId, response.data.familyNameBase)
                  this.$router.push(pathNew)
                })
                .catch(error => {
                  this.postState = error.postState
                  validationUtils.resetFormDelayed(this)
                })
            })
            .catch(error => {
              this.postState = error.postState
              validationUtils.resetFormDelayed(this)
            })
        }).catch(error => {
          this.postState = {
            errorType: "User", //APIErrorTypes.USER,
            errorMessages: [{message: `Error while processing GED file on line ${error.row}: ${error.message}`}]
          }
        })

      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          familyDataCompressed: null,
          gedcomDataCompressed: null
        }
        validationUtils.resetFormImmediate(this)
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>