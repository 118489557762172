<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <validation-provider name="Given Name" :rules="{required: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Given Name:"
          label-align-sm="right"
          label-for="given-name-box">
          <b-form-input id="given-name-box"
                        type="text"
                        v-model="form.givenName"
                        :state="getValidationState(validationContext)"
                        tabindex="1" />
          <b-form-invalid-feedback id="givenName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider name="Family Name" :rules="{required: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Family Name:"
          label-align-sm="right"
          label-for="family-name-box">
          <b-form-input id="family-name-box"
                        type="text"
                        v-model="form.familyName"
                        :state="getValidationState(validationContext)"
                        tabindex="2" />
          <b-form-invalid-feedback id="familyName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <input id="dummyUserName" type="text" style="display:none;" autocomplete="username">
      <validation-provider vid="password" name="Password" :rules="{required: true }" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Re-enter Password:"
          label-align-sm="right"
          label-for="password-box">
          <b-form-input id="password-box"
                        type="password"
                        v-model="form.password"
                        :state="getValidationState(validationContext)"
                        autocomplete="new-password"
                        tabindex="3" />
          <b-form-invalid-feedback id="password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="d-flex justify-content-end">
        <b-button type="button" variant="outline-secondary" tabindex="12" @click="resetPage()">Cancel</b-button>
        <b-button :disabled="postState.inProgress"
                  type="submit"
                  variant="primary"
                  tabindex="11"
                  class="ml-2">
          Submit
        </b-button>
      </div>
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"

  export default {
    name: 'EditUserName',
    components: {
      ApiPostFeedback
    },
    props: {
      initialFamilyName: {type: String, required: true},
      initialGivenName: {type: String, required: true}
    },
    data() {
      return {
        form: {
          familyName: this.initialFamilyName,
          givenName: this.initialGivenName,
          password: ""
        },
        postState: {}
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()
        this.axios.post(apiUtils.buildAPIUrl('account/userInfoUpdate'), this.form)
          .then(response => {
            this.postState = response.postState
            this.resetForm()
            this.resetPage()
          })
          .catch(error => {
            this.postState = error.postState
            this.form.password = ""
            validationUtils.resetFormDelayed(this)
          })
      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          familyName: "",
          givenName: "",
          password: ""
        }
        validationUtils.resetFormImmediate(this)
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>