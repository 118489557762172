<template>
  <div>
    <h5 @click="onBack()" class="back-to-parent px-2"><b-icon icon="arrow-left" /> Manage Families</h5>
    <h3 class="px-2">
      {{ familyName }}
    </h3>
    <b-card no-body>
      <b-tabs card pills>
        <b-tab title="General">
          <h4>General<b-badge v-if="mode!==1 && isAdministrator" @click="setMode(1)" class="action-icon" variant="primary"><b-icon icon="pencil" />Edit</b-badge></h4>
          <b-table-simple stacked="md" class="narrow-container-2">
            <b-tbody>
              <b-tr :class="getInfoTableRowClass(1)">
                <b-td>
                  <b-table-simple v-if="mode!==1" borderless>
                    <b-tbody>
                      <b-tr>
                        <b-th class="text-nowrap">Base Family Name:</b-th>
                        <b-td>{{ familyData.familyNameBase }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">
                          Description:
                          <span v-if="isAdministrator">
                            <b-popover target="familyDescriptionHelp"
                                       placement="bottomright"
                                       triggers="click blur"
                                       custom-class="popover-wide">
                              This field supports <b-link href="https://commonmark.org/help/tutorial/" target="_blank">Markdown</b-link>.
                            </b-popover>
                            <b-icon 
                              id="familyDescriptionHelp"
                              class="hover-pointer"
                              icon="info-square" />
                          </span>
                        </b-th>
                        <b-td v-html="familyDescriptionHtml" />
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">Author:</b-th>
                        <b-td>{{ familyData.author }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">Source Url:</b-th>
                        <b-td><b-link v-if="familyData.sourceUrl" :href="familyData.sourceUrl">{{ familyData.sourceUrl }}</b-link></b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">
                          Hide Gender:
                          <b-icon 
                            class="hover-pointer"
                            icon="info-square" 
                            v-b-popover.click.blur.bottomright="'If yes, gender will always be hidden in summaries and the display option \'Show Gender in Tree\' will be ignored.'" />
                        </b-th>
                        <b-td>{{ familyData.suppressGender ? "Yes" : "No" }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">
                          Is Public:
                          <b-icon 
                            class="hover-pointer"
                            icon="info-square" 
                            v-b-popover.click.blur.bottomright="'If yes, this family can be viewed by anyone.'" />
                        </b-th>
                        <b-td>{{ familyData.isPublic ? "Yes" : "No" }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">
                          Is Showcased:
                          <b-icon 
                            class="hover-pointer"
                            icon="info-square" 
                            v-b-popover.click.blur.bottomright="'If yes, this family is available as one of the random families that could be shown when a new user comes to the website if it is also public.'" />
                        </b-th>
                        <b-td>{{ familyData.isShowcased ? "Yes" : "No" }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <EditFamilyDetails v-if="mode===1" @force-reset="reset()" :initial-state="familyData" />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <h4>Personal<b-badge v-if="mode!==4" @click="setMode(4)" class="action-icon" variant="primary"><b-icon icon="pencil" />Edit</b-badge></h4>
          <b-table-simple stacked="md" class="narrow-container-2">
            <b-tbody>
              <b-tr :class="getInfoTableRowClass(4)">
                <b-td>
                  <b-table-simple v-if="mode!==4" borderless>
                    <b-tbody>
                      <b-tr>
                        <b-th class="text-nowrap">
                          Family Alias:
                          <b-icon 
                            class="hover-pointer"
                            icon="info-square" 
                            v-b-popover.click.blur.bottomright="'This is your name for this family.  This is the name that will appear instead of the base family name for you only.'" />
                        </b-th>
                        <b-td>{{ familyData.familyUserOperator.familyNameAlias }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">
                          Is Email Visible?:
                          <b-icon 
                            class="hover-pointer"
                            icon="info-square" 
                            v-b-popover.click.blur.bottomright="'Whether or not your email address is visible to other members of this private family.'" />
                        </b-th>
                        <b-td>{{ familyData.familyUserOperator.isEmailVisible ? "Yes" : "No" }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-nowrap">Authority:</b-th>
                        <b-td>{{ familyAuthorityText }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <EditFamilyUserDetails v-if="mode===4" @force-reset="reset()" :family-user-data="familyData.familyUserOperator" :operator-family-authority-id="operatorFamilyAuthorityId" />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>

        <b-tab title="Members">
          <FamilyUsers :family-users="familyData.familyUsers" :operator-family-authority-id="operatorFamilyAuthorityId" @force-reset="reset()" @set-mode="setMode()" />
        </b-tab>

        <b-tab title="Invitees">
          <FamilyInvitationsGiven :family-invitations="familyData.familyInvitations" :operator-family-authority-id="operatorFamilyAuthorityId" @force-reset="reset()" />
        </b-tab>

        <b-tab v-if="isOwner" title=".GED">
          <h4>.GED File<b-badge v-if="mode!==2" @click="setMode(2)" class="action-icon" variant="primary"><b-icon icon="upload" />Reimport</b-badge></h4>
          <b-table-simple hover stacked="md" class="narrow-container-2">
            <b-tbody>
              <b-tr :class="getInfoTableRowClass(2)">
                <b-th>Last Import</b-th>
                <b-td>
                  <span v-if="mode!==2">{{ lastImportFormatted }}</span>
                  <ImportExistingFamily v-if="mode===2" @force-reset="reset()" :family-name="familyName" />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import marked from 'marked'
import { checkRouteLoggedIn } from '@/logic/general/authenticationUtils'
import * as enums from '@/logic/enums'
import * as apiUtils from '@/logic/general/apiUtils'
import { standardDateFormat } from '@/logic/general/dateUtils'
import * as familyAdminUtils from '@/logic/family/familyAdminUtils'
import EditFamilyDetails from '@/components/familyAdmin/EditFamilyDetails'
import EditFamilyUserDetails from '@/components/familyAdmin/EditFamilyUserDetails'
import FamilyInvitationsGiven from '@/components/familyAdmin/FamilyInvitationsGiven'
import FamilyUsers from '@/components/familyAdmin/FamilyUsers'
import ImportExistingFamily from '@/components/familyAdmin/ImportExistingFamily'

export default {
  components: {
    EditFamilyDetails,
    EditFamilyUserDetails,
    FamilyInvitationsGiven,
    FamilyUsers,
    ImportExistingFamily,
  },
  data() {
    return {
      busy: false,
      mode: 0,
      familyData: { 
        familyUserOperator: {},
        familyUsers: [],
        familyInvitations: []
      },     
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => checkRouteLoggedIn(vm, next))
  },
  computed: {
    ...mapGetters('authentication', [
      'isLoggedIn'
    ]),
    ...mapState('authentication', [
      'jwt'
    ]),
    currentUser: function () {
      return this.jwt.nameid
    },
    familyAuthorityText: function () {
      return enums.familyAuthorityText(this.familyData.familyUserOperator.familyAuthorityId)
    },
    familyDescriptionHtml: function () {
      return marked.parseInline(this.familyData.familyDescription || '')
    },
    familyId: function () {
      return this.$route.params.familyId
    },
    familyName: function () {
      return this.familyData.familyUserOperator.familyNameAlias || this.familyData.familyNameBase
    },
    isAdministrator: function () {
      return familyAdminUtils.isAdministrator(this.operatorFamilyAuthorityId)
    },
    isOwner: function () {
      return familyAdminUtils.isOwner(this.operatorFamilyAuthorityId)
    },
    lastImportFormatted: function () {
      if (this.familyData.lastImport) {
        const lastImport = new Date(this.familyData.lastImport)
        return standardDateFormat.format(lastImport)
      } else {
        return ''
      }
    },
    operatorFamilyAuthorityId: function () {
      return this.familyData.familyUserOperator.familyAuthorityId
    }
  },
  methods: {
    getInfoTableRowClass: function (mode){
      return { 'embedded-form':this.mode===mode}
    },
    onBack: function () {
      this.$router.push({path: `/admin/family/management`})
    },
    reset: function () {
      this.busy = true
      this.mode = 0
      this.axios.get(apiUtils.buildAPIUrl(`familyAdmin/${this.familyId}`))
          .then(response => {
            this.familyData = response.data    
            this.busy = false     
          })
    },
    setMode: function(mode) {
      this.mode = mode
      this.familyData.familyUsers.forEach(value => {
        if (value._onShowDetails) {
          this.$set(value, '_onShowDetails', false)
        }
      })
    }
  },
  created() {
    this.reset()
  },
  watch: {
    isLoggedIn: function (newValue) {
      if (newValue === false) {
        this.$router.push({ path: `/` })
      }
    }
  }
}
</script>