<template>
  <b-jumbotron header="Welcome to Relative To Me!" lead="">
    <ul class="narrow-container-2">
      <li>
        <h6>Draw an individual's family tree</h6>
        <p class="mb-1">
          The current family is shown at the top left. Use the search box beside it on the right to find an individual within that family. Once you have selected someone from the list, their family tree will be drawn for you.
          <i>Hint: You can type multiple partial names.  Example: "Dia Spe" might show "Diana Spencer".</i>
        </p>
      </li>
      <li>
        <h6>Change the display options</h6>
        <p class="mb-1">You can control whether or not you want to see the individual's ancestors, descendants, or both by clicking on the <b-link v-b-modal.displayOptionsModal>Display Options</b-link> in the header.</p>
      </li>
      <li>
        <h6>Switch the current family</h6>
        <p class="mb-1">You can change the current family within the <b-link v-b-modal.displayOptionsModal>Display Options</b-link> or by <b-link :to="'/family/selection'">clicking</b-link> on the name of the current family shown at the top left.</p>
      </li>
      <li>
        <h6>Access private families</h6>
        <p class="mb-1">
          If you are logged in, you may be authorized to see additional private families. Select the <b-link v-b-modal.authenticationModal>Login</b-link> link in the header to login. 
          Any new invitations will be shown to you automatically once you are logged in. Once you have accepted an invitation for a family, that family will be listed when you change families.
        </p> 
      </li>
      <li>
        <h6>Advanced Features</h6>
        <p class="mb-1">If you are logged in you will have additional privileges:</p>       
        <ul>
          <li>Import a GEDCOM (.ged) file from family tree websites such as Ancestry.com or Geni.com and invite others to see it.</li>
          <li>Print a large family tree.</li>
          <li>Build a new family tree <span class="warning"><i><b>(Under Development)</b></i></span></li>
        </ul>
        <p class="pt-2">Some features may require a subscription in the future; however, that feature is still under development so there are no limits at this time.</p>
      </li>
    </ul>
  </b-jumbotron>
</template>

<script>

export default {
  data() {
    return {}
  }
}
</script>