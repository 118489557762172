import _ from 'lodash'
import * as cultureUtils from '@/logic/culture/cultureUtils'
import * as familyFactUtils from '@/logic/family/familyFactUtils'

export function getDetails(union,language) {
  const marriageEvent = familyFactUtils.getGroupMemberByCodeFirst(union, 'events','MARR')
  const divorceEvent = familyFactUtils.getGroupMemberByCodeFirst(union, 'events','DIV')

  const start = cultureUtils.formatDate(_.get(marriageEvent, 'date.date'), language)
  const end = cultureUtils.formatDate(_.get(divorceEvent, 'date.date'), language)

  if (start && end)
    return `${start} - ${end} `
  else 
    return start || end || '&nbsp;'
}