<template>
  <fragment>
    <div class="d-flex flex-wrap justify-content-between">
      <div>
        <h4>
          Authorized Members
          <b-icon class="hover-pointer"
                  icon="info-square" 
                  v-b-popover.click.blur.bottomright="'If this is a private family, these are all the users that are authorized to view it.  If this is a public family this list is still important as it will list all those who can make changes.'" />
        </h4>
      </div>
      <div>
        <b-pagination
          v-if="hasUsersPageTwo"
          v-model="usersCurrentPage"
          :per-page="usersPerPage"
          :total-rows="usersRows"
          aria-controls="my-table"
          align="right" />
      </div>
      <div>
        <b-form-group
          class="narrow-container-0"
          label-cols-sm="4"
          label="Name Filter:"
          label-align-sm="right"
          label-for="users-filter-box">
          <b-form-input id="users-filter-box" 
                        type="text" 
                        v-model="usersFilter" 
                        tabindex="1" />
        </b-form-group>
      </div>
    </div>

    <b-table :busy="busy"
             :current-page="usersCurrentPage"
             :items="familyUsers" 
             :fields="usersFields"
             :filter="usersFilter"
             :per-page="usersPerPage"
             @filtered="onUsersFiltered"
             responsive
             show-empty
             stacked="md"
             striped>
      <template #head(email)>
        <div>
          Email
          <b-icon class="hover-pointer"
                  icon="info-square" 
                  v-b-popover.click.blur.bottomright="'The user\'s email address.  The user may elect to hide their email address to other members of this family.'" />
        </div>
      </template>
      <template #head(familyAuthorityId)>
        <div>
          Authority
          <b-icon class="hover-pointer"
                  icon="info-square" 
                  v-b-popover.click.blur.bottomright="'Represents the user\'s ability to manage the family.'" />
        </div>
      </template>
      <template #head(familyNameAlias)>
        <div>
          Family Name Alias
          <b-icon class="hover-pointer"
                  icon="info-square" 
                  v-b-popover.click.blur.bottomright="'The user\'s name for this family. If the user has not specified anything here, to that person, this family will be known by the Base Family Name.'" />
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button v-if="!data.detailsShowing && isFamilyUserEditable(data)"
                  @click="showRowDetails(data)"
                  class="action-icon"
                  variant="primary"
                  size="sm">
          <b-icon icon="pencil" />Edit
        </b-button>
      </template>
      <template #row-details="data">
        <EditFamilyUserDetails @force-reset="reset()" :family-user-data="data.item" :operator-family-authority-id="operatorFamilyAuthorityId" />
      </template>
      <template #table-busy>
        <TableBusy />
      </template>
    </b-table>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'
import * as enums from '@/logic/enums'
import * as familyAdminUtils from '@/logic/family/familyAdminUtils'
import EditFamilyUserDetails from '@/components/familyAdmin/EditFamilyUserDetails'
import TableBusy from "@/components/TableBusy"

export default {
  components: {
    EditFamilyUserDetails,
    TableBusy
  },
  props: {
    familyUsers: {type: Array},
    operatorFamilyAuthorityId: {type: Number}
  },
  data() {
    return {
      busy: false,
      usersFields: [
        {key: "actions", tdClass: "info-table-action"},
        {key: "userName", label: "User"},
        {key: "email", label: "Email"},
        {key: "familyAuthorityId", label: "Authority", formatter: this.formatFamilyAuthority},
        {key: "familyNameAlias"}
      ],
      usersFilter: '',
      usersCurrentPage: 1,
      usersPerPage: 10,
      usersRows: 0
    }
  },
  computed: {
    ...mapState('authentication', [
      'jwt'
    ]),
    currentUser: function () {
      return this.jwt.nameid
    },
    hasUsersPageTwo: function () {
      return this.usersRows > this.usersPerPage
    }
  },
  methods: {
    formatFamilyAuthority: function (value, key, item) {
      return enums.familyAuthorityText(value)
    },
    isFamilyUserEditable: function (data) {
      if (data.item.userId === this.currentUser) {
        return true
      } else if (familyAdminUtils.canUpdateFamilyUser(this.operatorFamilyAuthorityId, data.item.familyAuthorityId)) {
        return true
      } else {
        return false
      }
    },
    onUsersFiltered(filteredItems) {
      this.usersRows = filteredItems.length
      this.usersCurrentPage = 1
    },
    reset: function () {
      this.$emit('force-reset')
    },
    showRowDetails: function(data) {
      this.$emit('set-mode', 3)
      data.toggleDetails()
    }
  },
  watch: {
    familyUsers: function (newValue) {
      this.usersRows = newValue ? newValue.length : 0
    }
  }
}
</script>