<template>
  <table class="info-offspring-container" v-if="union.kids.length > 0">
    <fragment v-for="(kid, k) in union.kids" :key="kid.id">
      <tr>
        <td class="offspring-top" />
        <td rowspan="2">
          <div class="child-container child-container-normal">
            <div class="child-title child-title-normal">
              <individual-name :individual-id="kid.id" :show-buttons="true" />
              <individual-summary :individual-id="kid.id" />
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td :class="{ 'offspring-more': hasMoreKids(union, k) }" />
      </tr>
    </fragment>
  </table>    
</template>

<script>
import IndividualName from "@/components/tree/IndividualName"
import IndividualSummary from "@/components/tree/IndividualSummary"

  export default {
    name: 'InfoKidList',
    components: {
      IndividualName,
      IndividualSummary
    },
    props: {
      union: {type: Object, required: true},
    },
    methods: {
      hasMoreKids: function (union, k) {
        return k < union.kids.length - 1
      }
    },
  }
</script>