import { accountProviderText } from '@/logic/enums'

export function eventLogin (vm) {
  const jwt = vm.$store.state.authentication.jwt
  const loginProvider = jwt['provider']

  // Analytics
  vm.$gtag.event('login', {
    'method': accountProviderText(loginProvider)
  })
}