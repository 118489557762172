<template>
  <div :class="classIndividualDetails">
    <gender-icon :individual="individual" :is-in-tree="isInTree" />
    {{ getBirthDeathSummary }}
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import * as individualUtils from '@/logic/family/individualUtils'
  import GenderIcon from "@/components/tree/GenderIcon"
  
  export default {
    name: 'IndividualSummary',
    components: {
      GenderIcon
    },
    props: {
      individualId: {type: String, required: true},
      isInTree: {type: Boolean, default: false}
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState('family', [
        'familyData',
      ]),
      ...mapState('viewState', [
        'language'
      ]),
      classIndividualDetails: function () {
        return {
          "individual-details": this.isInTree,
          "individual-details-info": !this.isInTree
        }
      },
      getBirthDeathSummary: function () {
        return individualUtils.getBirthDeathSummary(this.individual, this.language)
      },
      individual: function () {
        return individualUtils.getIndividual(this.familyData, this.individualId)
      }
    }
  }

</script>