import _ from 'lodash' 
import * as familyFactUtils from '@/logic/family/familyFactUtils'
import * as individualUtils from '@/logic/family/individualUtils'

export function setHeaderTags(to, vm) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = performSubstitutions(nearestWithTitle.meta.title, to.params, vm)

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, performSubstitutions(tagDef[key], to.params, vm))
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag))  // Add the meta tags to the document head.
  }
}

export function performSubstitutions(template, params, state) {
  let familyTitle = params.familyName || params.familySEO
  let familyDescription = params.familyName || params.familySEO
  let individualTitle= params.individualName || params.individualSEO
  let individualDescription = params.individualName || params.individualSEO

  if (state) {
    const stateFamilyDescription = state.family.familyDescription
    familyTitle = `The ${state.family.familyName} genealogical tree`   
    familyDescription = stateFamilyDescription ? stateFamilyDescription : `${familyTitle}.`

    if (params.individualId) {
      const language = state.viewState.language
      const familyData = state.family.familyData
      const individual = familyData.individuals[params.individualId]
      const standardName = individualUtils.getName(individual)
      const formalName = individualUtils.getFormalName(individual)
      const titleAttributes = familyFactUtils.getGroupMemberByCode(individual, 'attributes','TITL')
      const titles = titleAttributes.map(item => _.get(item, 'value')).join(', ')
      const occupationAttribute = familyFactUtils.getGroupMemberByCodePrimaryOrFirst(individual, 'attributes','OCCU')
      const occupation = _.get(occupationAttribute, 'value')
      const birthDeathSummary = individualUtils.getBirthDeathSummary(individual, language)
      const birthDeathSummaryText = birthDeathSummary ? ` (${birthDeathSummary})` : ''
      const titleOccupationText = titles || occupation ? `, ${titles || occupation},` : ''
      individualTitle = `${formalName} family tree`
      individualDescription = `The detailed family tree of ${standardName}${birthDeathSummaryText}${titleOccupationText} from the ${state.family.familyName} genealogical tree.`
    }
  }

  return template
          .replace('FAMILY_TITLE', _.unescape(familyTitle))
          .replace('FAMILY_DESCRIPTION', _.unescape(familyDescription))
          .replace('INDIVIDUAL_TITLE', _.unescape(individualTitle))
          .replace('INDIVIDUAL_DESCRIPTION', _.unescape(individualDescription))
}