<template>
  <div :class="classesChildContainer">
    <div :class="classesTitle">
      <Individual :individual-id="individualId" :class="classIndividual(false)" />
      <Individual :individual-id="unions[0].spouse.id" :class="classIndividual(true)" v-if="hasUnions && hasSpouse(unions[0])" />
    </div>

    <fragment v-for="(union, u) in unions" :key="union.id">
      <div class="relationship-container" v-if="hasSpouse(union)" :key="union.orderKey">
        <table>
          <tr v-if="u!==0">
            <td class="relationship-spacer" v-for="n in spacerCount(u)" :key="n" />
            <td class="relationship-next">
              <Individual :individual-id="union.spouse.id" class="spouse-next float-right text-right" v-if="hasSpouse(union)" />
            </td>
          </tr>
          <tr>
            <td class="relationship-spacer" v-for="n in spacerCount(u)" :key="n" />
            <td class="relationship-details" v-html="unionDetails(union)" @click="onShowUnionDetails(union.id)" />
          </tr>
        </table>
      </div>
      <table class="offspring-container" v-if="showOffspring(union)">
        <fragment v-for="(kid, k) in union.kids" :key="kid.id">
          <tr>
            <td class="offspring-spacer" v-for="n in spacerCount(u)" :key="n" />
            <td class="offspring-top" />
            <td rowspan="2">
              <Child :individual-id="kid.id"
                     :generation="offspringGeneration"
                     :show-unions="showUnions" />
            </td>
          </tr>
          <tr>
            <td class="offspring-spacer" v-for="n in spacerCount(u)" :key="n" />
            <td :class="{ 'offspring-more': hasMoreKids(union, k) }" />
          </tr>
        </fragment>
      </table>
    </fragment>
  </div>
</template>

<script>
  // Note:  In the template above, there the offspring-spacer cells are generated for both the top and the more instead of using rowspan="2"
  //        The reason for this is that this will not generate properly on Chrome for last child of all but the last spouse.  It works on Firefox.
  //        April 24, 2020   Chrome 80.0.3987.163 

  import { mapState } from 'vuex'
  import Individual from "@/components/tree/Individual"
  import * as familyUtils from '@/logic/family/familyUtils'
  import * as individualUtils from '@/logic/family/individualUtils'
  import * as unionUtils from "@/logic/family/unionUtils"
  
  export default {
    name: 'Child',
    components: {
      Individual
    },
    props: {
      individualId: {type: String, required: true},
      generation: {type: Number, default: 0},
      showUnions: {type: Boolean, default: true}
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState('family', [
        'familyData'
      ]),
      ...mapState('viewState', [
        'language',
        'maxDescendentGenerations',
        'showDecendents',
        'renderedUnions'
      ]),
      classesChildContainer: function () {
        return {
          'child-container': true,
          'child-container-normal': this.showUnions 
        }
      },
      classesTitle: function () {
        return {
          row: this.showUnions,
          'child-title': true,
          'child-title-normal': this.showUnions 
        }
      },
      focusIndividualId: function () {
        return this.$route.params.individualId
      },
      hasUnions: function () {
        return this.unions.length === 0 ? false : true
      },
      individual: function () {
        return individualUtils.getIndividual(this.familyData, this.individualId)
      },
      offspringGeneration: function () {
        return this.generation + 1
      },
      unions: function () {
        return this.showUnions ? this.getProcessedUnions() : []
      }
    },
    methods: {
      classIndividual: function(isSpouse) {
        return {
          "col-50": this.showUnions,
          "text-right": isSpouse,
          "text-left": !isSpouse,
          "focus-individual": this.individualId === this.focusIndividualId
        }
      },
      getProcessedUnions: function () {
        const unions = familyUtils.getFamilyUnions(this.familyData, this.individual).map(x => x.union)

        // The same union can appear twice within the same family tree.  For example, when cousins marry each other.
        // We will show both of these unions but the children will only appear in the first of these
        // This is done by recording the union within the state as rederedUnions.  We will also store the order of the spouses. For example: A-B
        // When we encounter the union again we only display it if the order is the same: A-B and will turn of children rendering if it is B-A
        for (const union of unions) {
          const spouseId = union.spouse ? union.spouse.id : 'spouse-unknown'
          union.orderKey = `${this.individualId}-${spouseId}`
          union.isFirstRender = union.orderKey === (this.renderedUnions[union.id] || union.orderKey)

          if (union.isFirstRender) {
            this.$store.dispatch('viewState/recordRenderedUnion',union)
          }
        }

        return unions
      },
      hasSpouse: function (union) {
        return union.spouse ? true : false
      },
      hasMoreKids: function (union, k) {
        return k < union.kids.length - 1
      },
      onShowUnionDetails: function (unionId) {
        this.$store.dispatch('viewState/loadInfoModal',{infoModalEntityId: unionId, infoModalEntityType: 'union'} )
        this.$bvModal.show('infoModal')
      },
      showOffspring: function (union) {
        return this.showDecendents && union.isFirstRender && this.offspringGeneration <= this.maxDescendentGenerations && union.kids.length > 0
      },
      spacerCount: function(u) {
        return this.unions.length - u - 1
      },
      unionDetails: function (union) {
        return unionUtils.getDetails(union, this.language)
      }
    }
  }
</script>