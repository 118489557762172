export const individualEventCodes = ['BIRT','ADOP','CHR','FCOM','BAPM','BARM','BASM','CONF','BLES','CHRA','ORDN','CENS','NATU','EMIG','IMMI','GRAD','EVEN','RETI','DEAT','BURI','CREM','PROB','WILL']
export const individualLDSEventCodes = ['BAPL','CONL','WAC','ENDL','SLGC']
export const individualAttributeCodes = ['TITL','EDUC','OCCU','DSCR','CAST','NATI','NMR','NCHI','PROP','RELI','RESI','FACT','IDNO','SSN']

export const unionEventCodes = ['MARC','ENGA','MARS','MARL','MARR','MARB','EVEN','CENS','ANUL','DIVF','DIV']
export const unionLDSEventCodes = ['SLGS']
export const unionAttributeCodes = ['NCHI','RESI']

export const headerGeneralFactMap = [
  {pathList: ['date.date','date.time'], labelKey: 'creationDate', separator: ' ', specialHandlingType: 'date', subfacts: [
    {pathList: 'copyright', labelKey: 'copyright'}
  ]},
  {pathList: 'submission', specialHandlingType: 'submission'},
  {pathList: 'submittors', specialHandlingType: 'submittor'}
]

export const individualGeneralFactMap = [
  {multivaluePath: 'names', pathList: 'fullName', citationPath: 'sources', labelKey: 'name', separator: ' ', subfacts: [
    {pathList: 'nickname', labelKey: 'nickname'},
    {pathList: 'type', labelKey: 'type', map: 'typeMap'},
    {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1},
    {pathList: 'restrictionNotice', labelKey: 'restrictionNotice', map: 'restrictionMap'}
  ]},
  {pathList: 'sex', labelKey: 'sex', map: 'sexMap'},
  {labelKey: 'aliases', factDisplayType: 2, subfacts: [
    {multivaluePath: 'aliases', pathList: 'pointer', pointerType: 'individual'},
    {multivaluePath: 'aliases', pathList: 'value'}
  ]},
  {labelKey: 'associations', factDisplayType: 2, subfacts: [
    {multivaluePath: 'associations', pathList: 'pointer', pointerType: 'individual', citationPath: 'sources', subfacts: [
      {pathList: 'relation', labelKey: 'relation', map: 'roleMap'},
      {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1}
    ]}
  ]}
]

export const individualOtherFactMap = [
  {pathList: 'multimedia', specialHandlingType: 'multimedia'},
  {labelKey: 'contact', factDisplayType: 2, private: true,  subfacts: [
    {pathList: ['address.address1','address.address2','address.city','address.subdivision','address.postalCode','address.country'], labelKey: 'address', separator: ', '},
    {pathList: 'address.phones', alternatePathList: 'phones', labelKey: 'phones', separator: ', ', isSensitive: true},
    {pathList: 'address.emails', alternatePathList: 'emails', labelKey: 'emails', separator: ', ', isSensitive: true},
    {pathList: 'address.faxes', alternatePathList: 'faxes', labelKey: 'faxes', separator: ', ', isSensitive: true},
    {pathList: 'address.urls', alternatePathList: 'urls', labelKey: 'urls', separator: ', '},
  ]},
  {pathList: ['lastChange.date.date','lastChange.date.time'], labelKey: 'lastChange', separator: ' ', specialHandlingType: 'date', subfacts: [
    {pathList: 'lastChange.notes', specialHandlingType: 'note', factFormatType: 1}
  ]},
  {pathList: 'submittors', specialHandlingType: 'submittor'},
  {pathList: 'recordFileNumber', labelKey: 'recordFileNumber'},
  {pathList: 'ancestralFileNumber', labelKey: 'ancestralFileNumber'},
  {pathList: ['reference.type', 'reference.value'], labelKey: 'reference', separator: ': '},
  {pathList: 'recordIdNumber', labelKey: 'recordIdNumber'},
  {pathList: 'restrictionNotice', labelKey: 'restrictionNotice', map: 'restrictionMap'}
]

export const unionGeneralFactMap = []

export const unionOtherFactMap = [
  {pathList: 'multimedia', specialHandlingType: 'multimedia'},
  {pathList: ['lastChange.date.date','lastChange.date.time'], labelKey: 'lastChange', separator: ' ', specialHandlingType: 'date', subfacts: [
    {pathList: 'lastChange.notes', specialHandlingType: 'note', factFormatType: 1}
  ]},
  {pathList: 'submittors', specialHandlingType: 'submittor'},
  {pathList: ['reference.type', 'reference.value'], labelKey: 'reference', separator: ': '},
  {pathList: 'recordIdNumber', labelKey: 'recordIdNumber'},
  {pathList: 'restrictionNotice', labelKey: 'restrictionNotice', map: 'restrictionMap'}
]

export const childToFamilyMap = [
  {pathList: 'pedigree', labelKey: 'pedigree', map: 'pedigreeMap'},
  {pathList: 'status', labelKey: 'status', map: 'statusMap'},
  {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1}
]

export const eventSubfactMap = [
  {pathList: 'type', labelKey: 'type'},
  {pathList: 'value', specialHandlingType: 'eventValue'},
  {pathList: 'place.value', citationPath: 'place.sources'},
  {pathList: ['place.map.latitude','place.map.longitude'], labelKey: 'map', separator: ','},
  {pathList: 'place.notes', specialHandlingType: 'note', factFormatType: 1},
  {pathList: 'place.multimedia', specialHandlingType: 'multimedia'},
  {pathList: 'place.placeReference', specialHandlingType: 'placeReference'},
  {pathList: 'placeReference', specialHandlingType: 'placeReference'},
  {pathList: ['address.address1','address.address2','address.city','address.subdivision','address.postalCode','address.country'], alternatePathList: 'address.address', separator: ', '},
  {pathList: 'address.phones', alternatePathList: 'phones', labelKey: 'phones', separator: ', ', isSensitive: true},
  {pathList: 'address.emails', alternatePathList: 'emails', labelKey: 'emails', separator: ', ', isSensitive: true},
  {pathList: 'address.faxes', alternatePathList: 'faxes', labelKey: 'faxes', separator: ', ', isSensitive: true},
  {pathList: 'address.urls', alternatePathList: 'urls', labelKey: 'urls', separator: ', '},
  {pathList: 'age', labelKey: 'age', specialHandlingType: 'age'},
  {pathList: 'husband.age', labelKey: 'ageHusband', specialHandlingType: 'age'},
  {pathList: 'wife.age', labelKey: 'ageWife', specialHandlingType: 'age'},
  {pathList: 'agency', labelKey: 'agency'},
  {pathList: 'cause', labelKey: 'cause'},
  {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1},
  {pathList: 'multimedia', specialHandlingType: 'multimedia'},
  {labelKey: 'associations', factDisplayType: 2, subfacts: [
    {multivaluePath: 'associations', pathList: 'pointer', pointerType: 'individual', subfacts: [
      {pathList: 'relation', labelKey: 'relation', map: 'roleMap'},
      {pathList: 'role', labelKey: 'role', map: 'roleMap'},
      {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1}
    ]}
  ]},
  {labelKey: 'shares', factDisplayType: 2, subfacts: [
    {multivaluePath: 'shares', pathList: 'pointer', pointerType: 'individual', subfacts: [
      {pathList: 'relation', labelKey: 'relation', map: 'roleMap'},
      {pathList: 'role', labelKey: 'role', map: 'roleMap'},
      {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1}
    ]}
  ]},
  {labelKey: 'witnesses', factDisplayType: 2, subfacts: [
    {multivaluePath: 'witnesses', pathList: 'pointer', pointerType: 'individual', subfacts: [
      {pathList: 'relation', labelKey: 'relation', map: 'roleMap'},
      {pathList: 'role', labelKey: 'role', map: 'roleMap'},
      {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1}
    ]}
  ]},
  {pathList: 'restrictionNotice', labelKey: 'restrictionNotice', map: 'restrictionMap'}
]

export const ldsEventSubfactMap = [
  {pathList: 'status', map:'ldsStatusMap'},
  {pathList: 'place.value', citationPath: 'place.sources'},
  {pathList: 'ldsTemple', labelKey: 'temple'}
]

export const mediaFactMap = [
  {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1},
  {pathList: ['lastChange.date.date','lastChange.date.time'], labelKey: 'lastChange', separator: ' ', specialHandlingType: 'date', subfacts: [
    {pathList: 'lastChange.notes', specialHandlingType: 'note', factFormatType: 1}
  ]},
  {pathList: ['reference.type', 'reference.value'], labelKey: 'reference', separator: ': '},
  {pathList: 'recordIdNumber', labelKey: 'recordIdNumber'},
  {pathList: 'photoRecordIdNumber', labelKey: 'photoRecordIdNumber'},
]

export const repositoryFactMap1 = [
  {multivaluePath: 'names', pathList: 'fullName', labelKey: 'repository', citationPath: 'sources', subfacts: [
    {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1}
  ]},
  {pathList: ['address.address1','address.address2','address.city','address.subdivision','address.postalCode','address.country'], alternatePathList: 'address.address', separator: ', '},
  {pathList: ['reference.type', 'reference.value'], labelKey: 'reference', separator: ': '},
  {pathList: 'recordIdNumber', labelKey: 'recordIdNumber'},
  {pathList: 'notes', specialHandlingType: 'note', factFormatType: 1}
]

export const repositoryFactMap2 = [
  {pathList: 'address.phones', labelKey: 'phones', separator: ', '},
  {pathList: 'phones', labelKey: 'phones', separator: ', '},
  {pathList: 'address.emails', labelKey: 'emails', separator: ', '},
  {pathList: 'emails', labelKey: 'emails', separator: ', '},
  {pathList: 'address.faxes', labelKey: 'faxes', separator: ', '},
  {pathList: 'faxes', labelKey: 'faxes', separator: ', '},
  {pathList: 'urls', labelKey: 'urls', separator: ', '},
  {pathList: ['lastChange.date.date','lastChange.date.time'], labelKey: 'lastChange', separator: ' ', specialHandlingType: 'date', subfacts: [
    {pathList: 'lastChange.notes', specialHandlingType: 'note', factFormatType: 1}
  ]}
]
