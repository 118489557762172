<template>
  <div :class="classSubfact">
    <div>
      <span>
        <span class="info-label" v-if="subfact.label">{{ label }}: </span>
        <info-individual v-if="hasIndividualPointer" :individual-id="subfact.value" :citation-number="subfact.citationNumber" />
        <span v-if="hasNoPointer" v-html="value" />
      </span>
    </div>
    <info-subfact v-for="(subsubfact,index) in subfact.subfacts" :key="`sub-subfacts-${index}`" :subfact="subsubfact" />
  </div>
</template>

<script>
import marked from 'marked'
import InfoIndividual from "@/components/familyInfo/InfoIndividual"

  export default {
    name: 'InfoSubfact',
    components: {
      InfoIndividual
    },
    props: {
      subfact: {type: Object, required: true},
    },
    computed: {
      classSubfact: function () {
        const c = {}
        c[`info-subfact-format-${this.subfact.factFormatType}`] = this.subfact.factFormatType > 0 ? true : false
        return c
      },
      label: function () {
        return this.subfact.label
      },
      hasIndividualPointer: function () {
        return this.subfact.pointerType === 'individual'
      },
      hasNoPointer: function () {
        return !this.subfact.pointerType
      },
      value: function () {
        const value = marked.parseInline(decodeURI(this.subfact.value || ''))
        return this.subfact.citationNumber ? `${value}<sup>${this.subfact.citationNumber}</sup>` : value
      }
    }
  }
</script>