<template>
  <span v-if="showGender"><font-awesome-icon :icon="genderIcon" class="gender-icon" />&nbsp;</span>
</template>

<script>
  import { mapState } from 'vuex'
  import * as individualUtils from '@/logic/family/individualUtils'

  export default {
    name: 'GenderIcon',
    props: {
      individual: {type: Object, required: true},
      isInTree: {type: Boolean, default: false}
    },
    computed: {
      ...mapState('family', [
        'suppressGender',
      ]),
      ...mapState('viewState', [
        'showGenderInTree',
      ]),
      gender: function () {
        return individualUtils.getGender(this.individual)
      },
      genderIcon: function () {
        const icon = individualUtils.getGenderIcon(this.gender)
        return ['fas', icon]
      },
      showGender: function () {
        return !!this.gender && !this.suppressGender && (this.showGenderInTree || !this.isInTree) 
      }
    }
  }
</script>