// Notes:  charMinWidth represents the average pixel width in a long name.  The longest name displayed is significant so the longest name likely has a mix of characters. 
//         For a 16px font, the characters vary from 4 to 17 pixels
//         For a 14px font, the characters vary from 4 to 15 pixels
//         For a 12px font, the characters vary from 3 to 13 pixels
// If the user has a name with all 'W's then they can stretch the display with the generationRelativeWidth

const widthFactorNormal = .9     // With the assumption that both names are unlikely to be long and the same length
const widthFactorAncestor = 1

const zoomDimensions = [
  {
    childContainerOffsetAncestor: "7px",
    childContainerOffsetDescendent: "6px",
    charMinWidth: 6.0,                
    fontIndividualLarge: "12px",
    fontIndividualSmall: "9px",
    relationshipHeight: 16,
    relationshipOffset: 16
  },
  {
    childContainerOffsetAncestor: "8px",
    childContainerOffsetDescendent: "7px",
    charMinWidth: 7.0,           
    fontIndividualLarge: "14px",
    fontIndividualSmall: "11px",
    relationshipHeight: 18,
    relationshipOffset: 18,
  },
  {
    childContainerOffsetAncestor: "8px",
    childContainerOffsetDescendent: "7px",
    charMinWidth: 8.0,
    fontIndividualLarge: "16px",
    fontIndividualSmall: "13px",
    relationshipHeight: 20,
    relationshipOffset: 20
  },
]

export function treeDimensions(renderedIndividuals, generationRelativeWidth, treeZoom) {
  // On initial load, the renderedInvividuals will be empty.  On the next tick, it will have all the values
  const maxCharacters = getMaxCharacters(renderedIndividuals) || 35 
  const dimensions = zoomDimensions[treeZoom]
  const minWidth = Math.ceil(maxCharacters * dimensions.charMinWidth)
  const widthAncestor = minWidth + Math.round(generationRelativeWidth * widthFactorAncestor) + (2 * dimensions.relationshipOffset) + 1   
  const widthNormal = minWidth + Math.round((minWidth + generationRelativeWidth) * widthFactorNormal)

  return {
    childContainerOffsetAncestor: dimensions.childContainerOffsetAncestor,
    childContainerOffsetDescendent: dimensions.childContainerOffsetDescendent,
    fontIndividualLarge: dimensions.fontIndividualLarge,
    fontIndividualSmall: dimensions.fontIndividualSmall,
    relationshipHeight: `${dimensions.relationshipHeight}px`,
    relationshipOffset: `${dimensions.relationshipOffset}px`,
    relationshipWidthAncestor: `${widthAncestor}px`,
    relationshipWidthNormal: `${widthNormal}px`
  }
}

export function getMaxCharacters(renderedIndividuals) {
  let max = 0

  // Let's get all the names for each individual
  for (const key of Object.getOwnPropertyNames(renderedIndividuals)) {
    if (key.startsWith('I')){
      const renderedIndividual = renderedIndividuals[key];

      if (renderedIndividual.nameLength > max) { 
        max = renderedIndividual.nameLength 
      }
    }
  }

  return max
}