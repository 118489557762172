<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      This feature not yet available
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"

  export default {
    name: 'EditSubscription',
    components: {
      ApiPostFeedback
    },
    props: {

    },
    data() {
      return {
        form: {

        },
        postState: {}
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()

      },
      resetForm: function () {
        this.postState = {}
        this.form = {

        }
        validationUtils.resetFormImmediate(this)
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>