<template>
  <div id="app">
    <Header @resize="resize" />
    <router-view :key="$route.fullPath" class="router-view" :style="routerViewStyle" />
    <APIUnexpectedErrorModal />
    <AuthenticationModal />
    <DisplayOptionsModal />
    <InfoModal />
    <NewInvitationsModal />
    <SaveTreeModal />
    <Footer />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { treeDimensions } from '@/logic/family/treeUtils'
  import APIUnexpectedErrorModal from "@/components/modals/APIUnexpectedErrorModal"
  import AuthenticationModal from "@/components/modals/AuthenticationModal"
  import DisplayOptionsModal from "@/components/modals/DisplayOptionsModal"
  import InfoModal from "@/components/modals/InfoModal"
  import NewInvitationsModal from "@/components/modals/NewInvitationsModal"
  import SaveTreeModal from "@/components/modals/SaveTreeModal"
  import Header from "@/components/Header"
  import Footer from "@/components/Footer"
  
  export default {
    components: {
      APIUnexpectedErrorModal,
      AuthenticationModal,
      DisplayOptionsModal,
      InfoModal,
      NewInvitationsModal,
      SaveTreeModal,
      Header,
      Footer
    },
    data() {
      return {
        headerHeight: 56
      }
    },
    computed: {
      ...mapGetters('authentication', [
        'isLoggedIn'
      ]),
      ...mapState('authentication', [
        'jwt'
      ]),
      ...mapState('viewState', [
        'generationRelativeWidth',
        'renderedIndividuals',
        'treeZoom'
      ]),
      routerViewStyle: function () {
        return {
          top: `${this.headerHeight}px`
        }
      }
    },
    methods: {
      onGoogle: function (isSignedInToGoogle) {
        // You have just been logged out of Google but are still logged in to RelativeToMe and and you were originally logged in using the Google oAuth2 service
        if (!isSignedInToGoogle && this.isLoggedIn && this.jwt.provider === 1) {
          console.log(`onGoogle: isLoggedIn-${this.isLoggedIn}  provider-${this.jwt.provider}  isSignedInToGoogle-${isSignedInToGoogle}`)
          this.$store.dispatch('authentication/logoff')
        }
      },
      onStorageUpdate: function (event) {
        // If the user has multiple tabs open, such as when when the email is being verified, signal the change in the other tabs.
        if (event.key === "ls-ctss") {
          this.$store.dispatch('authentication/processAccessToken', this, event)
        }
      },
      resize: function (height) {
        this.headerHeight = height
      },
      updateCSSVariables: function () {
        const dimensions = treeDimensions(this.renderedIndividuals,this.generationRelativeWidth,this.treeZoom)
        const bodyStyles = document.body.style;
        bodyStyles.setProperty("--child-container-offset-ancestor", dimensions.childContainerOffsetAncestor)
        bodyStyles.setProperty("--child-container-offset-descendent", dimensions.childContainerOffsetDescendent)
        bodyStyles.setProperty("--font-individual-large", dimensions.fontIndividualLarge)
        bodyStyles.setProperty("--font-individual-small", dimensions.fontIndividualSmall)
        bodyStyles.setProperty("--relationship-height", dimensions.relationshipHeight)
        bodyStyles.setProperty("--relationship-offset", dimensions.relationshipOffset)
        bodyStyles.setProperty("--relationship-width-ancestor", dimensions.relationshipWidthAncestor)
        bodyStyles.setProperty("--relationship-width-normal", dimensions.relationshipWidthNormal)
      }
    },
    beforeDestroy() {
      window.removeEventListener("storage", this.onStorageUpdate);
    },
    beforeCreate() {
      this.$store.dispatch('viewState/setInitialState')

      // Google status 
      this.$gapi.getAuthInstance().then(instance => {
        // https://developers.google.com/identity/sign-in/web/reference#googleauthsignout
        console.log(`App.beforeCreate login state: isLoggedIn-${this.isLoggedIn}  isSignedInToGoogle-${instance.isSignedIn.get()}`)
      })

      // When the application starts, load the JWT access token into state if there is one
      this.$store.dispatch('authentication/processAccessToken', this).finally(() => {
        // Load the default family
        this.$store.dispatch('family/loadDefaultFamily', this)
      })
    },
    created() {
      // Set CSS variables
      this.updateCSSVariables()

      // Setup listener to see when Google might logoff an account
      this.$gapi.listenUserSignIn(this.onGoogle)
    },
    mounted() {
      window.addEventListener("storage", this.onStorageUpdate);
    },
    watch: {
      generationRelativeWidth: function () {
        this.updateCSSVariables()
      },
      renderedIndividuals: function () {
        this.updateCSSVariables()
      },
      treeZoom: function () {
        this.updateCSSVariables()
      }
    }
  }
</script>

<style lang="scss">
  @import './assets/base.scss'
</style>