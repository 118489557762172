<template>
  <div class="px-2 pt-2 narrow-container-0">
    <h4>Email Settings</h4>
    <div v-if="!busy" class="py-3"><span class="font-weight-bold">Email Address: </span><span>{{ this.emailManagementInfo.email }}</span></div>
    <div v-if="busy">Loading ...</div>
    <EditEmailSettings v-if="!busy" :initial-state="emailManagementInfo" :is-stand-alone="true" :manage-email-token="manageEmailToken" />
  </div>
</template>

<script>
import * as apiUtils from '@/logic/general/apiUtils'
import EditEmailSettings from '@/components/email/EditEmailSettings.vue'

export default {
  components: {
    EditEmailSettings
  },
  data() {
    return {
      busy: false,
      emailManagementInfo: {}
    }
  },
  computed: {
    manageEmailToken: function () {
      return this.$route.params.manageEmailToken
    },
  },
  methods: {
    reset: function () {
      this.busy = true
      this.axios.get(apiUtils.buildAPIUrl(`emailManagement?manageEmailToken=${encodeURIComponent(this.manageEmailToken)}`))
          .then(response => {
            this.emailManagementInfo = response.data     
            this.busy = false      
          })
    }
  },
  created() {
    this.reset()
  }
}
</script>