import * as labelMaps from '@/logic/culture/labelMaps'

export function getLabel(labelKey, language) {
  if (labelKey) {
    const map = labelMaps.generalMap[labelKey]

    if (map) {
      return map[language] || map['default']
    } else {
      console.warn(`Unable to translate labelKey '${labelKey}' into language '${language}'. There is no map.`)
      return 'Unknown'
    }
  } else {
    return null
  }
}

export function getLocalizedValue(value, mapName, language) {
  let result = value

  if (value && mapName) {
    const map = labelMaps[mapName]
  
    if (map) {
      const valueMap = map[value]

      if (valueMap) {
        return valueMap[language] || valueMap['default'] || value
      } 
    } 

    result = result.replace('\n','<br/>')
  }

  return result
}

export function formatAge(value, language) {
  value = value.split(' ').join(', ')
  return replaceTranslatableItems(value, "ageReplacer", language)
}

export function formatDate(value, language) {
  return replaceTranslatableItems(value, "dateReplacer", language)
}

export function formatEventValue(value, language) {
  if (value) {
    const special = value.trim().toUpperCase()

    if (special === 'Y' || special === 'V:Y') {
      return replaceTranslatableItems('Y', "ynReplacer", language)
    }
  } 

  return value
}

export function formatNote(value, language) {
  return replaceTranslatableItems(value, "noteReplacer", language,'<span class="info-sublabel">','</span>')
}

export function replaceTranslatableItems(value, replacerName, language, wrapLeft = '', wrapRight = '') {
  const replacer = labelMaps[replacerName]
  let result = value

  if (value && replacer) {
    for (const key in replacer) {
      const valueMap = replacer[key];
      const replacement = valueMap[language] || valueMap['default'] 
      const re = new RegExp(key, 'g')
      result = result.replace(re, `${wrapLeft}${replacement}${wrapRight}`)
    }

    // Capitalize the first character
    result = result.substr(0,1).toUpperCase() + result.substr(1)
  }

  return result
}