<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <validation-provider name="Base Family Name" :rules="{required: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Base Family Name:"
          label-align-sm="right"
          label-for="family-name-base-box">
          <b-form-input id="family-name-base-box"
                        type="text"
                        v-model="form.familyNameBase"
                        :state="getValidationState(validationContext)"
                        tabindex="1" />
          <b-form-invalid-feedback id="familyName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Description" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Description:"
          label-align-sm="right"
          label-for="family-description-base-box">
          <b-form-textarea id="family-description-base-box"
                           v-model="form.familyDescription"
                           :state="getValidationState(validationContext)"
                           placeholder="Enter optional description..."
                           rows="3"
                           tabindex="3" />
          <b-form-invalid-feedback id="familyDescription-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      
      <validation-provider name="Author" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Author:"
          label-align-sm="right"
          label-for="author-base-box">
          <b-form-input id="author-base-box"
                        type="text"
                        v-model="form.author"
                        :state="getValidationState(validationContext)"
                        tabindex="4" />
          <b-form-invalid-feedback id="author-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider name="Source URL" :rules="{url: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Source URL:"
          label-align-sm="right"
          label-for="source-url-base-box">
          <b-form-input id="source-url-base-box"
                        type="text"
                        v-model="form.sourceUrl"
                        :state="getValidationState(validationContext)"
                        tabindex="4" />
          <b-form-invalid-feedback id="sourceUrl-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        label-cols-sm="3"
        label-for="suppressGender-box">
        <b-form-checkbox id="suppressGender-box"
                         type="text"
                         v-model="form.suppressGender"
                         tabindex="5">
          Hide Gender?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="isSiteAdministrator"
        label-cols-sm="3"
        label-for="isPublic-box">
        <b-form-checkbox id="isPublic-box"
                         type="text"
                         v-model="form.isPublic"
                         tabindex="5">
          Is Public?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="isSiteAdministrator"
        label-cols-sm="3"
        label-for="isShowcased-box">
        <b-form-checkbox id="isShowcased-box"
                         type="text"
                         v-model="form.isShowcased"
                         tabindex="5">
          Is Showcased?
        </b-form-checkbox>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button type="button" variant="outline-secondary" tabindex="12" @click="resetPage()">Cancel</b-button>
        <b-button :disabled="postState.inProgress"
                  type="submit"
                  variant="primary"
                  tabindex="11"
                  class="ml-2">
          Submit
        </b-button>
      </div>
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"

  export default {
    name: 'EditFamilyDetails',
    components: {
      ApiPostFeedback
    },
    props: {
      initialState: {type: Object, required: true}
    },
    data() {
      return {
        form: {
          familyId: this.$route.params.familyId,
          familyNameBase: this.initialState.familyNameBase,
          familyDescription: this.initialState.familyDescription,
          author: this.initialState.author,
          sourceUrl: this.initialState.sourceUrl,
          suppressGender: this.initialState.suppressGender,
          isPublic: this.initialState.isPublic,
          isShowcased: this.initialState.isShowcased,
        },
        postState: {}
      }
    },
    computed: {
      ...mapGetters('authentication', [
        'isSiteAdministrator'
      ])
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onSubmit: function () {
        const familyId = this.$route.params.familyId
        this.postState = apiUtils.resetPostState()
        this.axios.patch(apiUtils.buildAPIUrl('familyAdmin/family'), this.form)
          .then(response => {
            this.postState = response.postState
            this.resetForm()
            this.resetPage()
            this.$store.dispatch('family/postUpdateReload', { vm: this, familyId })
          })
          .catch(error => {
            this.postState = error.postState
            validationUtils.resetFormDelayed(this)
          })
      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          familyNameBase: "",
          userFamilyNameAlias: "",
          userIsEmailVisible: false,
          familyDescription: "",
          author: "",
          sourceUrl: ""
        }
        validationUtils.resetFormImmediate(this)
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>