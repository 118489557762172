<template>
  <div class="px-2 pt-2">
    <div v-if="!isLoading" class="d-flex flex-wrap justify-content-between">
      <div>
        <h4>
          Individuals In Family
        </h4>
      </div>
      <div>
        <b-pagination
          v-model="individualsCurrentPage"
          :per-page="individualsPerPage"
          :total-rows="individualsRows"
          aria-controls="my-table"
          align="right" />
      </div>
      <div>
        <b-form-group
          class="narrow-container-0"
          label-cols-sm="4"
          label="Name Filter:"
          label-align-sm="right"
          label-for="individuals-filter-box">
          <b-form-input id="individuals-filter-box" 
                        type="text" 
                        v-model="individualsFilter" 
                        tabindex="1" />
        </b-form-group>
      </div>
    </div>

    <b-table :current-page="individualsCurrentPage"
             :items="individuals" 
             :fields="individualsFields"
             :per-page="individualsPerPage"
             hover
             responsive
             show-empty
             stacked="md"
             striped>
      <template #cell(actions)="row">
        <b-button @click="onShowIndividualTree(row)" class="action-icon" variant="primary" size="sm"><font-awesome-icon :icon="['fas', 'project-diagram']" />Tree</b-button>
        <b-button @click="onShowDetails(row)" class="action-icon" variant="primary" size="sm"><font-awesome-icon :icon="['fas', 'book']" />Particulars</b-button>
      </template>
      <template #cell(name)="row">
        <individual-name :individual-id="row.item.individualId" :use-formal-name="true" />
      </template>
      <template #cell(summary)="row">
        <individual-summary :individual-id="row.item.individualId" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import * as urlUtils from '@/logic/general/urlUtils'
import { individualNameSearchGrid } from '@/logic/family/individualNameSearchSet'
import IndividualName from "@/components/tree/IndividualName"
import IndividualSummary from "@/components/tree/IndividualSummary"
import { buildFamilyIndividualListEvent } from '@/logic/seo/analyticsUtils'
import { buildFamilyStructuredData } from '@/logic/seo/structuredDataUtils'
import { setHeaderTags } from '@/logic/seo/headerUtils'

export default {
  components: {
    IndividualName,
    IndividualSummary
  },
  props: {
    familyindividuals: {type: Array},
    operatorFamilyAuthorityId: {type: Number}
  },
  data() {
    return {
      busy: false,
      individualsFields: [
        {key: "actions", tdClass: "info-table-action text-nowrap"},
        {key: "name"},
        {key: "summary"}
      ],
      individuals: [],
      individualsFilter: '',
      individualsCurrentPage: 1,
      individualsPerPage: 15,
      individualsRows: 0
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.seo(to)
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.seo(to)
    next()
  },
  computed: {
    ...mapState('family', [
        'familyId',
        'familyName',
        'familyData',
        'individualsArray',
        'isLoading',
        'individualSearchMap'
      ]),
      ...mapState('viewState', [
        'language'
      ])
  },
  methods: {
    onIndividualsFiltered: function () {
      this.individuals = individualNameSearchGrid(this.individualsFilter, this.familyData, this.individualSearchMap, this.individualsArray, this.language)
      this.individualsRows = this.individuals.length
      this.individualsCurrentPage = 1
    },
    onShowIndividualTree: function (row) {
      const pathNew = urlUtils.getIndividualURL(this.familyId, this.familyName, row.item.individualId, row.item.name)
      this.$router.push(pathNew)
    },
    onShowDetails: function (row) {
      this.$store.dispatch('viewState/loadInfoModal',{infoModalEntityId: row.item.individualId, infoModalEntityType: 'individual'})
      this.$bvModal.show('infoModal')
    },
    seo: function (to) {
      if (!this.isLoading) {
        buildFamilyIndividualListEvent(this, to.params)
        setHeaderTags(to, this.$store.state)
        buildFamilyStructuredData(this.$store.state.family, to.params)
      }
    }
  },
  mounted() {
    if (this.isLoading === false) {
      this.onIndividualsFiltered()
    }
  },
  created() {
    // Add this function to the component to handle debouncing within typing search text.
    this.debouncedGetIndividualList = _.debounce(this.onIndividualsFiltered, 400)
  },
  watch: {
    individualsFilter: function (newSearchText) {
      this.debouncedGetIndividualList()
    },
    isLoading: function (newValue) {
      if (newValue === false) {
        this.onIndividualsFiltered()
        this.seo(this.$route)
      }
    }
  }
}
</script>