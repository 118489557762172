<template>
  <div class="header" ref="header">
    <BannerNetworkError />
    <BannerUnverifiedEmail />
    <b-navbar toggleable="lg" type="dark" variant="info" data-nosnippet="data-nosnippet">
      <b-navbar-toggle target="nav-collapse" />

      <b-navbar-brand v-if="isLoading">Loading Family Information ...</b-navbar-brand>
      <b-navbar-brand v-if="!isLoading" :to="'/family/selection'">
        <span class="family-name-header">
          <font-awesome-icon :icon="['fas', 'exchange-alt']" v-b-tooltip.hover.bottomright="'Switch families'" />{{ familyName }}
        </span>
        <FamilyDescription :display-info-modal="true"
                           :family-description="familyDescription"
                           :author="author"
                           :source-url="sourceUrl"
                           id="headerFamilyInfo"
                           placement="bottomright" />
      </b-navbar-brand>
      <b-navbar-brand v-if="!isLoading && !isIndividualListShowing" @click="onShowIndividualList" class="hover-pointer">
        <font-awesome-icon :icon="['fas', 'clipboard-list']" v-b-tooltip.hover.bottomright="'List all the members of this family'" />
      </b-navbar-brand>


      <!-- Please note that the serializer just has the search text.  This is because the search logic is done elsewhere and this prevents any interfence with that.-->
      <vue-typeahead-bootstrap
        v-if="!isLoading"
        v-model="searchText"
        :data="individuals"
        :serializer="item => searchText"  
        @hit="onShowIndividual"
        class="flex-grow-1"
        input-name="individualSearch"
        placeholder="Search with an individual's name(s)">
        <template slot="suggestion" slot-scope="{ data }">
          <individual-name :individual-id="data.individualId" :use-formal-name="true" />
          <individual-summary :individual-id="data.individualId" /> 
        </template>
      </vue-typeahead-bootstrap> 

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto " right>   
          <b-nav-item v-b-modal.saveTreeModal v-if="!isLoading && isIndividualTree">Save Tree</b-nav-item>
          <b-nav-item v-b-modal.displayOptionsModal v-if="!isLoading">Display Options</b-nav-item>
          <b-nav-item v-b-modal.authenticationModal v-if="!isLoggedIn">Login</b-nav-item> 
          <b-nav-item-dropdown text="Account Actions" right v-if="isLoggedIn">
            <b-dropdown-item :to="'/admin/accountSettings'">Account Settings</b-dropdown-item>
            <b-dropdown-item v-if="isLoggedInFully" :to="'/admin/family/management'">Families</b-dropdown-item>
            <b-dropdown-item v-if="isLoggedInFully" :to="'/admin/family/invitations'">Invitations</b-dropdown-item>
            <b-dropdown-divider v-if="isSiteAdministrator" />
            <b-dropdown-item v-if="isSiteAdministrator" :to="'/siteAdmin/reimportAll'">Reimport All</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="onLogout">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item class="footer-header-links" :to="'/info/terms'">Terms</b-nav-item>
          <b-nav-item class="footer-header-links" :to="'/info/privacy'">Privacy</b-nav-item>
          <b-nav-item class="footer-header-links" :to="'/info/contactUs'">Contact Us</b-nav-item>
          <b-nav-item :to="'/'"><b-icon icon="question-circle" /></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <BannerCookieWarning />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
  import _ from 'lodash'
  import shortUUID from 'short-uuid'
  import { individualNameSearchHeader } from '@/logic/family/individualNameSearchSet'
  import * as individualUtils from '@/logic/family/individualUtils'
  import * as urlUtils from '@/logic/general/urlUtils'
  import BannerCookieWarning from "@/components/banners/BannerCookieWarning"
  import BannerNetworkError from "@/components/banners/BannerNetworkError"
  import BannerUnverifiedEmail from "@/components/banners/BannerUnverifiedEmail"
  import FamilyDescription from "@/components/family/FamilyDescription"
  import IndividualName from "@/components/tree/IndividualName"
  import IndividualSummary from "@/components/tree/IndividualSummary"
  
  export default {
    components: {
      BannerCookieWarning,
      BannerNetworkError,
      BannerUnverifiedEmail,
      FamilyDescription,
      IndividualName,
      IndividualSummary,
      VueTypeaheadBootstrap
    },
    data() {
      return {
        individuals: [],
        clientHeight: 56,
        resizeObserver: null,
        searchText: '',
        isIndividualListShowing: false
      }
    },
    beforeRouteEnter(to, from, next) {
      // Force reactivity when the route changes
      next(vm => { 
        this.routeVersion = shortUUID.generate()  
      })
    },
    computed: {
      ...mapGetters('authentication', [
        'isLoggedIn',
        'isLoggedInFully',
        'isLoggedInUnverified',
        'isSiteAdministrator'
      ]),
      ...mapState('family', [
        'familyData',
        'familyId',
        'familyName',
        'familyDescription',
        'author',
        'sourceUrl',
        'isPublic',
        'isLoading',
        'individualSearchMap'
      ]),
      ...mapState('viewState', [
        'areCookiesAccepted',
        'hasNetworkError',
        'language'
      ]),
      isIndividualTree: function () {
        return this.$route.name === 'Individual Tree'
      }
    },
    methods: {
      getIndividualList: function () {
        this.individuals = individualNameSearchHeader(this.searchText, this.familyData, this.individualSearchMap, this.language)
      },
      getGenderAvatarUrl: function (gender) {
        return individualUtils.getGenderAvatarUrl(gender)
      },
      getBirthDeathSummary: function (individual) {
        return individualUtils.getBirthDeathSummary(individual, this.language)
      },
      onLogout: function () {
        this.$bvModal.msgBoxConfirm('Are you sure?', {size: 'sm'})
        .then(value => {
          if (value) {
            this.searchText = ''
            this.$auth.logout()
            .finally(() => {
              // If server call fails, continue anyways.
              const vm = this
              this.$store.dispatch('authentication/logoff',this)
              .finally(() => {
                if (!vm.isPublic) {
                  vm.$store.dispatch('family/loadDefaultFamily', vm)
                }
              })  
            }) 
          }
        })
      },
      onResize () {
        const ref = this.$refs.header 

        if (ref) {
          const h = ref.clientHeight

          if (h != this.clientHeight) {
            this.clientHeight = h
            this.$emit('resize', h)
          }
        }
      },
      onShowIndividual: function (item) {
        const pathNew = urlUtils.getIndividualURL(this.familyId, this.familyName, item.individualId, item.name)

        if (pathNew.path !== this.$router.currentRoute.path) {
          this.$router.push(pathNew)
        }

        this.searchText = ''
      },
      onShowIndividualList: function () {
        const pathNew = urlUtils.getFamilyURL(this.familyId, this.familyName)
        this.$router.push(pathNew)
      },
      setIndividualListShowing: function () {
        const pathIndividualList =  urlUtils.getFamilyURL(this.familyId, this.familyName)
        this.isIndividualListShowing = pathIndividualList.path === this.$router.currentRoute.path
      }
    },
    created() {
      // Add this function to the component to handle debouncing within typing search text.
      this.debouncedGetIndividualList = _.debounce(this.getIndividualList, 400)
    },
    mounted () {
      this.resizeObserver = new ResizeObserver(this.onResize).observe(this.$refs.header)
    },
    watch: {
      areCookiesAccepted: function () {
        this.onResize()
      },
      familyId: function (to, from) {
        this.setIndividualListShowing()
      },
      hasFamilyDescription: function (newValue) {
        // If the family has been switched we want to reset the search text
        this.searchText = ''
      },
      hasNetworkError: function () {
        this.onResize()
      },
      isLoggedInUnverified: function () {
        this.onResize()
      },
      searchText: function (newSearchText) {
        this.debouncedGetIndividualList()
      },
      '$route' (to, from) {
        this.setIndividualListShowing()
      }
    },
    destroy() {
      delete this.resizeObserver
    }
  }
</script>