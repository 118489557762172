<template>
  <b-jumbotron header="Privacy Policy" lead="">
    <ol>
      <li>
        <h6>General</h6>
        <p>
          At relativetome, we care about your personal data, so we have prepared this Privacy Policy to explain how we collect, use and share it.
        </p>
        <p>
          This Privacy Policy (“Privacy Policy”) details the personal data Ibex Consulting, Inc. (“relativetome”, “we”, “us” or “our”) receives about you, how we process it and your 
          rights and obligations in relation to your personal data. Ibex Consulting Inc., a company registered at 22-7250 144ST, Surrey, BC, Canada V3W 1L7
          is the data controller for the purposes of the General Data Protection Regulation (“GDPR”) and any relevant local legislation (“Data Protection Laws”).
        </p>
        <p>
          By using or accessing the Service, you agree to the terms of this Privacy Policy. Capitalized terms not defined here have the meanings set forth in the terms and conditions (the “Terms and Conditions”), 
          located at <b-link :to="'/info/terms'">relativetome.com/info/terms</b-link>. We may update our Privacy Policy to reflect changes to our information practices. If we do this and the changes are material, we will post a notice that we have made 
          changes to this Privacy Policy on the Website for at least 7 days before the changes are made, and we will indicate the date these terms were last revised at the bottom of the Privacy Policy. 
          Any revisions to this Privacy Policy will become effective at the end of that 7 day period.
        </p>
      </li>

      <li>
        <h6>Information We Collect</h6>
        <p>
          This details the type of information we collect.
        </p>
        <ol type="a">
          <li>
            <h6>Registration Data</h6>
            <p>
              Through the registration process, you will provide us with your name and e-mail address. You will also provide us with your payment transaction information if you choose to pay for relativetome services.
            </p>
          </li>
          <li>
            <h6>Family Data</h6>
            <p>
              Families may be uploaded to relativetome.  There are two types of families; public and private.  Public families can be viewed by anyone.  
              A family will only be made public on RelativeTo Me if the family was already available publicly on another website or, by agreement with the author(s), relativetome has been given permission to make it public.
            </p>
          </li>
          <li>
            <h6>Activity Data</h6>
            <p>
              When you use the Service, we will collect technical data about how you interact with our Services; for more information, see <b-link href="#cookies">Cookies</b-link>.
            </p>
            <p class="info-list-intro">
              We only collect the data we need which means we don’t record:
            </p>
            <ul class="info-list">
              <li>Names</li>
              <li>Email addresses</li>
              <li>Passwords</li>
            </ul>
            <p>
              We do record the following data:
            </p>
            <h6 class="info-list-intro">Tech Specs</h6>
            <ul class="info-list">
              <li>Browser</li>
              <li>Device type</li>
              <li>Operating system</li>
              <li>Viewfinder size</li>
              <li>IP address</li>
            </ul>
            <h6 class="info-list-intro">Navigation</h6>
            <ul class="info-list">
              <li>Pages visited</li>
              <li>Referrers</li>
              <li>URL parameters</li>
              <li>Session duration</li>
            </ul>
            <h6 class="info-list-intro" id="cookies">Events</h6>
            <ul class="info-list">
              <li>Register new account</li>
              <li>Forgot your password</li>
              <li>Verify email</li>
              <li>Login</li>
              <li>Account change</li>
              <li>Import Family</li>
            </ul>
          </li>
        </ol>
      </li>

      <li class="end-of-text-block">
        <h6>Cookies</h6>
        <p>
          When you enter the Website, we collect your browser type and your IP address (a unique address that identifies your computer on the Internet). 
        </p>
        <p>
          In addition, we store certain information from your browser using "cookies." A cookie is a piece of data stored on the user's computer tied to information about the user. 
          We use cookies for account security and personalized ads. If you do not want information collected through the use of cookies, there is a simple procedure in most browsers 
          that allows you to deny or accept the cookie feature; however, you should note that you cannot login without allowing cookies.
        </p>
        <p>
          You can find more information about cookies and how to manage them at <b-link href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</b-link>.
        </p>
        <h6 class="info-list-intro">Ads</h6>
        <ul>
          <li>Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website or other websites.</li>
          <li>Google's use of advertising cookies enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.</li>
          <li>Users may opt out of personalized advertising by visiting <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener">Ads Settings</a> or you can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">www.aboutads.info</a>.</li>
        </ul>
      </li>

      <li>
        <h6>Information Obtained by Third Parties</h6>
        <p>
          We use Google Analytics which is a web analytics tool that helps us understand how users engage with the Website. 
          Like many services, Google Analytics uses first-party cookies to track user interactions as in our case, where they are used to collect information about how users use our site. 
          This information is used to compile reports and to help us improve our Website. The reports disclose website trends without identifying individual visitors. 
          You can opt out of Google Analytics without affecting how you visit our site – for more information on opting out of being tracked by Google Analytics across all websites you use, visit this 
          Google page: <b-link href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</b-link>.
        </p>
      </li>

      <li>
        <h6>Use of information obtained by relativetome</h6>
        <p>
          This details how we use the information we collect.
        </p>
        <ol type="a">
          <li>
            <h6>Registration Data</h6>
            <p>
              relativetome may occasionally send you service related and product change announcements through the general operation of the Service. 
              We may also use your contact information to send you notifications regarding new services, offers and promotions offered by relativetome if you affirmatively consent to receive such communications.
            </p>
          </li>
          <li>
            <h6>Family Data</h6>
            <p>
              relativetome will never sell or share your Family Data with any other organization except third-party printers that would print a portion of your family data at your request.  
              relativetome does not analyze your Family Data other than to find better ways to render that data for your use.  
              relativetome will never attempt to contact anyone using information from the family data.
            </p>
          </li>
          <li>
            <h6>Activity Data</h6>
            <p>
              relativetome may use or share anonymous data collected through the Service, including Activity Data without limitation. As a business, 
              it is critical that we perform our contract with you with the best service possible, and it is in our legitimate interests to perform these 
              processing functions and to enable service e-mails by default to keep your data secure and provide our Service. 
              You may opt out of any non-essential service e-mails at any time.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h6>Sharing your personal data with third parties</h6>
        <p>
          relativetome shares your personal data only when it is necessary to offer the Service, legally required, or permitted by you.
        </p>
        <p>
          relativetome will never sell or share your Family Data with any other organization except third-party printers that would print a portion of your family data at your request.
        </p>
        <p>
          We will be required to access and disclose personal data in response to lawful requests, such as subpoenas or court orders, or in compliance with applicable laws. 
          Additionally, we will access and share account or other personal data when we believe it is necessary to comply with law, to protect our interests or property, to prevent 
          fraud or other illegal activity perpetrated through the Service or using the relativetome name, or to prevent imminent harm. This will include accessing and sharing personal data 
          with other companies, lawyers, agents or government agencies.
        </p>
        <p>
          If the ownership of all or substantially all of the relativetome business, or individual business units or assets owned by relativetome that are related to the Service, were to change, 
          your personal data will be transferred to the new owner. In any such transfer of information, your personal data would remain subject to this section.
        </p>
        <p>
          relativetome will share aggregate or anonymous data collected through the Service, including Activity Data, for purposes such as understanding or improving the service.
        </p>
      </li>

      <li>
        <h6>Data subject rights and data retention</h6>
        <p>
          You can manage your account settings at <b-link href="/admin/accountSettings">https://relativetome.com/admin/accountSettings</b-link> to update, amend, and correct your information.
        </p>
        <p>
          You also have the following rights in relation to the personal data we hold about you, unless provided otherwise by local law:
        </p>
        <ul class="info-list">
          <li>To request access to, or erasure of, the personal data we hold about you.</li>
          <li>To request us to restrict the processing of the personal data we hold about you.</li>
          <li>To object to us processing personal data relating to you.</li>
          <li>Where you have given us consent to process your personal data, you have the right to withdraw that consent at any time.</li>
          <li>To export the personal data you have provided to relativetome in a format that can be transferred electronically to a third party.</li>
          <li>To delete your account with relativetome by following the instructions available through the Service.</li>
        </ul>
        <p>
          relativetome will retain your data until your account is deleted, after which point we will retain anonymous data collected through the Service, 
          including Activity Data, which may be used by relativetome and shared with third parties in any manner.
        </p>
        <p>
          Please note that some of these rights are not absolute. In some cases, we may refuse a request to exercise particular rights if complying with it meant that we are no longer 
          able to meet our contractual obligation to provide you with particular products and services. We will keep you informed as to the actions that we can take when you make your request.
        </p>
        <p>
          You may also have the right to make a GDPR complaint to the relevant Supervisory Authority. 
          A list of EEA Supervisory Authorities is available here: <b-link href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</b-link> 
          and the UK here: <b-link href="https://ico.org.uk/global/contact-us/">https://ico.org.uk/global/contact-us/</b-link>. 
          If you need further assistance regarding your rights, please contact us using the contact information provided below and we will consider your request in accordance with applicable law. 
          In some cases our ability to uphold these rights for you may depend upon our obligations to process personal information for security, safety, fraud prevention reasons, compliance with 
          regulatory or legal requirements, or because processing is necessary to deliver the services you have requested. Where this is the case, we will inform you of specific details in response to your request.
        </p>
      </li>

      <li>
        <h6>Third party websites and links</h6>
        <p>
          Please note that you may have cookies placed on your computer by third party websites that refer you to our Service. 
          Although we do not share your personal data with these third party websites unless it is reasonably necessary to offer the Service, 
          they may be able to link certain non-personally identifiable information we transfer to them with personal data they previously collected from you. 
          Please review the privacy policies of each website you visit to better understand their privacy practices. In addition, relativetome would like to inform 
          you that anytime you click on links (including advertising banners), which take you to third party websites, you will be subject to the third parties’ privacy policies.
        </p>
        <p>
          Our Services contain links to other sites operated by third parties. relativetome does not control such other sites and is not responsible for their content, 
          their privacy policies, or their use of personal data, including any personal or financial information collected by our third party payment processor to process payments 
          for in-app purchases. relativetome's inclusion of such links does not imply any endorsement of the content on such sites or of their owners or operators except as disclosed on the Services. 
          Any information submitted by you directly to these third parties is subject to that third party’s privacy policy
        </p>
      </li>

      <li>
        <h6>Information security</h6>
        <p>
          relativetome has implemented administrative and technical safeguards it believes are appropriate to protect the confidentiality, integrity and availability of your personal data, access credentials, and family data.
          However, given sufficient resources, a determined attacker could defeat those safeguards and may, as a result, gain access to the data we seek to protect.
        </p>
      </li>

      <li>
        <h6>Do Not Track</h6>
        <p>
          The Service is not designed to respond to “do not track” signals sent by some browsers.
        </p>
      </li>

      <li>
        <h6>Contact Us</h6>
        <p>
          relativetome is owned and operated by Ibex Consulting Inc.
        </p>
        <p>
          Ibex Consulting Inc. is located within Canada at:<br>
          Ibex Consulting Inc.<br>
          22-7250 144ST<br>
          Surrey, BC  Canada<br>
          V3W 1L7<br>
        </p>
        <p>
          For all data privacy inquiries and any questions or concerns you have about this Privacy Policy, please contact our Data Protection Officer at Support@relativetome.com
        </p>
      </li>
    </ol>
  </b-jumbotron>
</template>

<script>

export default {
  data() {
    return {}
  }
}
</script>