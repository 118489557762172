<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <h3>Forgot Your Password?</h3>
      <p>Fill this form to reset your password.  You will be sent an email that will allow you to create a new password.</p>
      <validation-provider name="Email" :rules="{required: true, email: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="1"
          label="Email:"
          label-align-sm="right"
          label-for="email-box">
          <b-form-input id="email-box" 
                        type="text" 
                        v-model="form.email" 
                        :state="getValidationState(validationContext)"
                        autocomplete="username" 
                        tabindex="1" />
          <b-form-invalid-feedback id="email-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div class="d-flex justify-content-end pb-2">
        <vue-recaptcha :sitekey="reCaptchaSiteKey" 
                       @verify="onCaptchaVerified"
                       @expired="onCaptchaExpired"
                       size="normal"
                       tabindex="10" />
      </div>
      <div class="d-flex justify-content-end">
        <b-button type="button" variant="outline-secondary" tabindex="12" @click="onCancelRegistration">Cancel</b-button>
        <b-button type="submit"
                  variant="primary"
                  tabindex="11"
                  class="ml-2"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  :title="submitTitle"
                  :disabled="!form.recaptchaToken">
          Submit
        </b-button>
      </div>
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"
  import VueRecaptcha from 'vue-recaptcha';

  const submitCaptchaMissing = "Please respond to reCaptcha"

  export default {
    name: 'PasswordForgot',
    components: {
      ApiPostFeedback,
      VueRecaptcha
    },
    props: {
    },
    data() {
      return {
        form: {
          recaptchaToken: "",
          confirmPassword: "",
          email: "",
          familyName: "",
          givenName: "",
          password: "",
          stayLoggedIn: false
        },
        postState: {},
        reCaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        submitTitle: submitCaptchaMissing,
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onCancelRegistration: function () {
        this.$emit('set-display-mode','login')
        this.resetForm()
      },
      onCaptchaExpired: function () {
        this.form.recaptchaToken = ''
        this.submitTitle = submitCaptchaMissing
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.form.recaptchaToken = recaptchaToken
        this.submitTitle = ''
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()
        this.axios.post(apiUtils.buildAPIUrl('account/passwordSendResetRequest'), this.form)
          .then(response => {
            this.postState = response.postState
            this.resetForm()
            this.$bvModal.hide('authenticationModal')
          })
          .catch(error => {
            this.postState = error.postState
          })
      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          email: ""
        }
        validationUtils.resetFormImmediate(this)
      }
    }
  }
</script>