export function familyAuthorityText (authorityId) {
  switch(authorityId) {
    case 1: return "Read-only"
    case 2: return "Admin"
    case 3: return "Owner"
    default: return "Unknown"
  }
}

export function accountProviderText (accountProvider) {
  switch(accountProvider) {
    case 0: return "RelativeToMe.com"
    case 1: return "Google"
    default: return "Unknown"
  }
}

export function subclauseTypeText (factDisplayType) {
  switch(factDisplayType) {
    case 0: return "standard"
    case 1: return "text"
    case 2: return "note"
    default: return "Unknown"
  }
}
