import { buildIndividualNameSearchMap, loadIndividuals } from '@/logic/family/individualNameSearchSet'
import * as apiUtils from '@/logic/general/apiUtils'
import * as urlUtils from '@/logic/general/urlUtils'
import { base64GzippedToObj} from '@/logic/general/gzipUtils'

export default {
  loadDefaultFamily(context, vm) {
    context.commit('familyStartLoad')

    const routeFamilyId = vm.$route.params.familyId
    const storedFamilyId = localStorage.getItem('ls-fi')
    const storedFamilyDataHash = localStorage.getItem('ls-fdh')
    const familyId = routeFamilyId ?? storedFamilyId

    vm.$store.dispatch('viewState/resetModalIds')
    const params = {
      familyId: familyId,
      familyDataHash: storedFamilyDataHash
    }
    vm.axios.get(apiUtils.buildAPIUrl('family/default'), { params })
      .then(response => {
        const family = response.data

        // If the url does not match the default family returned (not logged in, not authorized, no longer exists), navigate to the new family
        if (routeFamilyId && family.familyId !== routeFamilyId) {
          const pathNew = urlUtils.getFamilyURL(family.familyId, family.familyName)
          vm.$router.push(pathNew)
        }

        vm.$store.dispatch('family/setFamily', {vm, family})
      })
      .catch(error => {
        setTimeout(() => {context.dispatch('loadDefaultFamily', vm)}, 10000) 
      })
  },

  loadFamily(context, { vm, familyId }) {
    return new Promise((resolve, reject) => {
      context.commit('familyStartLoad')
      vm.$store.dispatch('viewState/resetModalIds')
      vm.axios.get(apiUtils.buildAPIUrl(`family/${familyId}`))
        .then(response => {
          const family = response.data
          vm.$store.dispatch('family/setFamily', {vm, family} )
          resolve()
        })
        .catch(error => {
          context.commit('familyStopLoad')
          reject(error)
        })
    })
  },

  postUpdateReload(context, { vm, familyId }) {
    if (context.state.familyId === familyId) {
      vm.$store.dispatch('family/loadFamily', { vm, familyId })
    }
  },
  
  setFamily(context, { vm, family} ) {
    // If the stored hash matches the family in the database, this should mean that the copy stored in the database is the same as the one stored in local storage
    // When that happens, the server does not return the data from the server and instead sets the flag "useLocal".  In this case we use the value 
    // stored in local storage.  If the local storage value has been deleted, we use an empty object instead.
    const storedfamilyDataCompressed = localStorage.getItem('ls-fdc')
    const familyDataCompressed = (family.useLocal ? storedfamilyDataCompressed : family.familyDataCompressed) || 'H4sIAAAAAAAACquuBQBDv6ajAgAAAA==' 

    base64GzippedToObj(familyDataCompressed)
      .then(fd => {
        family.familyData = Object.freeze(fd)  // We are freezing the object so that it becomes read-only.  We don't want Vue to add reativity to this 
        const individualsArray = Object.freeze(loadIndividuals(family.familyData))
        const individualSearchMap = Object.freeze(buildIndividualNameSearchMap(individualsArray))
        const payload = {
          ...family,
          individualsArray,
          individualSearchMap
        }

        context.commit('familyEndLoad', payload)
        localStorage.setItem('ls-fi', family.familyId)
        localStorage.setItem('ls-fdc', family.familyDataCompressed)
        localStorage.setItem('ls-fdh', family.familyDataHash)
      })
      .catch(error =>
        {
          localStorage.removeItem('ls-fdc')
          localStorage.removeItem('ls-fdh')
          context.dispatch('loadDefaultFamily', vm )
        })
  }
}
