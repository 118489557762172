<template>
  <div class="embedded-form row-details narrow-container-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-form-group
          v-if="isPersonal"
          label-cols-sm="2"
          label-for="isEmailVisible-box">
          <b-form-checkbox id="isEmailVisible-box"
                           type="text"
                           v-model="form.isEmailVisible"
                           tabindex="1">
            Is Email Visible?
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="isPersonal"
          label-cols-sm="2"
          label="Family Alias:"
          label-align-sm="right"
          label-for="family-alias-box">
          <b-form-input id="family-alias-box"
                        type="text"
                        v-model="form.familyNameAlias"
                        tabindex="2" />
        </b-form-group>

        <b-form-group
          v-if="isAdministrator && !isPersonal"
          label-cols-sm="2"
          label="Authority:"
          label-align-sm="right"
          label-for="Authority-box">
          <b-form-radio-group id="Authority-box" v-model="form.familyAuthorityId" class="pt-2">
            <b-form-radio value="1">Read-only</b-form-radio>
            <b-form-radio value="2" v-if="isAuthorityAvailable(2)">Admin</b-form-radio>
            <b-form-radio value="3" v-if="isAuthorityAvailable(3)">Owner</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button type="button" variant="outline-secondary" tabindex="12" @click="resetPage()">Cancel</b-button>
          <b-button :disabled="postState.inProgress"
                    type="submit"
                    variant="primary"
                    tabindex="11"
                    class="ml-2">
            Submit
          </b-button>
        </div>
        <ApiPostFeedback :post-state="postState" />
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as familyAdminUtils from '@/logic/family/familyAdminUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"

  export default {
    name: 'EditFamilyUserDetails',
    components: {
      ApiPostFeedback
    },
    props: {
      familyUserData: {type: Object, required: true},
      operatorFamilyAuthorityId: {type: Number, required: true},
    },
    data() {
      return {
        form: {
          familyUserId: this.familyUserData.familyUserId,
          familyNameAlias: this.familyUserData.familyNameAlias,
          familyAuthorityId: this.familyUserData.familyAuthorityId,
          isEmailVisible: this.familyUserData.isEmailVisible
        },
        postState: {}
      }
    },
    computed: {
      ...mapState('authentication', [
        'jwt'
      ]),
      currentUser: function () {
        return this.jwt.nameid
      },
      isAdministrator: function () {
        return familyAdminUtils.isAdministrator(this.operatorFamilyAuthorityId)
      },
      isPersonal: function () {
        return this.familyUserData.userId === this.currentUser 
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      isAuthorityAvailable: function (authorityId) {
        return this.operatorFamilyAuthorityId >= authorityId
      },
      onSubmit: function () {
        const familyId = this.familyUserData.familyId
        this.postState = apiUtils.resetPostState()
        this.axios.patch(apiUtils.buildAPIUrl('familyAdmin/familyUser'), this.form)
          .then(response => {
            this.postState = response.postState
            this.resetForm()
            this.resetPage()
            this.$store.dispatch('family/postUpdateReload', { vm: this, familyId })
          })
          .catch(error => {
            this.postState = error.postState
            validationUtils.resetFormDelayed(this)
          })
      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          familyNameBase: "",
          userFamilyNameAlias: "",
          userIsEmailVisible: false,
          familyDescription: "",
          author: "",
          sourceUrl: ""
        }
        validationUtils.resetFormImmediate(this)
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>