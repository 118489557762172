<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
      <validation-provider name="Email" :rules="{required: true, email: true}" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Email:"
          label-align-sm="right"
          label-for="email-box">
          <b-form-input id="email-box" 
                        type="text" 
                        v-model="form.email" 
                        :state="getValidationState(validationContext)"
                        autocomplete="username"
                        tabindex="1" />
          <b-form-invalid-feedback id="email-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider vid="password" name="Password" :rules="{required: true }" v-slot="validationContext">
        <b-form-group
          label-cols-sm="3"
          label="Re-enter Password:"
          label-align-sm="right"
          label-for="password-box">
          <b-form-input id="password-box"
                        type="password"
                        v-model="form.password"
                        :state="getValidationState(validationContext)"
                        autocomplete="current-password"
                        tabindex="2" />
          <b-form-invalid-feedback id="password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="d-flex justify-content-end">
        <b-button type="button" variant="outline-secondary" tabindex="12" @click="resetPage()">Cancel</b-button>
        <b-button :disabled="postState.inProgress"
                  type="submit"
                  variant="primary"
                  tabindex="11"
                  class="ml-2">
          Submit
        </b-button>
      </div>
      <ApiPostFeedback :post-state="postState" />
    </b-form>
  </validation-observer>
</template>

<script>
  import * as apiUtils from '@/logic/general/apiUtils'
  import * as validationUtils from '@/logic/general/validationUtils'
  import ApiPostFeedback from "@/components/ApiPostFeedback"

  export default {
    name: 'EditEmail',
    components: {
      ApiPostFeedback
    },
    props: {
      initialEmail: {type: String, required: true}
    },
    data() {
      return {
        form: {
          email: this.initialEmail,
          password: ""
        },
        postState: {}
      }
    },
    methods: {
      getValidationState: function (validationContext) {
        return validationUtils.getValidationState(validationContext)
      },
      onSubmit: function () {
        this.postState = apiUtils.resetPostState()
        this.axios.post(apiUtils.buildAPIUrl('account/emailUpdate'), this.form)
          .then(response => {
            this.postState = response.postState
            this.$auth.setToken(response.data)
            this.resetForm()
            this.$store.dispatch('authentication/signalNewAccessToken', this)
            this.resetPage()
          })
          .catch(error => {
            this.postState = error.postState
            this.form.password = ""
            validationUtils.resetFormDelayed(this)
          })
      },
      resetForm: function () {
        this.postState = {}
        this.form = {
          email: "",
          password: ""
        }
        validationUtils.resetFormImmediate(this)
      },
      resetPage: function () {
        this.$emit('force-reset')
      }
    }
  }
</script>