import removeAccents from 'remove-accents'

export function getUrlSEOSafeName(value) {
  const base = value || 'x'                   // Never work with null or empty strings.  Use 'x' as default
  const latin = removeAccents(base)           // Strip of accents.  For example, 'ÀÁÂÃÄÅ' are converted to 'A'
  const regex1 = /[^0-9a-zA-Z]+/g             // Allow only the following characters.  A subset of RFC1738
  const urlSafe = latin.replace(regex1, '_')  // Replace any other character with an underscore. Multiple invalid characters in a row are replaced with a single underscore
  const regex2 = /^_*|_*$/g                   // Strip off any leading or trailing underscores
  const clean = urlSafe.replace(regex2, '')
  return clean.toLowerCase() || 'x'
}

export function getFamilyURL(familyId, familyName) {
  const familySEO = getUrlSEOSafeName(familyName)
  const path = { 
    name: 'Family', 
    params: {   
      familyId: familyId,
      familyName: familyName,
      familySEO: familySEO
    },
    path: `/tree/${familyId}/${familySEO}`
  }

  return path
}

export function getIndividualURL(familyId, familyName, individualId, fullName) {       
  const familySEO = getUrlSEOSafeName(familyName)
  const individualSEO = getUrlSEOSafeName(fullName)
  const path = { 
    name: 'Individual Tree', 
    params: {   
      familyId: familyId,
      familyName: familyName,
      familySEO: familySEO,
      individualId: individualId,
      individualName: fullName,
      individualSEO: individualSEO
    },
    path: `/tree/${familyId}/${familySEO}/${individualId}/${individualSEO}`
  }

  return path
}