import _ from 'lodash'

// This method will return all of the group members specified by the codes.  The codes will define the order.  If there is 
// more than one group member with the same code, they will be shown in the original order.
//   Example:  If codes is ['BIRT','GRAD','DEAT'], all the birth records will appear to start followed by all the graduations and finally by all the deaths.
export function getGroupMemberByCodes(individual, group, codes) {
  let result = []
  const gm = _.get(individual, group, [])
  codes.forEach(code => {
    const f = gm.filter(e => e.code === code)
    result = [...result, ...f]
  })
  return result
}

export function getGroupMemberByCode(individual, group, code) {
  const g = _.get(individual, group, [])
  return g.filter(e => e.code === code)
}

export function getGroupMemberByCodeFirst(individual, group, code) {
  const g = _.get(individual, group, [])
  const f = g.filter(e => e.code === code)
  return f.length > 0 ? f[0] : null
}

export function getGroupMemberByCodePrimaryOrFirst(individual, group, code) {
  const g = _.get(individual, group, [])
  const f = g.filter(e => e.code === code)
  const fp = f.filter(e => e.primary)

  if (fp.length > 0)
    return fp[0]
  else if (f.length > 0)
    return f[0]
  else 
    return null
}

export function getGroupMemberByCodeLast(individual, group, code) {
  const g = _.get(individual, group, [])
  const f = g.filter(e => e.code === code)
  return f.length > 0 ? f[f.length - 1] : null
}

export function getGroupMemberByPrimaryOrFirst(individual, group) {
  const g = _.get(individual, group, [])
  const fp = g.filter(e => g.primary)

  if (fp.length > 0)
    return fp[0]
  else if (g.length > 0)
    return g[0]
  else 
    return null
}

export function pushValue(targetArray, value, surroundLeft = '') {
  if (value) {
    const surroundRight = surroundLeft ==='(' ? ')' : surroundLeft
    targetArray.push(`${surroundLeft}${value}${surroundRight}`)
  }
}

export function pushValueByPath(entity, targetArray, path, surroundLeft = '') {
  const value = _.get(entity,path)
  pushValue(targetArray, value, surroundLeft)
  return value
}

export function pushValueByPathList(entity, pathList, targetArray, separator) {
  const r = []
  pathList.forEach(path => {
    pushValueByPath(entity, r, path)
  })
  
  pushValue(targetArray, r.join(separator)) 
}
