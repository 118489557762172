<template>
  <tr>
    <td v-if="fact.factDisplayType === 1" colspan="3">
      <div v-html="valueWithCitation" />
      <info-subfact v-for="(subfact,index) in fact.subfacts" :key="`subfacts-${index}`" :subfact="subfact" />
    </td>
    <td v-if="fact.factDisplayType !== 1" class="text-nowrap zero-width" v-html="label" />
    <td v-if="fact.factDisplayType === 2" colspan="2">
      <info-individual v-if="hasIndividualPointer" :individual-id="fact.value" :citation-number="fact.citationNumber" />
      <div v-if="hasNoPointer" v-html="valueWithCitation" />
      <info-subfact v-for="(subfact,index) in fact.subfacts" :key="`subfacts-${index}`" :subfact="subfact" />
    </td>
    <td v-if="fact.factDisplayType === 3" class="text-nowrap zero-width" v-html="value" />
    <td v-if="fact.factDisplayType === 3">
      <info-subfact v-for="(subfact,index) in fact.subfacts" :key="`subfacts-${index}`" :subfact="subfact" />
    </td>
  </tr>
</template>

<script>
import marked from 'marked'
import InfoIndividual from "@/components/familyInfo/InfoIndividual"
import InfoSubfact from '@/components/familyInfo/InfoSubfact'

  export default {
    name: 'InfoFact',
    components: {
      InfoIndividual,
      InfoSubfact
    },
    props: {
      fact: {type: Object, required: true},
    },
    computed: {
      hasIndividualPointer: function () {
        return this.fact.pointerType === 'individual'
      },
      hasNoPointer: function () {
        return !this.fact.pointerType
      },
      label: function () {
        const base = `<strong>${this.fact.label}</strong>`
        return this.fact.citationNumber ? `${base}<sup>${this.fact.citationNumber}</sup>` :  base
      },
      value: function () {
        return marked.parseInline(this.fact.value || '')
      },
      valueWithCitation: function () {
        return this.fact.citationNumber && this.value ? `${this.value}<sup>${this.fact.citationNumber}</sup>` : this.value
      }
    }
  }
</script>